import { forwardRef } from 'react';
import { ButtonProps as AntButtonProps, Button as AntdButton } from 'antd';
import cn from 'classnames';
import { Severity } from '@/types';
import styles from './base-button.module.scss';

export const { Group: ButtonGroup } = AntdButton;

export interface BaseButtonProps extends AntButtonProps {
  severity?: Severity;
  noStyle?: boolean;
  bordered?: boolean;
}

export const BaseButton = forwardRef<HTMLButtonElement, BaseButtonProps>(
  ({ className, severity, bordered = false, noStyle, children, ...props }, ref) => {
    const btnClassName = cn(styles.baseButton, {
      [styles.text]: props.type === 'text',
      [styles.ghost]: props.ghost,
      [styles.primary]: props.type === 'primary',
      [styles.link]: props.type === 'link',
      [styles.noStyle]: noStyle,
      [styles.errorSeverity]: !props.danger && severity === 'error',
      [styles.warningSeverity]: !props.danger && severity === 'warning',
      [styles.successSeverity]: !props.danger && severity === 'success',
      [styles.bordered]: !props.danger && bordered,
    });
    return (
      <AntdButton className={`${btnClassName} ${className}`} ref={ref} {...props}>
        {children}
      </AntdButton>
    );
  },
);
