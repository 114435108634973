import { SVGProps } from 'react';

export const RefreshIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.7 14.447A6.669 6.669 0 0 0 14.667 8c0-3.68-2.96-6.667-6.667-6.667-4.447 0-6.667 3.707-6.667 3.707m0 0V2m0 3.04h2.96"
      stroke="#292D32"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.333 8A6.67 6.67 0 0 0 8 14.667"
      stroke="#292D32"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="3 3"
    />
  </svg>
);
