import { create } from 'zustand';
import { handleApiCallInStore } from './utils';
import { LoadingKeys } from '@/constants/loading-keys';
import { API_END_POINTS } from '@/constants/api-end-points';
import { convertToFormData, customHttp, http } from '@/utils';
import {
  AddOrEditTopicPayload,
  ApiResponseWithPagination,
  BasePaginationParams,
  ITopicDetails,
  MaterialType,
  StringOrNumber,
  TopicItem,
} from '@/types';

const apiEndPoints = {
  getAllEndpoint: API_END_POINTS.topics,
  createEndPoint: API_END_POINTS.topics,
  deleteEndpoint: API_END_POINTS.topicsCrud,
  getOneEndpoint: API_END_POINTS.topicsCrud,
  updateEndpoint: API_END_POINTS.topicsCrud,
};
const loadingKeys = {
  getAllLoadingKey: LoadingKeys.getAllTopics,
  deleteLoadingKey: LoadingKeys.deleteTopic,
  getOneLoadingKey: LoadingKeys.getTopicDetails,
  createOrUpdateLoadingKey: LoadingKeys.createOrUpdateTopic,
};
interface IMaterialStore {
  topics: TopicItem[];
  totalTopics: number;
  topicDetails: ITopicDetails;
  getAll: (payload: BasePaginationParams & { type: MaterialType }) => void;
  deleteOne: (id: number, onSuccess: () => void) => void;
  addOrEdit: (id: StringOrNumber, payload: AddOrEditTopicPayload, onSuccess: () => void) => void;
  getById: (id: StringOrNumber) => void;
}

export const useTopicStore = create<IMaterialStore>((set) => ({
  topics: [],
  totalTopics: 0,
  topicDetails: undefined,
  addOrEdit: (id, payload, onSuccess) => {
    handleApiCallInStore(loadingKeys.createOrUpdateLoadingKey, async () => {
      if (!id) {
        await customHttp.post<FormData>(apiEndPoints.getAllEndpoint, convertToFormData<AddOrEditTopicPayload>(payload));
      } else {
        await customHttp.put<FormData>(
          apiEndPoints.updateEndpoint(id),
          convertToFormData<AddOrEditTopicPayload>(payload),
        );
      }
      onSuccess();
    });
  },
  getAll: (payload) => {
    handleApiCallInStore(loadingKeys.getAllLoadingKey, async () => {
      const { data } = await http.get<undefined, ApiResponseWithPagination<TopicItem>>(apiEndPoints.getAllEndpoint, {
        params: payload,
      });
      set({ topics: data.results, totalTopics: data.total });
    });
  },
  deleteOne: (id, onSuccess) => {
    handleApiCallInStore(loadingKeys.deleteLoadingKey, async () => {
      await http.delete(apiEndPoints.deleteEndpoint(id));
      onSuccess();
    });
  },
  getById: (id) => {
    handleApiCallInStore(loadingKeys.getOneLoadingKey, async () => {
      const { data } = await http.get<ITopicDetails>(apiEndPoints.getOneEndpoint(id));
      set({ topicDetails: data });
    });
  },
}));
