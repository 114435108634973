import { AxiosError } from 'axios';
import { message } from 'antd';
import { LoadingKeys } from '@/constants';
import { useLoadingStore } from './loading-store';

export const handleApiCallInStore = async (
  loadingKey: LoadingKeys,
  apiCall: () => void,
  onFailure?: (error: AxiosError) => void,
  showMessage: boolean = true,
) => {
  try {
    useLoadingStore.getState().setLoading(loadingKey, true);
    await apiCall();
  } catch (error: any) {
    if (onFailure) {
      onFailure(error?.response?.data?.[0]?.message);
    } else if (showMessage) {
      message.destroy();
      message.error(
        error?.data?.errors?.length > 0 ? error?.data?.errors?.[0]?.message : error?.data?.message ?? error?.message,
      );
    }
    return error?.data?.errors;
  } finally {
    useLoadingStore.getState().setLoading(loadingKey, false);
  }
};
