import { create } from 'zustand';
import {
  ApiResponse,
  ICustomerDashboardData,
  ICustomerServiceDashboard,
  IDashboardData,
  ISalesDashboardData,
} from '@/types';
import { handleApiCallInStore } from './utils';
import { API_END_POINTS, LoadingKeys } from '@/constants';
import { http } from '@/utils';

interface IDashboardStore {
  dashboardData: IDashboardData;
  customerDashboardData: ICustomerDashboardData;
  salesDashboardData: ISalesDashboardData;
  customerServiceDashboardData: ICustomerServiceDashboard;
  getDashboardData: (type: string) => void;
}

export const useDashboardStore = create<IDashboardStore>((set) => ({
  dashboardData: undefined,
  customerDashboardData: undefined,
  salesDashboardData: undefined,
  customerServiceDashboardData: undefined,
  getDashboardData: (type) => {
    handleApiCallInStore(LoadingKeys.getDashboardData, async () => {
      const { data } = await http.get<
        undefined,
        ApiResponse<IDashboardData | ISalesDashboardData | ICustomerServiceDashboard | ICustomerDashboardData>
      >(API_END_POINTS.dashboardData);
      if (type === 'company') {
        set({ dashboardData: data as IDashboardData });
      } else if (type === 'customer') {
        set({ customerDashboardData: data as ICustomerDashboardData });
      } else if (type === 'customer_service') {
        set({ customerServiceDashboardData: data as ICustomerServiceDashboard });
      } else {
        set({ salesDashboardData: data as ISalesDashboardData });
      }
    });
  },
}));
