import { SVGProps } from 'react';

export const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.027 11.75a.63.63 0 0 0 .093-.008l2.628-.46a.153.153 0 0 0 .083-.044l6.624-6.624a.155.155 0 0 0 .034-.17.155.155 0 0 0-.034-.05l-2.597-2.599a.155.155 0 0 0-.111-.045.155.155 0 0 0-.111.045L4.013 8.42a.159.159 0 0 0-.044.083l-.461 2.628a.523.523 0 0 0 .147.465.53.53 0 0 0 .372.155ZM5.08 9.025l5.667-5.666 1.145 1.146-5.667 5.665-1.39.246.245-1.391Zm8.67 4.037H2.25a.5.5 0 0 0-.5.5v.563c0 .069.056.125.125.125h12.25a.125.125 0 0 0 .125-.125v-.563a.5.5 0 0 0-.5-.5Z"
      fill="#000"
      fillOpacity={0.88}
    />
  </svg>
);
