import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormField } from '@/types';
import { langLabel } from '@/utils';
import { RULES, validateNumber } from '../rules';

export const useTiersFields = () => {
  const { t } = useTranslation();
  const tiersFields = useMemo(
    (): FormField[] => [
      {
        label: langLabel(t('title'), 'en'),
        name: 'title_en',
        type: 'input',
        rules: [RULES.required, RULES.englishOnly],
      },
      {
        label: langLabel(t('title'), 'ar'),
        name: 'title_ar',
        type: 'input',
        rules: [RULES.required, RULES.arabicOnly],
      },
      {
        label: t('target'),
        name: 'target',
        type: 'input',
        rules: [RULES.required, RULES.integers, { validator: validateNumber({ minVal: 0 }) }],
      },
      {
        label: t('pointPercentage'),
        name: 'point_percentage',
        type: 'input',
        rules: [RULES.required, RULES.whiteSpace, RULES.number, { validator: validateNumber({ minVal: 0 }) }],
        inputNumberProps: {
          addonAfter: '%',
        },
      },
      {
        label: t('icon'),
        name: 'icon',
        type: 'uploadImage',
        rules: [RULES.required],
        uploadImageProps: {
          maxImagesCount: 1,
          maxSizeInMB: 1,
        },
      },
    ],
    [],
  );
  return {
    tiersFields,
  };
};
