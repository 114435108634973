import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { RULES } from '../rules';
import { FormField } from '@/types';

export const useDistributorsFields = () => {
  const { t } = useTranslation();

  const distributorsFields = useMemo(
    (): FormField[] => [
      {
        label: t('name'),
        name: 'name',
        type: 'input',
        rules: [RULES.required],
      },
      {
        label: t('email'),
        name: 'email',
        type: 'input',
        rules: [RULES.required, RULES.email],
      },
      // ...(!distributorId
      //   ? [
      //       {
      //         label: t('password'),
      //         name: 'password',
      //         type: 'password' as const,
      //         placeholder: t('createAPassword'),
      //         rules: [RULES.required, PARAM_RULES.min(8), RULES.whiteSpace],
      //       },
      //     ]
      //   : []),

      {
        label: t('phoneNumber'),
        name: 'mobile',
        type: 'phone',
        phoneSelectName: 'country_code',
        rules: [RULES.required, RULES.number, RULES.whiteSpace, RULES.mobile],
      },
      {
        label: t('registrationNumber'),
        name: 'registration_number',
        type: 'input',
        rules: [RULES.required, RULES.number, RULES.integers, RULES.whiteSpace],
      },
      {
        label: t('representativeFullName'),
        name: 'representative_full_name',
        type: 'input',
        rules: [RULES.required],
      },
      {
        label: t('logo'),
        name: 'logo',
        type: 'uploadImage',
        uploadImageProps: {
          maxImagesCount: 1,
          maxSizeInMB: 1,
        },
      },
    ],
    [],
  );

  return {
    distributorsFields,
  };
};
