import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Tag } from 'antd';
import { FormField } from '@/types';
import { RULES, validateNumber } from '../rules';
import { PARTNER_STATUS_MAPPER } from '../customer-service-constants';
import { API_END_POINTS } from '../api-end-points';
import { useGetInitialData } from '@/hooks';
import { LoadingKeys } from '../loading-keys';
import { langLabel, optionalLabel } from '@/utils';

export const usePartnersFields = () => {
  const { t } = useTranslation();

  const partnerCategoriesData = useGetInitialData({
    apiEndPoint: API_END_POINTS.partnerCategories,
    loadingKey: LoadingKeys.getAllPartnerCategoriesLite,
    fetchOnMount: true,
  });

  const partnersTableFields = useMemo(
    (): FormField[] => [
      {
        label: t('category'),
        name: 'category',
        type: 'select',
        placeholder: t('selectCategory'),
        options: [],
      },
      {
        label: t('status'),
        name: 'status',
        type: 'select',
        placeholder: t('selectStatus'),
        options: Object.values(PARTNER_STATUS_MAPPER).map((item) => ({
          label: (
            <Tag bordered color={item.color} icon={item.icon}>
              {item.label}
            </Tag>
          ),
          value: item.value,
        })),
      },
    ],
    [],
  );

  const partnersFields = useMemo(
    (): FormField[] => [
      {
        label: t('partnerName'),
        name: 'name',
        type: 'input',
        rules: [RULES.required],
      },
      {
        label: optionalLabel(langLabel(t('description'), 'en')),
        name: 'description_en',
        type: 'textarea',
        placeholder: t('description'),
        rules: [RULES.englishOnly],
      },
      {
        label: optionalLabel(langLabel(t('description'), 'ar')),
        name: 'description_ar',
        type: 'textarea',
        placeholder: t('description'),
        rules: [RULES.arabicOnly],
      },
      {
        label: t('email'),
        name: 'email',
        type: 'input',
        rules: [RULES.required, RULES.email, RULES.whiteSpace],
      },
      {
        label: t('mobileNumber'),
        name: 'mobile',
        type: 'phone',
        phoneSelectName: 'country_code',
        rules: [RULES.required, RULES.number, RULES.whiteSpace, RULES.mobile],
      },
      {
        label: t('category'),
        name: 'partner_category_id',
        type: 'infiniteSelect' as const,
        placeholder: t('selectCategory'),
        infiniteSelectProps: {
          apiEndPoint: API_END_POINTS.tiers,
          loadingKey: LoadingKeys.getAllTiers,
          initialData: partnerCategoriesData?.initialData,
          initialHasMore: partnerCategoriesData?.hasMore,
        },
      },
      {
        label: t('minBalance'),
        name: 'min_balance',
        type: 'input',
        rules: [RULES.required, { validator: validateNumber({ minVal: 0 }) }, RULES.number, RULES.whiteSpace],
      },
      {
        label: t('maxBalance'),
        name: 'max_balance',
        type: 'input',
        rules: [RULES.required, RULES.number, { validator: validateNumber({ minVal: 0 }) }, RULES.whiteSpace],
      },
      {
        label: t('validFor'),
        name: 'valid_for',
        type: 'date',
        rules: [RULES.required],
      },
      {
        label: t('image'),
        name: 'logo',
        type: 'uploadImage',
        uploadImageProps: {
          maxImagesCount: 1,
          maxSizeInMB: 1,
        },
        rules: [RULES.required],
      },
    ],
    [partnerCategoriesData],
  );

  return {
    partnersFields,
    partnersTableFields,
  };
};
