import { PAGE, SEARCH_KEYWORD } from '@/constants';
import { useAddOrDeleteSearchParam } from './use-add-or-delete-search-param';

export const useOnSearchSubmit = () => {
  const { deleteSearchParam, updateSearchParams } = useAddOrDeleteSearchParam();

  const onSearch = (value) => {
    if (value) {
      updateSearchParams({ [SEARCH_KEYWORD]: value, [PAGE]: '1' });
    } else {
      deleteSearchParam(SEARCH_KEYWORD);
    }
  };
  return { onSearch };
};
