import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './roles-select.module.scss';
import { OptionType } from '@/types';

interface RolesSelectProps {
  className?: string;
  value: number;
  placeholder?: string;
  onChange: (value: number) => void;
  options: OptionType[];
  disabled?: boolean;
  width?: number;
}

export const RolesSelect = ({
  className,
  value,
  placeholder,
  disabled = false,
  onChange,
  options,
  width = 140,
}: RolesSelectProps) => {
  const { t } = useTranslation();
  return (
    <Select
      style={{ width }}
      disabled={disabled}
      className={`${styles.selectRole} ${className ?? ''}`}
      value={value}
      placeholder={placeholder ?? t('role')}
      onChange={onChange}
      options={options}
    />
  );
};
