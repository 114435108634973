import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormField } from '@/types';
import { RULES, validateNumber } from '../rules';

export const useCompanyWizardSettingsFields = () => {
  const { t } = useTranslation();
  const companyWizardSettings = useMemo(
    (): FormField[] => [
      {
        label: t('startDate'),
        name: 'start_date',
        type: 'date',
        placeholder: t('selectDate'),
        rules: [RULES.required],
        datePickerProps: {
          format: 'YYYY-MM-DD',
        },
        colProps: {
          lg: 8,
          span: 12,
        },
      },
      {
        label: t('renewalDate'),
        name: 'renewal_date',
        type: 'date',
        placeholder: t('selectDate'),
        rules: [RULES.required],
        datePickerProps: {
          format: 'YYYY-MM-DD',
        },
        colProps: {
          lg: 8,
          span: 12,
        },
      },
      {
        label: t('basePercentage'),
        name: 'base_percentage',
        type: 'input',
        rules: [RULES.required, RULES.number, RULES.whiteSpace, { validator: validateNumber({ minVal: 0 }) }],
        inputNumberProps: {
          addonAfter: '%',
        },
      },
      {
        label: t('joinPoints'),
        name: 'join_points',
        type: 'input',
        rules: [RULES.required, RULES.number, RULES.whiteSpace],
      },
      {
        label: t('creditPerPoint'),
        name: 'credit_per_point',
        type: 'input',
        rules: [RULES.required, RULES.whiteSpace],
      },
      {
        label: t('minimumPointsToRedeem'),
        name: 'min_points_to_redeem',
        type: 'input',
        rules: [RULES.required, RULES.number, RULES.whiteSpace],
      },
    ],
    [],
  );

  return { companyWizardSettings };
};
