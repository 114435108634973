import { WithChildrenProps } from '@/types';
import { Loader } from '../loader/loader.component';

interface LoadingWrapProps {
  loading: boolean;
}

export const LoadingWrap = ({ loading, children }: WithChildrenProps<LoadingWrapProps>) => (
  <>{loading ? <Loader /> : children}</>
);
