import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { langLabel, optionalLabel } from '@/utils';
import { RULES } from '../rules';
import { FormField } from '@/types';
import { API_END_POINTS } from '../api-end-points';
import { LoadingKeys } from '../loading-keys';
import { useGetInitialData } from '@/hooks';

export const useSubcategoryFields = (categoryId: string) => {
  const { t } = useTranslation();
  const { initialData: categories, hasMore: categoriesHasMore } = useGetInitialData({
    apiEndPoint: API_END_POINTS.categories,
    loadingKey: LoadingKeys.getAllCategoriesLite,
  });

  const fields: FormField[] = useMemo<FormField[]>(
    (): FormField[] => [
      {
        label: t('categoryName'),
        name: 'category_id',
        type: 'infiniteSelect' as const,
        rules: [RULES.required],
        placeholder: t('selectCategory'),
        infiniteSelectProps: {
          apiEndPoint: API_END_POINTS.categories,
          loadingKey: LoadingKeys.getAllCategoriesLite,
          disabled: !!categoryId,
        },
      },

      {
        label: langLabel(t('subcategoryName'), 'en'),
        name: 'name_en',
        type: 'input',
        placeholder: t('enterSubcategoryName'),
        rules: [RULES.required, RULES.englishOnly],
      },
      {
        label: langLabel(t('subcategoryName'), 'ar'),
        name: 'name_ar',
        type: 'input',
        placeholder: t('enterSubcategoryName'),
        rules: [RULES.required, RULES.arabicOnly],
      },
      {
        label: optionalLabel(langLabel(t('description'), 'en')),
        name: 'description_en',
        type: 'textarea',
        placeholder: t('description'),
        rules: [RULES.englishOnly],
      },
      {
        label: optionalLabel(langLabel(t('description'), 'ar')),
        name: 'description_ar',
        type: 'textarea',
        placeholder: t('description'),
        rules: [RULES.arabicOnly],
      },
    ],
    [categoryId],
  );

  const tableFields: FormField[] = useMemo<FormField[]>(
    (): FormField[] => [
      ...(!categoryId
        ? ([
            {
              name: 'category_id',
              type: 'infiniteSelect',
              placeholder: t('selectCategory'),
              colProps: {
                span: 24,
                sm: 12,
                lg: 8,
                xl: 6,
              },
              infiniteSelectProps: {
                apiEndPoint: API_END_POINTS.categories,
                loadingKey: LoadingKeys.getAllCategoriesLite,
                initialData: categories,
                initialHasMore: categoriesHasMore,
                size: 'middle',
              },
            },
          ] as const)
        : []),
    ],
    [categories, categoriesHasMore, categoryId],
  );
  return {
    fields,
    tableFields,
  };
};
