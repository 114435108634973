import React, { useLayoutEffect } from 'react';
import { Layout, SiderProps } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { SiderMenu } from '../sider-menu';
import { SiderLogo } from '../sider-logo/sidebar-logo.component';
import { useAppStore } from '@/stores';
import styles from './main-sider.module.scss';
import { MAIN_LAYOUT_SIDEBAR_WIDTH } from '../../../../constants/layout-dimensions';

const MainSider: React.FC<SiderProps> = ({ ...props }) => {
  const { sidebarCollapsed, setCollapsed } = useAppStore();
  const isCollapsedScreen = useMediaQuery({
    query: '(min-width: 568px) and (max-width: 991px)',
  });

  useLayoutEffect(() => {
    if (isCollapsedScreen) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [isCollapsedScreen]);
  return (
    <>
      <Layout.Sider
        className={styles.sider}
        trigger={null}
        collapsed={sidebarCollapsed}
        collapsedWidth={80}
        collapsible
        width={MAIN_LAYOUT_SIDEBAR_WIDTH}
        {...props}
      >
        <div className={styles.siderContent}>
          <SiderLogo />

          <SiderMenu />
        </div>
      </Layout.Sider>
    </>
  );
};

export default MainSider;
