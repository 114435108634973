import { SVGProps } from 'react';

export const ReceiptIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 15.166c-.527 0-1.027-.266-1.374-.74l-.673-.9a.72.72 0 0 0-.527-.306c-.2-.014-.4.08-.56.246l-.38-.333.367.34c-.967 1.027-1.7.947-2.053.807-.36-.14-.967-.6-.967-2.08V4.693c0-2.96.853-3.86 3.647-3.86h5.033c2.793 0 3.647.9 3.647 3.86V12.2c0 1.473-.607 1.933-.967 2.08-.353.14-1.087.22-2.053-.807a.715.715 0 0 0-.567-.247.733.733 0 0 0-.533.307l-.674.9c-.34.467-.84.733-1.366.733ZM5.386 12.22h.087c.493.026.96.286 1.273.706l.68.9c.327.434.827.434 1.154 0l.673-.9c.313-.42.78-.68 1.28-.706.5-.027.987.18 1.347.566.506.54.833.607.953.56.16-.066.333-.453.333-1.146V4.693c0-2.407-.42-2.86-2.646-2.86H5.48c-2.227 0-2.647.453-2.647 2.86V12.2c0 .7.173 1.086.333 1.146.12.047.447-.02.954-.56.346-.366.8-.566 1.266-.566Z"
      fill="#000"
      fillOpacity={0.88}
    />
    <path
      d="M10 9.333a.664.664 0 0 1-.667-.666c0-.367.3-.667.667-.667.366 0 .666.3.666.667a.664.664 0 0 1-.666.666ZM6 6a.664.664 0 0 1-.667-.666c0-.367.3-.667.667-.667.366 0 .666.3.666.667A.664.664 0 0 1 6 6ZM5.753 9.7a.494.494 0 0 1-.353-.147.503.503 0 0 1 0-.706L9.887 4.36a.503.503 0 0 1 .706 0 .503.503 0 0 1 0 .707L6.107 9.553a.473.473 0 0 1-.354.147Z"
      fill="#292D32"
    />
  </svg>
);
