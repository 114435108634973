import { SVGProps } from 'react';

export const WelcomwIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={44} height={44} fill="none" {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M31.45 42.25H10.596c-1.364 0-2.045 0-2.36-.27a1.125 1.125 0 0 1-.392-.943c.033-.414.515-.896 1.479-1.86l19.131-19.131c.891-.891 1.337-1.337 1.85-1.504a2.25 2.25 0 0 1 1.391 0c.514.167.96.612 1.85 1.504l8.705 8.704v2.7m-10.8 10.8c3.78 0 5.67 0 7.114-.736a6.75 6.75 0 0 0 2.95-2.95c.736-1.444.736-3.334.736-7.114m-10.8 10.8h-18.9c-3.78 0-5.67 0-7.114-.736a6.75 6.75 0 0 1-2.95-2.95C1.75 37.12 1.75 35.23 1.75 31.45v-18.9c0-3.78 0-5.67.736-7.114a6.75 6.75 0 0 1 2.95-2.95C6.879 1.75 8.77 1.75 12.55 1.75h18.9c3.78 0 5.67 0 7.114.736a6.75 6.75 0 0 1 2.95 2.95c.736 1.443.736 3.334.736 7.114v18.9M18.625 14.125a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z"
    />
  </svg>
);
