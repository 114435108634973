import { FC } from 'react';
import { Button, Flex } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { ProfileDropdown } from '../components/profile-dropdown/profile-dropdown.component';
import { useAppStore } from '@/stores';
import styles from './header.module.scss';
import { NotificationsDropdown } from '../components/notifications-dropdown/notifications-dropdown.component';
import { ChangeLanguage } from '@/components';

export const MobileHeader: FC = () => {
  const { setIsMobileDrawerOpen } = useAppStore();

  return (
    <nav className={styles.mobilenav}>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap={4}>
          <ProfileDropdown isMobile />
          {/* <NotificationsDropdown /> */}
          <ChangeLanguage />
        </Flex>
        <Button
          size="middle"
          className={styles.settingsBtn}
          type="link"
          onClick={() => setIsMobileDrawerOpen(true)}
          icon={<MenuOutlined />}
        />
      </Flex>
    </nav>
  );
};
