import { SVGProps } from 'react';

export const EmailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity={0.88}
      strokeWidth={2}
      d="m2.333 8.166 9.526 6.668c.772.54 1.157.81 1.577.915.37.092.758.092 1.129 0 .419-.105.805-.375 1.576-.915l9.526-6.668M7.933 23.333h12.134c1.96 0 2.94 0 3.689-.382a3.5 3.5 0 0 0 1.53-1.53c.38-.748.38-1.728.38-3.688v-7.467c0-1.96 0-2.94-.38-3.689a3.5 3.5 0 0 0-1.53-1.53c-.749-.381-1.729-.381-3.69-.381H7.935c-1.96 0-2.94 0-3.69.381a3.5 3.5 0 0 0-1.529 1.53c-.381.749-.381 1.729-.381 3.689v7.467c0 1.96 0 2.94.381 3.689a3.5 3.5 0 0 0 1.53 1.53c.748.38 1.728.38 3.689.38Z"
    />
  </svg>
);
