import { Button, Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import { EditFilled, PlusOutlined } from '@ant-design/icons';
import { DynamicBreadcrumb } from '../breadcrumb/breadcrumb.component';
import { BreadCrumbItem, PermissionType } from '@/types';
import { Paragraph } from '../paragraph/paragraph.component';
import styles from './pages-title.module.scss';
import { PermissionsKeys } from '@/constants/permission-keys';
import { usePermission } from '@/hooks';

const { Title } = Typography;

interface PagesTitleProps {
  title: string;
  subtitle?: string;
  handleAddClick?: () => void;
  children?: ReactNode;
  addBtnTitle?: string;
  breadItems?: BreadCrumbItem[];
  isBreadCrumbLoading?: boolean;
  handleEditClick?: () => void;
  editBtnTitle?: string;
  permissionKey?: PermissionsKeys;
}

export const PagesHeader = ({
  children,
  title,
  subtitle,
  handleAddClick,
  addBtnTitle,
  breadItems,
  isBreadCrumbLoading = false,
  handleEditClick,
  editBtnTitle,
  permissionKey,
}: PagesTitleProps) => {
  const { t } = useTranslation();
  const { showComponent } = usePermission();
  const permissionCondition = (permissionType: PermissionType, handleFunction: () => void) =>
    permissionType && permissionKey ? handleFunction && showComponent(permissionKey, permissionType) : handleFunction;

  return (
    <div className={styles.pagesHead}>
      <Flex gap={10} className={styles.titleBtnWrap} justify="space-between" align="flex-start" wrap>
        <div className={styles.titleWrap}>
          <Title level={3}>{title}</Title>
          {subtitle && <Paragraph className={styles.subtitle}>{subtitle}</Paragraph>}
          {breadItems && (
            <div className={styles.breadcrumbWrap}>
              <DynamicBreadcrumb loading={isBreadCrumbLoading} items={breadItems} />
            </div>
          )}
        </div>

        <Flex justify="space-between" align="center" gap={8}>
          {permissionCondition(PermissionType.CREATE, handleAddClick) && (
            <Button size="large" onClick={handleAddClick} icon={<PlusOutlined />}>
              {addBtnTitle ?? t('add')}
            </Button>
          )}
          {permissionCondition(PermissionType.EDIT, handleEditClick) && (
            <Button size="large" onClick={handleEditClick} icon={<EditFilled />}>
              {editBtnTitle ?? t('edit')}
            </Button>
          )}
          {children}
        </Flex>
      </Flex>
    </div>
  );
};
