import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { t } from 'i18next';

export enum PARTNER_STATUS {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  PENDING = 'pending',
}

export enum REDEEM_REQUEST_STATUS {
  NEW = 'New',
  IN_PROGRESS = 'In Progress',
  REJECTED = 'rejectected',
  DELIVERED = 'Delivered',
}

export const PARTNER_STATUS_MAPPER = {
  [PARTNER_STATUS.ACCEPTED]: {
    label: t('accepted'),
    color: 'success',
    value: PARTNER_STATUS.ACCEPTED,
    icon: <CheckCircleOutlined />,
  },
  [PARTNER_STATUS.REJECTED]: {
    label: t('rejected'),
    color: 'error',
    value: PARTNER_STATUS.REJECTED,
    icon: <CloseCircleOutlined />,
  },
  [PARTNER_STATUS.PENDING]: {
    label: t('pending'),
    color: 'warning',
    value: PARTNER_STATUS.PENDING,
    icon: <ExclamationCircleOutlined />,
  },
};

export const REDEEM_REQUEST_STATUS_MAPPER = {
  [REDEEM_REQUEST_STATUS.NEW]: {
    label: t('new'),
    color: 'processing',
    value: REDEEM_REQUEST_STATUS.NEW,
  },
  [REDEEM_REQUEST_STATUS.IN_PROGRESS]: {
    label: t('inProgress'),
    color: 'warning',
    value: REDEEM_REQUEST_STATUS.IN_PROGRESS,
  },
  [REDEEM_REQUEST_STATUS.REJECTED]: {
    label: t('rejected'),
    color: 'error',
    value: REDEEM_REQUEST_STATUS.REJECTED,
  },
  [REDEEM_REQUEST_STATUS.DELIVERED]: {
    label: t('delivered'),
    color: 'success',
    value: REDEEM_REQUEST_STATUS.DELIVERED,
  },
};
