import { SVGProps } from 'react';

export const ImportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.959 5.933c2.4.207 3.38 1.44 3.38 4.14v.087c0 2.98-1.193 4.173-4.173 4.173h-4.34c-2.98 0-4.174-1.193-4.174-4.173v-.087c0-2.68.967-3.913 3.327-4.133M8 1.333V9.92"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.232 8.433 8 10.666 5.766 8.433"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
