import { SVGProps } from 'react';

export const LockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity={0.88}
      strokeWidth={2}
      d="M19.833 11.667V9.333a5.833 5.833 0 0 0-11.666 0v2.334M14 16.917v2.333m-3.733 5.25h7.466c1.96 0 2.94 0 3.69-.381a3.5 3.5 0 0 0 1.529-1.53c.381-.749.381-1.729.381-3.689v-1.633c0-1.96 0-2.94-.381-3.69a3.5 3.5 0 0 0-1.53-1.529c-.749-.381-1.729-.381-3.689-.381h-7.466c-1.96 0-2.94 0-3.69.381a3.5 3.5 0 0 0-1.529 1.53c-.381.748-.381 1.729-.381 3.689V18.9c0 1.96 0 2.94.381 3.689a3.5 3.5 0 0 0 1.53 1.53c.748.381 1.728.381 3.689.381Z"
    />
  </svg>
);
