/* eslint-disable camelcase */
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { FormInstance } from 'antd';
import { useLocation, useSearchParams } from 'react-router-dom';
import { FormField } from '@/types';
import { langLabel, optionalLabel } from '@/utils';
import { RULES } from '../rules';
import { API_END_POINTS } from '../api-end-points';
import { LoadingKeys } from '../loading-keys';
import { SEARCH_KEYWORD } from '..';
import { useGetInitialData } from '@/hooks';

interface SKUFieldsArguments {
  brandId?: string;
}

export const useSKUFields = ({ brandId }: SKUFieldsArguments) => {
  const { t } = useTranslation();
  const [selectedCategoryId, setSelectedCategory] = useState<number>();
  const [selectedSubcategoryId, setSelectedSubcategory] = useState<number>();
  const [searchParams] = useSearchParams();

  const location = useLocation();
  const { initialData: categories, hasMore: categoriesHasMore } = useGetInitialData({
    apiEndPoint: API_END_POINTS.categories,
    loadingKey: LoadingKeys.getAllCategoriesLite,
  });

  const {
    initialData: subcategories,
    hasMore: subcategoriesHasMore,
    fetchData: fetchSubcategories,
  } = useGetInitialData({
    apiEndPoint: API_END_POINTS.subcategories,
    loadingKey: LoadingKeys.getAllSubcategoriesLite,
    fetchOnMount: false,
  });

  const {
    initialData: brands,
    hasMore: brandsHasMore,
    fetchData: fetchBrands,
  } = useGetInitialData({
    apiEndPoint: API_END_POINTS.brands,
    loadingKey: LoadingKeys.getAllBrandsLite,
    fetchOnMount: false,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const categoryIdParamValue = searchParams.get('category_id');
    const subcategoryIdParamValue = searchParams.get('sub_category_id');
    setSelectedCategory(categoryIdParamValue ? Number(categoryIdParamValue) : undefined);
    setSelectedSubcategory(subcategoryIdParamValue ? Number(subcategoryIdParamValue) : undefined);
  }, [location.search]);

  useEffect(() => {
    if (selectedCategoryId) {
      fetchSubcategories(selectedCategoryId ? { category_id: selectedCategoryId } : {});
    }
  }, [selectedCategoryId]);
  useEffect(() => {
    if (selectedSubcategoryId) {
      fetchBrands(selectedSubcategoryId ? { sub_category_id: selectedSubcategoryId } : {});
    }
  }, [selectedSubcategoryId]);

  const onCategoryChange = (categoryId: number, form: FormInstance) => {
    setSelectedCategory(categoryId);
    setSelectedSubcategory(undefined);

    form.setFieldValue('sub_category_id', undefined);
    form.setFieldValue('brand_id', undefined);
  };
  const onSubcategoryChange = (subcategoryId: number, form: FormInstance) => {
    setSelectedSubcategory(subcategoryId);
    form.setFieldValue('brand_id', undefined);
  };

  const onReset = () => {
    setSelectedCategory(undefined);
    setSelectedSubcategory(undefined);
  };

  const SKUFields = useMemo(
    (): FormField[] => [
      {
        label: t('brand'),
        name: 'brand_id',
        type: 'infiniteSelect',
        rules: [RULES.required],
        placeholder: t('selectBrand'),
        infiniteSelectProps: {
          apiEndPoint: API_END_POINTS.brands,
          loadingKey: LoadingKeys.getAllBrandsLite,
          disabled: !!brandId,
        },
      },
      {
        label: langLabel(t('SKUName'), 'en'),
        name: 'name_en',
        type: 'input',
        placeholder: t('enterSKUName'),
        rules: [RULES.required, RULES.englishOnly],
      },
      {
        label: langLabel(t('SKUName'), 'ar'),
        name: 'name_ar',
        type: 'input',
        placeholder: t('enterSKUName'),
        rules: [RULES.required, RULES.arabicOnly],
      },
      {
        label: optionalLabel(t('logo')),
        name: 'logo',
        type: 'uploadImage',
        uploadImageProps: {
          maxImagesCount: 1,
          maxSizeInMB: 1,
        },
      },
      {
        label: optionalLabel(langLabel(t('description'), 'en')),
        name: 'description_en',
        type: 'textarea',
        placeholder: t('description'),
        rules: [RULES.englishOnly],
      },
      {
        label: optionalLabel(langLabel(t('description'), 'ar')),
        name: 'description_ar',
        type: 'textarea',
        placeholder: t('description'),
        rules: [RULES.arabicOnly],
      },

      // {
      //   label: t('price'),
      //   name: 'price',
      //   type: 'price',
      //   rules: [RULES.required, RULES.number],
      //   inputNumberProps: {
      //     min: 1,
      //   },
      // },
      // {
      //   label: t('images'),
      //   name: 'images',
      //   type: 'uploadImage',
      //   rules: [RULES.required],
      //   uploadImageProps: {
      //     maxImagesCount: 5,
      //     maxSizeInMB: 1,
      //   },
      // },
    ],
    [brandId],
  );
  const tableFields = useMemo(
    (): FormField[] => [
      ...(!brandId
        ? [
            {
              name: 'category_id',
              type: 'infiniteSelect' as const,
              placeholder: t('selectCategory'),
              colProps: {
                span: 24,
                sm: 12,
                lg: 8,
                xl: 6,
              },
              infiniteSelectProps: {
                apiEndPoint: API_END_POINTS.categories,
                loadingKey: LoadingKeys.getAllCategoriesLite,
                initialData: categories,
                initialHasMore: categoriesHasMore,
                size: 'middle' as const,
                onSelectChange: onCategoryChange,
              },
            },
            {
              name: 'sub_category_id',
              type: 'infiniteSelect' as const,
              placeholder: t('selectSubcategory'),
              colProps: {
                span: 24,
                sm: 12,
                lg: 8,
                xl: 6,
              },
              infiniteSelectProps: {
                apiEndPoint: API_END_POINTS.subcategories,
                loadingKey: LoadingKeys.getAllSubcategoriesLite,
                initialData: selectedCategoryId === undefined ? [] : subcategories,
                initialHasMore: subcategoriesHasMore,
                size: 'middle' as const,
                disabled: !selectedCategoryId,
                onSelectChange: onSubcategoryChange,
              },
            },
            {
              name: 'brand_id',
              type: 'infiniteSelect' as const,
              placeholder: t('selectBrand'),
              colProps: {
                span: 24,
                sm: 12,
                lg: 8,
                xl: 6,
              },
              infiniteSelectProps: {
                apiEndPoint: API_END_POINTS.brands,
                loadingKey: LoadingKeys.getAllBrandsLite,
                initialData: selectedSubcategoryId === undefined ? [] : brands,
                initialHasMore: brandsHasMore,
                size: 'middle' as const,
                disabled: !selectedSubcategoryId,
              },
            },
          ]
        : []),
    ],
    [
      brandId,
      selectedCategoryId,
      subcategories,
      selectedSubcategoryId,
      subcategoriesHasMore,
      categories,
      categoriesHasMore,
      brands,
      brandsHasMore,
      selectedCategoryId,
      subcategoriesHasMore,
    ],
  );
  return {
    SKUFields,
    tableFields,
    onReset,
  };
};
