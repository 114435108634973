/* eslint-disable default-param-last */
import { create } from 'zustand';
import { NavigateFunction } from 'react-router-dom';
import { PWAEvent } from '@/types';

interface IAppState {
  navigate: NavigateFunction;
  setNavigate: (navigateFunction: NavigateFunction) => void;
  sidebarCollapsed: boolean;
  pwaEvent: PWAEvent;
  isPWASupported: boolean;
  isStandalone: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
  toggleSidebar: () => void;
  addDeferredPrompt: (payload: PWAEvent) => void;
  isMobileDrawerOpen: boolean;
  setIsMobileDrawerOpen: (isOpen: boolean) => void;
}

export const useAppStore = create<IAppState>((set) => ({
  isMobileDrawerOpen: false,
  navigate: () => {},
  sidebarCollapsed: false,
  themeToken: {},
  pwaEvent: null,
  isPWASupported: false,
  isStandalone: window.matchMedia('(display-mode: standalone)').matches,
  setNavigate: (navigateFunc) => set({ navigate: navigateFunc }),
  setCollapsed: (isCollapsed) => set(() => ({ sidebarCollapsed: isCollapsed })),
  toggleSidebar: () => set((state) => ({ sidebarCollapsed: !state.sidebarCollapsed })),
  addDeferredPrompt: (payload) => set(() => ({ pwaEvent: payload, isPWASupported: true })),
  setIsMobileDrawerOpen: (isOpen) => set(() => ({ isMobileDrawerOpen: isOpen })),
}));
