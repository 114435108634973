import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { LoadingKeys, stepsMapper, WizardSteps } from '@/constants';
import { useAuthStore, useLoadingStore, useWizardStore } from '@/stores';

export const useStepNavigate = (currentStep: WizardSteps) => {
  const { getLoading } = useLoadingStore((state) => state);
  const { updateUser } = useAuthStore((state) => state);
  const { setStep, step } = useWizardStore((state) => state);
  const { user } = useAuthStore((state) => state);
  const navigate = useNavigate();

  const goToStep = (stepValue: WizardSteps, distributor_type?: string, withNavigate: boolean = true) => {
    setStep({ login_step: stepValue }, step > stepValue, () => {
      updateUser(distributor_type ? { login_step: stepValue, distributor_type } : { login_step: stepValue });
      if (withNavigate) {
        navigate(stepsMapper[stepValue]);
      }
    });
  };
  useEffect(() => {
    if (user && (user?.login_step ?? WizardSteps.STEP_0) !== currentStep) {
      goToStep(currentStep, undefined, false);
    }
  }, []);

  return {
    goToStep,
    nextLoading: getLoading(LoadingKeys.setNextStep),
    distributorType: user?.distributor_type,
    isInternal: user?.distributor_type === 'internal',
    isExternal: user?.distributor_type === 'external',
  };
};
