import { SVGProps } from 'react';

export const UploadPlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.063.75h1.874c.167 0 .25.083.25.25v22c0 .167-.083.25-.25.25h-1.874c-.167 0-.25-.083-.25-.25V1c0-.167.083-.25.25-.25Z"
      fill="#000"
      fillOpacity={0.45}
    />
    <path
      d="M.5 10.813h21c.167 0 .25.083.25.25v1.874c0 .167-.083.25-.25.25H.5c-.167 0-.25-.083-.25-.25v-1.874c0-.167.083-.25.25-.25Z"
      fill="#000"
      fillOpacity={0.45}
    />
  </svg>
);
