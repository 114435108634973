import { Col, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { SearchProps } from 'antd/es/input';
import { useAddOrDeleteSearchParam } from '@/hooks';
import { PAGE, SEARCH_KEYWORD } from '@/constants';
import styles from './search-table-input.module.scss';

const { Search } = Input;

interface SearchTableInputProps {
  placeHolder?: string;
  onSubmit?: (value: string) => void;
  searchInputProps?: SearchProps;
}

export const SearchTableInput = ({ placeHolder, onSubmit, searchInputProps }: SearchTableInputProps) => {
  const { deleteSearchParam, updateSearchParams } = useAddOrDeleteSearchParam();
  const { t } = useTranslation();
  const onSearch = (value) => {
    onSubmit?.(value);
    if (value) {
      updateSearchParams({ [SEARCH_KEYWORD]: value, [PAGE]: '1' });
    } else {
      deleteSearchParam(SEARCH_KEYWORD);
    }
  };

  return (
    <Row>
      <Col lg={8}>
        <Search
          size="middle"
          enterButton
          className={styles.searchInput}
          placeholder={placeHolder ?? t('search')}
          onSearch={onSearch}
          {...(searchInputProps ?? {})}
        />
      </Col>
    </Row>
  );
};
