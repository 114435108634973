import { PermissionsKeys } from '@/constants/permission-keys';
import { useAuthStore } from '@/stores';
import { PermissionType } from '@/types';
import { useLanguage } from './use-language';

export const usePermission = () => {
  const { user } = useAuthStore((state) => state);
  const { language } = useLanguage();
  const translateComponent = (key: PermissionsKeys) => user?.permissions_keys?.[key]?.[`name_${language}`];
  const showComponent = (key: PermissionsKeys, type: PermissionType) =>
    !!user?.permissions?.[key]?.includes(type) || (key as string) === 'dashboard';

  return { showComponent, translateComponent };
};
