import { Avatar, Badge, Card, Flex, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { HomeOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { EnhancedImage } from '../Image/enhanced-image.component';
import { LocationIcon, StarIcon } from '../icons';
import { TableActions } from '../table-actions/table-actions.component';
import { CustomerItem } from '@/types';
import { PermissionsKeys } from '@/constants/permission-keys';
import { Paragraph } from '../paragraph/paragraph.component';
import styles from './customers.module.scss';

const { useToken } = theme;
const { Title } = Typography;

interface CustomerCardProps {
  onEditClick: () => void;
  onDeleteClick: () => void;
  data: CustomerItem;
}

export const CustomerCard = ({ onEditClick, onDeleteClick, data }: CustomerCardProps) => {
  const { t } = useTranslation();
  const { token } = useToken();

  return (
    <Card bordered className={styles.card}>
      <Flex className={styles.cardHead} justify="space-between" align="center" gap={10}>
        <Flex align="center" gap={5}>
          <Flex align="center" gap={15}>
            <Avatar src={data?.user?.logo} className={styles.avatar} size={32} icon={<UserOutlined />} />
            <Title className={styles.customerName} level={5}>
              {data?.user?.name}
            </Title>
          </Flex>
          <EnhancedImage fluid className={styles.badge} width={22} height={27} preview={false} src={data?.tier?.icon} />
        </Flex>
        {data?.user?.is_active && (
          <Badge
            className={styles.badge}
            color={data?.user?.is_active === 'active' ? token.colorSuccess : token.colorTextPlaceholder}
            text={data?.user?.is_active === 'active' ? t('active') : t('inActive')}
          />
        )}
      </Flex>
      <div className={styles.customerDetailsWrapper}>
        <ul>
          <li>
            <MailOutlined />
            {data?.user?.email}
          </li>
          <li>
            <PhoneOutlined />
            {data?.user?.country_code ?? ''} {data?.user?.mobile ?? ''}
          </li>
          <li>
            <LocationIcon />
            {data?.address ?? ''}
          </li>
          <li>
            <StarIcon />
            {data?.points ?? 0} {t('points')}
          </li>
          <li>
            <StarIcon />
            {data?.extra_points ?? 0} {t('extraPoints')}
          </li>
          <li>
            <HomeOutlined />
            {data?.company_name ?? 0}
          </li>
        </ul>
      </div>
      <Flex justify="space-between" align="center" gap={10} className={styles.footer}>
        <Paragraph>{data?.customer_code}</Paragraph>
        <TableActions
          permissionKey={PermissionsKeys.CUSTOMERS}
          handleEditClick={onEditClick}
          handleDeleteClick={onDeleteClick}
        />
      </Flex>
    </Card>
  );
};
