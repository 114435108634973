import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useAuthStore, useUserStore } from '@/stores';
import { EnhancedImage } from '../Image/enhanced-image.component';
import { ROUTES } from '@/constants';
import styles from './logo.module.scss';
import { useIsAdmin } from '@/hooks';

interface LogoProps {
  withLink?: boolean;
  isSmall?: boolean;
}

export const Logo = ({ withLink, isSmall = false }: LogoProps) => {
  const { companySettings } = useUserStore((state) => state);
  const isAdmin = useIsAdmin();
  return (
    <>
      {isAdmin ? (
        <div
          className={cn(styles.logoWrap, {
            [styles.smallScreenLogoWrap]: isSmall,
          })}
        >
          <EnhancedImage
            preview={false}
            fluid
            src="https://tlp-uploads.s3.me-central-1.amazonaws.com/public/logo-9f487efb-2e87-4570-b7c6-7c33dc4865e5-1731978296061.jpeg"
          />
        </div>
      ) : (
        <>
          {withLink ? (
            <Link
              className={cn(styles.logoWrap, {
                [styles.smallScreenLogoWrap]: isSmall,
              })}
              to={ROUTES.HOME}
            >
              <EnhancedImage preview={false} fluid src={companySettings?.logo} />
            </Link>
          ) : (
            <div
              className={cn(styles.logoWrap, {
                [styles.smallScreenLogoWrap]: isSmall,
              })}
            >
              <EnhancedImage preview={false} fluid src={companySettings?.logo} />
            </div>
          )}
        </>
      )}
    </>
  );
};
