import { create } from 'zustand';
import { message } from 'antd';
import { handleApiCallInStore } from './utils';
import { API_END_POINTS, LoadingKeys, TOAST_MESSAGES } from '@/constants';
import { convertToFormData, customHttp, http } from '@/utils';
import {
  AddOrEditItemPayload,
  ApiResponse,
  ApiResponseWithPagination,
  BasePaginationParams,
  IItemDetails,
  ProductItem,
  StringOrNumber,
} from '@/types';

interface IItemStore {
  items: ProductItem[];
  itemDetails: IItemDetails;
  totalItemsCount: number;
  getAllItems: (payload: BasePaginationParams & { sku_id?: number }) => void;
  deleteItem: (id: number, onSuccess: () => void) => void;
  addOrEditItem: (payload: AddOrEditItemPayload, onSuccess: () => void) => void;
  getItemById: (id: StringOrNumber) => void;
}

export const useItemStore = create<IItemStore>((set) => ({
  itemDetails: undefined,
  items: [],
  totalItemsCount: 0,
  getItemById: (id) => {
    handleApiCallInStore(LoadingKeys.getItemById, async () => {
      const { data } = await http.get<undefined, ApiResponse<IItemDetails>>(API_END_POINTS.itemDetails(id));
      set({ itemDetails: data });
    });
  },
  addOrEditItem: (payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.addOrEditItem, async () => {
      if (!payload.id) {
        await customHttp.post<FormData>(API_END_POINTS.items, convertToFormData<AddOrEditItemPayload>(payload));
      } else {
        await customHttp.put<FormData>(
          API_END_POINTS.updateItem(payload.id),
          convertToFormData<AddOrEditItemPayload>(payload),
        );
      }
      onSuccess();
    });
  },
  getAllItems: (payload) => {
    handleApiCallInStore(LoadingKeys.getAllItems, async () => {
      const { data } = await http.get<undefined, ApiResponseWithPagination<ProductItem>>(API_END_POINTS.items, {
        params: payload,
      });
      set({ items: data.results, totalItemsCount: data.total });
    });
  },
  deleteItem: (id, onSuccess) => {
    handleApiCallInStore(LoadingKeys.deleteItem, async () => {
      await http.delete(API_END_POINTS.deleteItem(id));
      onSuccess();
      message.success(TOAST_MESSAGES.DELETE_ITEM_SUCCESS);
    });
  },
}));
