import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';

interface SubmitCancelProps {
  handleSubmit?: () => void;
  handleCancel?: () => void;
  showCancel?: boolean;
  loading: boolean;
  submitBtnText?: string;
}

export const SubmitCancel = ({
  handleSubmit,
  submitBtnText,
  showCancel = true,
  handleCancel,
  loading,
}: SubmitCancelProps) => {
  const { t } = useTranslation();
  return (
    <Flex justify="flex-end" align="center" gap={10}>
      <Button loading={loading} onClick={handleSubmit} htmlType="submit" type="primary">
        {submitBtnText ?? t('submit')}
      </Button>
      {showCancel && <Button onClick={handleCancel}>{t('cancel')}</Button>}
    </Flex>
  );
};
