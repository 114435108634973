import { SVGProps } from 'react';

export const KeyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity={0.88}
      strokeWidth={2}
      d="M19.833 10.5c0-.597-.227-1.194-.683-1.65a2.326 2.326 0 0 0-1.65-.683m0 9.333a7 7 0 1 0-6.937-6.059c.068.507.102.76.079.92a.996.996 0 0 1-.136.404c-.08.142-.219.281-.497.56l-5.962 5.962c-.202.201-.303.302-.375.42a1.167 1.167 0 0 0-.14.337c-.032.134-.032.277-.032.563v2.026c0 .654 0 .98.127 1.23.112.22.29.398.51.51.25.127.576.127 1.23.127h2.8v-2.333H10.5v-2.334h2.333l1.843-1.842c.278-.278.417-.418.559-.497a.996.996 0 0 1 .404-.136c.16-.023.413.011.92.08.307.04.622.062.941.062Z"
    />
  </svg>
);
