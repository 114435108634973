import { useGenericStore } from './generic-store';
import { LoadingKeys } from '../constants/loading-keys';
import { API_END_POINTS } from '../constants/api-end-points';
import { IRedeemRequestPayload, RedeemRequestItem } from '@/types';

export const useRedeemRequestsStore = useGenericStore<RedeemRequestItem, IRedeemRequestPayload>(
  {
    getAllEndpoint: API_END_POINTS.redeemRequests,
    updateEndpoint: API_END_POINTS.redeemRequestsCrud,
  },
  {
    getAllLoadingKey: LoadingKeys.getAllRedeemRequests,
    createOrUpdateLoadingKey: LoadingKeys.changeRedeemRequestStatus,
  },
  false,
);
