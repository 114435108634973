import { Avatar, Card, Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './backlog.module.scss';

const { Title } = Typography;

export const Backlog = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <Title level={5}>{t('backlog')}</Title>
      </div>
      <Card bordered>
        <Flex gap={10} className={styles.backlogItem}>
          <Avatar size={48} />
          <div className={styles.backlogItemContent}>
            <Typography.Paragraph>
              Lana Steiner
              <span>2 mins ago</span>
            </Typography.Paragraph>
            <Typography.Paragraph>
              <span>Invited Alisa</span>
              to the team
            </Typography.Paragraph>
          </div>
        </Flex>
        <Flex gap={10} className={styles.backlogItem}>
          <Avatar size={48} />
          <div className={styles.backlogItemContent}>
            <Typography.Paragraph>
              Lana Steiner
              <span>2 mins ago</span>
            </Typography.Paragraph>
            <Typography.Paragraph>
              <span>Invited Alisa</span>
              to the team
            </Typography.Paragraph>
          </div>
        </Flex>
        <Flex gap={10} className={styles.backlogItem}>
          <Avatar size={48} />
          <div className={styles.backlogItemContent}>
            <Typography.Paragraph>
              Lana Steiner
              <span>2 mins ago</span>
            </Typography.Paragraph>
            <Typography.Paragraph>
              <span>Invited Alisa</span>
              to the team
            </Typography.Paragraph>
          </div>
        </Flex>
        <Flex gap={10} className={styles.backlogItem}>
          <Avatar size={48} />
          <div className={styles.backlogItemContent}>
            <Typography.Paragraph>
              Lana Steiner
              <span>2 mins ago</span>
            </Typography.Paragraph>
            <Typography.Paragraph>
              <span>Invited Alisa</span>
              to the team
            </Typography.Paragraph>
          </div>
        </Flex>
      </Card>
    </div>
  );
};
