import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { langLabel, optionalLabel } from '@/utils';
import { RULES } from '../rules';
import { FormField } from '@/types';

const useTopicFields = () => {
  const { t } = useTranslation();
  const topicFields = useMemo(
    (): FormField[] => [
      {
        label: langLabel(t('topicTitle'), 'en'),
        name: 'title_en',
        type: 'input',
        placeholder: t('enterTopicTitle'),
        rules: [RULES.required, RULES.englishOnly],
      },
      {
        label: langLabel(t('topicTitle'), 'ar'),
        name: 'title_ar',
        type: 'input',
        placeholder: t('enterTopicTitle'),
        rules: [RULES.required, RULES.arabicOnly],
      },
      {
        label: optionalLabel(langLabel(t('description'), 'en')),
        name: 'content_en',
        type: 'textarea',
        placeholder: t('content'),
        rules: [RULES.englishOnly],
      },
      {
        label: optionalLabel(langLabel(t('description'), 'ar')),
        name: 'content_ar',
        type: 'textarea',
        placeholder: t('content'),
        rules: [RULES.arabicOnly],
      },
      {
        label: t('image'),
        name: 'image',
        type: 'uploadImage',
        rules: [RULES.required],
        uploadImageProps: {
          maxImagesCount: 1,
          maxSizeInMB: 1,
        },
      },
    ],
    [],
  );
  return {
    topicFields,
  };
};

export default useTopicFields;
