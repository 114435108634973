import { t } from 'i18next';

export enum BusinessModelsTypes {
  NEW = 'new',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}
export const BUSINESS_MODEL_STATUS_MAPPER = {
  [BusinessModelsTypes.APPROVED]: { label: t('approved'), color: 'success', value: BusinessModelsTypes.APPROVED },
  [BusinessModelsTypes.NEW]: { label: t('new'), color: 'warning', value: BusinessModelsTypes.NEW },
  [BusinessModelsTypes.REJECTED]: { label: t('rejected'), color: 'error', value: BusinessModelsTypes.REJECTED },
};
