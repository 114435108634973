import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { FormInstance } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { FormField } from '@/types';
import { langLabel, optionalLabel } from '@/utils';
import { RULES } from '../rules';
import { API_END_POINTS } from '../api-end-points';
import { LoadingKeys } from '../loading-keys';
import { useGetInitialData } from '@/hooks';

interface BrandsFieldsArguments {
  subcategoryId?: string;
}

export const useBrandsFields = ({ subcategoryId }: BrandsFieldsArguments) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const [selectedCategoryId, setSelectedCategory] = useState<number>(undefined);
  const categoryIdValue = searchParams.get('category_id');

  const { initialData: initialCategoriesData, hasMore: categoriesHasMore } = useGetInitialData({
    apiEndPoint: API_END_POINTS.categories,
    loadingKey: LoadingKeys.getAllCategoriesLite,
  });
  const {
    initialData: initialSubcategories,
    hasMore: subcategoriesHasMore,
    fetchData: fetchInitialSubcategories,
  } = useGetInitialData({
    apiEndPoint: API_END_POINTS.subcategories,
    loadingKey: LoadingKeys.getAllSubcategoriesLite,
    fetchOnMount: false,
  });

  useEffect(() => {
    setSelectedCategory(categoryIdValue ? Number(categoryIdValue) : undefined);
  }, [categoryIdValue]);

  useEffect(() => {
    if (selectedCategoryId) {
      fetchInitialSubcategories(selectedCategoryId ? { category_id: selectedCategoryId } : {});
    }
  }, [selectedCategoryId]);

  const onCategoryChange = (categoryId: number, form: FormInstance) => {
    setSelectedCategory(categoryId);
    form.setFieldValue('sub_category_id', undefined);
  };

  const onResetSearch = () => {
    setSelectedCategory(undefined);
  };

  const brandsFields = useMemo(
    (): FormField[] => [
      {
        label: t('subcategory'),
        name: 'sub_category_id',
        type: 'infiniteSelect',
        rules: [RULES.required],
        placeholder: t('selectCategory'),
        infiniteSelectProps: {
          apiEndPoint: API_END_POINTS.subcategories,
          loadingKey: LoadingKeys.getAllCategoriesLite,
          disabled: !!subcategoryId,
        },
      },
      {
        label: langLabel(t('brandName'), 'en'),
        name: 'name_en',
        type: 'input',
        placeholder: t('enterBrandName'),
        rules: [RULES.required, RULES.englishOnly],
      },
      {
        label: langLabel(t('brandName'), 'ar'),
        name: 'name_ar',
        type: 'input',
        placeholder: t('enterBrandName'),
        rules: [RULES.required, RULES.arabicOnly],
      },
      {
        label: optionalLabel(langLabel(t('description'), 'en')),
        name: 'description_en',
        type: 'textarea',
        placeholder: t('description'),
        rules: [RULES.englishOnly],
      },
      {
        label: optionalLabel(langLabel(t('description'), 'ar')),
        name: 'description_ar',
        type: 'textarea',
        placeholder: t('description'),
        rules: [RULES.arabicOnly],
      },
      {
        label: optionalLabel(t('logo')),
        name: 'logo',
        type: 'uploadImage',
        uploadImageProps: {
          maxImagesCount: 1,
          maxSizeInMB: 1,
        },
      },
    ],
    [subcategoryId],
  );

  const tableFields = useMemo(
    (): FormField[] => [
      ...(!subcategoryId
        ? [
            {
              name: 'category_id',
              type: 'infiniteSelect' as const,
              placeholder: t('selectCategory'),
              colProps: {
                span: 24,
                sm: 12,
                lg: 8,
                xl: 6,
              },
              infiniteSelectProps: {
                apiEndPoint: API_END_POINTS.categories,
                loadingKey: LoadingKeys.getAllCategoriesLite,
                initialData: initialCategoriesData,
                initialHasMore: categoriesHasMore,
                size: 'middle' as const,
                onSelectChange: onCategoryChange,
              },
            },
            {
              name: 'sub_category_id',
              type: 'infiniteSelect' as const,
              placeholder: t('selectSubcategory'),
              colProps: {
                span: 24,
                sm: 12,
                lg: 8,
                xl: 6,
              },
              infiniteSelectProps: {
                apiEndPoint: API_END_POINTS.subcategories,
                loadingKey: LoadingKeys.getAllSubcategoriesLite,
                initialData: selectedCategoryId === undefined ? [] : initialSubcategories,
                initialHasMore: subcategoriesHasMore,
                size: 'middle' as const,
                disabled: !selectedCategoryId,
              },
            },
          ]
        : []),
    ],
    [
      subcategoryId,
      selectedCategoryId,
      initialSubcategories,
      subcategoriesHasMore,
      initialCategoriesData,
      categoriesHasMore,
    ],
  );
  return {
    brandsFields,
    tableFields,
    onResetSearch,
  };
};
