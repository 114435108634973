import { Editor } from '@ckeditor/ckeditor5-core';
import { UploadAdapter, FileLoader } from '@ckeditor/ckeditor5-upload'; // Corrected import
import { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { message } from 'antd';
import {
  Essentials,
  Paragraph,
  Bold,
  Italic,
  Strikethrough,
  Underline,
  Code,
  Subscript,
  Superscript,
  RemoveFormat,
  Link,
  AutoLink,
  Image,
  ImageToolbar,
  ImageStyle,
  Table,
  TableToolbar,
  BlockQuote,
  CodeBlock,
  MediaEmbed,
  HorizontalLine,
  SpecialCharacters,
  Undo,
  FontSize,
  FontFamily,
  FontColor,
  FontBackgroundColor,
  Highlight,
  WordCount,
  FileRepository,
  ClassicEditor,
  Heading,
  Font,
  List,
  TodoList,
  FindAndReplace,
  Alignment,
  ImageInline,
  ImageStyleEditing,
  ImageUpload,
  ImageCaption,
  ImageResize,
  ImageBlock,
  ImageBlockEditing,
} from 'ckeditor5';
import { convertToFormData, customHttp } from '@/utils';
import { API_END_POINTS } from '@/constants';
import { ApiResponse } from '@/types';

import i18n from '@/i18';

function uploadAdapter(loader: FileLoader): UploadAdapter {
  return {
    upload: () =>
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file;
          const { data } = await customHttp.post<FormData, ApiResponse<{ url: string }>>(
            API_END_POINTS.uploadFile,
            convertToFormData({ file }),
          );
          resolve({
            default: data?.url,
          });
        } catch (error) {
          reject();
          message.error(i18n.t('somethingWentWrongWhileUploadingTheFile'));
        }
      }),
    abort: () => {},
  };
}
function uploadPlugin(editor: Editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => uploadAdapter(loader);
}

interface TextEditorProps {
  editorData: string;
  setEditorData: (data: string) => void;
}

export const TextEditor = ({ editorData, setEditorData }: TextEditorProps) => {
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        config={{
          extraPlugins: [uploadPlugin],
          plugins: [
            Heading,
            Font,
            Essentials,
            Paragraph,
            Bold,
            Italic,
            Strikethrough,
            Underline,
            Code,
            Subscript,
            Superscript,
            RemoveFormat,
            Link,
            AutoLink,
            Image,
            ImageUpload,
            ImageCaption,
            ImageToolbar,
            ImageStyle,
            ImageStyleEditing,
            ImageResize,
            Table,
            TableToolbar,
            BlockQuote,
            CodeBlock,
            MediaEmbed,
            HorizontalLine,
            SpecialCharacters,
            Undo,
            FontSize,
            FontFamily,
            FontColor,
            FontBackgroundColor,
            Highlight,
            WordCount,
            FileRepository,
            ImageBlock,
            ImageBlockEditing,
            List,
            FontBackgroundColor,
            TodoList,
            AutoLink,
            FindAndReplace,
            Alignment,
            ImageInline,
          ],
          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'indent',
            'outdent',
            '|',
            'imageUpload',
            'blockQuote',
            'mediaEmbed',
            'undo',
            'redo',
          ],
          image: {
            toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
          },
        }}
        data={editorData}
        onChange={handleEditorChange}
      />
    </div>
  );
};
