import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { PARAM_RULES, RULES } from '../rules';
import { FormField, StringOrNumber } from '@/types';
import { STATUS_OPTIONS } from '../sales-constants';

export const useCompanyFields = (companyId: StringOrNumber) => {
  const { t } = useTranslation();

  const companyFields = useMemo(
    (): FormField[] => [
      {
        label: t('name'),
        name: 'name',
        type: 'input',
        placeholder: t('enterCompanyName'),
        rules: [RULES.required],
      },
      {
        label: t('subDomain'),
        name: 'sub_domain',
        type: 'input',
        rules: [RULES.required],
      },
      {
        label: t('email'),
        name: 'email',
        type: 'input',
        placeholder: t('enterYourEmail'),
        rules: [RULES.required, RULES.email],
      },

      ...(!companyId
        ? [
            {
              label: t('password'),
              name: 'password',
              type: 'password' as const,
              placeholder: t('createAPassword'),
              rules: [RULES.required, PARAM_RULES.min(8), RULES.whiteSpace],
            },
          ]
        : []),

      {
        label: t('phoneNumber'),
        name: 'mobile',
        type: 'phone',
        phoneSelectName: 'country_code',
        rules: [RULES.required, RULES.number, RULES.whiteSpace, RULES.mobile],
      },
      {
        label: t('registrationNumber'),
        name: 'registration_number',
        type: 'input',
        rules: [RULES.required, RULES.number, RULES.integers, RULES.whiteSpace],
      },
      {
        label: t('representativeFullName'),
        name: 'representative_full_name',
        type: 'input',
        rules: [RULES.required],
      },
      {
        label: t('logo'),
        name: 'logo',
        type: 'uploadImage',
        uploadImageProps: {
          maxImagesCount: 1,
          maxSizeInMB: 1,
        },
      },
    ],
    [companyId],
  );
  const companySearchFields = useMemo(
    (): FormField[] => [
      {
        label: t('status'),
        name: 'status',
        type: 'select',
        placeholder: t('selectStatus'),
        options: STATUS_OPTIONS,
      },
    ],
    [],
  );
  return {
    companyFields,
    companySearchFields,
  };
};
