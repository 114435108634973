import { SVGProps } from 'react';

export const ArrowBack = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 8H1m0 0 7 7M1 8l7-7"
      stroke="#000"
      strokeOpacity={0.45}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
