import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormField } from '@/types';
import { RULES } from '../rules';
import { REDEEM_REQUEST_STATUS_MAPPER } from '../customer-service-constants';
import { Tag } from 'antd';

export const useRedeemRequestFields = () => {
  const { t } = useTranslation();
  const changeRequestStatusFields = useMemo(
    (): FormField[] => [
      {
        name: 'reason',
        type: 'textarea',
        placeholder: t('typeTheReason'),
        rules: [RULES.required],
      },
    ],
    [],
  );
  const redeemRequestsTableFields = useMemo(
    (): FormField[] => [
      {
        label: t('status'),
        name: 'status',
        type: 'select',
        placeholder: t('selectStatus'),
        options: Object.values(REDEEM_REQUEST_STATUS_MAPPER)?.map((item) => ({
          label: <Tag color={item.color}>{item.label}</Tag>,
          value: item.value,
        })),
      },
      {
        label: t('date'),
        name: 'created_at',
        type: 'date',
        placeholder: t('selectDate'),
        datePickerProps: {
          format: 'YYYY-MM-DD',
        },
      },
    ],
    [],
  );
  return { changeRequestStatusFields, redeemRequestsTableFields };
};
