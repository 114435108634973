import { SVGProps } from 'react';

export const QuestionMark = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 1a7 7 0 1 0 .001 14.001A7 7 0 0 0 8 1Zm0 12.813A5.813 5.813 0 0 1 8 2.187a5.813 5.813 0 0 1 0 11.626Z"
      fill="#000"
      fillOpacity={0.45}
    />
    <path
      d="M9.744 4.948A2.634 2.634 0 0 0 8 4.312c-.656 0-1.275.227-1.744.636-.487.427-.756 1-.756 1.614v.12c0 .068.056.124.125.124h.75a.125.125 0 0 0 .125-.125v-.119c0-.689.673-1.25 1.5-1.25s1.5.561 1.5 1.25c0 .486-.344.932-.877 1.136a1.746 1.746 0 0 0-1.125 1.654v.335c0 .07.057.126.125.126h.75a.125.125 0 0 0 .125-.126v-.354a.755.755 0 0 1 .483-.7c.922-.355 1.517-1.167 1.517-2.07.002-.615-.267-1.188-.754-1.615Zm-2.369 6.49a.625.625 0 1 0 1.25 0 .625.625 0 0 0-1.25 0Z"
      fill="#000"
      fillOpacity={0.45}
    />
  </svg>
);
