import { OrderType, StringOrNumber } from '@/types';

export const API_END_POINTS = {
  login: '/auth/login',
  forgetPassword: '/auth/forgot-password',
  verifyOtp: '/auth/verify-otp',
  resetPassword: '/auth/reset-password',
  getAllBusinessModels: '/business-model',
  addBusinessModel: '/auth/wizard/add-business-model',
  addCustomBuisnessModel: '/auth/wizard/request-custom-business-model',
  changeStep: '/auth/change-step',
  getAllRoles: '/roles',
  addTeamMembers: '/auth/wizard/add-team-members',
  addSalesTeam: '/auth/wizard/add-sales-team',
  getAllTeamMembers: '/team-members',
  updateTeamMember: (id: string | number) => `/team-members/${id}`,
  deleteTeamMembers: '/auth/wizard/delete-team-members',
  getAllSalesTeam: '/sales_team?type=distributor',
  addExternalDistributor: '/auth/wizard/add-external-distributer',
  getSelectedBusinessModel: `/business-model/selected-by-company`,
  changeCompanySettings: '/auth/wizard/add-dates',
  settings: '/settings',
  updateSettings: (companyId: StringOrNumber) => `/settings/${companyId}`,
  categories: '/categories',
  subcategories: '/sub-categories',
  categorySubcategories: (categoryId: number) => `/categories/${categoryId}/sub-categories`,

  deleteCategory: (id: number) => `/categories/${id}`,
  updateCategory: (id: number) => `/categories/${id}`,
  updateSubcategory: (id: number) => `/sub-categories/${id}`,
  deleteSubcategory: (id: number) => `/sub-categories/${id}`,
  deleteBrand: (id: number) => `/brands/${id}`,
  updateBrand: (id: number) => `/brands/${id}`,
  subcategoryBrands: (subcategoryId: number) => `sub-categories/${subcategoryId}/brands`,
  brands: '/brands',
  SKUs: '/skus',
  updateSKU: (id: StringOrNumber) => `/skus/${id}`,
  deleteSKU: (id: StringOrNumber) => `/skus/${id}`,
  brandSKUs: (brandId: number) => `/brands/${brandId}/skus`,
  items: '/items',
  itemDetails: (itemId: StringOrNumber) => `/items/${itemId}`,
  updateItem: (itemId: StringOrNumber) => `/items/${itemId}`,
  deleteItem: (itemId: StringOrNumber) => `/items/${itemId}`,

  SKUItems: (SKUId: StringOrNumber) => `skus/${SKUId}/items`,
  getCategoryById: (categoryId: StringOrNumber) => `/categories/${categoryId}`,
  getSubcategoryById: (subcategoryId: StringOrNumber) => `/sub-categories/${subcategoryId}`,
  getBrandById: (brandId: StringOrNumber) => `/brands/${brandId}`,
  getSKUById: (SKUId: StringOrNumber) => `/skus/${SKUId}`,
  marketingGroups: '/marketing-groups',
  marketingGroupDetails: (id: StringOrNumber) => `/marketing-groups/${id}`,
  updateMarketingGroup: (groupId: StringOrNumber) => `/marketing-groups/${groupId}`,
  deleteMarketingGroup: (groupId: StringOrNumber) => `/marketing-groups/${groupId}`,
  assignItemToMarketingGroup: (groupId: StringOrNumber) => `marketing-groups/${groupId}/assign/item`,
  getAssignedItemsToMarketingGroup: (groupId: StringOrNumber) => `marketing-groups/${groupId}/items`,
  unassignItemFromMarketingGroup: (groupId: StringOrNumber) => `marketing-groups/${groupId}/unassign/item`,
  deleteTeamMemberById: (id: number) => `team-members/${id}`,
  addTeamMember: '/team-members',
  topics: '/topics',
  topicsCrud: (topicId: StringOrNumber) => `/topics/${topicId}`,
  distributors: '/distributors',
  distributorCrud: (id: StringOrNumber) => `/distributors/${id}`,
  salesTeam: '/sales_team',
  salesTeamMemberCrud: (id: StringOrNumber) => `/sales_team/${id}`,
  articles: '/articles',
  addArticle: (topicId: StringOrNumber) => `topics/${topicId}/articles`,
  articlesCrud: (topicId: StringOrNumber, articleId: StringOrNumber) => `topics/${topicId}/articles/${articleId}`,
  uploadFile: '/uploads',
  importFile: '/uploads/csv',
  dashboardData: `/dashboard`,
  pointsFormula: '/points/points-formula',
  partnerCategories: '/partner-categories',
  partnerCategoriesCrud: (categoryId: StringOrNumber) => `/partner-categories/${categoryId}`,

  updateItemPointFormula: (itemId: StringOrNumber) => `/points/points-formula/item/${itemId}`,
  profile: '/profile',
  tiers: '/tiers',
  logs: '/logs',
  tiersCrud: (tierId: StringOrNumber) => `/tiers/${tierId}`,
  pointFormulaOptions: '/points/options',
  customers: '/sales/customers',
  customersCrud: (customerId: StringOrNumber) => `/sales/customers/${customerId}`,
  tickets: '/sales/tickets',
  ticketsCrud: (ticketId: StringOrNumber) => `/sales/tickets/${ticketId}`,
  ticketTypes: '/company/ticket-types',
  ticketTypesCrud: (ticketTypeId: StringOrNumber) => `/company/ticket-types/${ticketTypeId}`,
  invoices: 'sales/invoices',
  invoicesCrud: (invoiceId: StringOrNumber) => `/sales/invoices/${invoiceId}`,
  getTicketComments: (ticketId: StringOrNumber) => `sales/tickets/${ticketId}/comments`,
  addTicketComment: (ticketId: StringOrNumber) => `sales/tickets/${ticketId}/comments`,
  calculatePoints: '/points/calculater',
  createOrder: '/points/orders',
  updateOrder: (orderId: StringOrNumber) => `/points/orders/${orderId}`,
  orders: '/points/orders',
  changeOrderStatus: (orderId) => `points/orders/${orderId}/status`,
  orderCrud: (orderId: StringOrNumber, orderType: OrderType) => `/points/order/${orderType}/${orderId}`,
  getCompanyDetailsByHostName: (hostname: string) => `companies/${hostname}/sub-domain`,
  entities: '/db/entities',
  partners: '/partners',
  partnersCrud: (partnerId: StringOrNumber) => `/partners/${partnerId}`,
  getOrUpdateRolePermissions: (roleId: StringOrNumber) => `/roles/${roleId}/permissions`,
  updateRole: (roleId: StringOrNumber) => `/roles/${roleId}`,
  addRole: `/roles`,
  redeemRequests: '/requests',
  redeemRequestsCrud: (requestId: StringOrNumber) => `requests/${requestId}/status`,
  businessModels: '/admin/business-model',
  businessModelsCrud: (id: StringOrNumber) => `/admin/business-model/${id}`,
  companies: '/companies',
  companiesCrud: (id: StringOrNumber) => `/companies/${id}`,
  customerRedemption: '/requests/redemption',
  redeemCard: '/requests/redeem',
};
