import { Outlet } from 'react-router-dom';
import styles from './auth-layout.module.scss';

export const AuthLayout = () => (
  <div className={styles.wrapper}>
    <div className={styles.backgroundWrapper}>
      <div className={styles.cardWrapper}>
        <Outlet />
      </div>
    </div>
  </div>
);
