import { Empty, Flex } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './empty.module.scss';

interface EmptyCompProps {
  description?: ReactNode;
}

export const EmptyComp = ({ description }: EmptyCompProps) => {
  const { t } = useTranslation();
  return (
    <Flex className={styles.emptyWrap} justify="center" align="center">
      <Empty description={description ?? t('thereIsNoDataToShow')} />
    </Flex>
  );
};
