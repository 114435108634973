import i18n from '@/i18';

export const TOAST_MESSAGES = {
  OTP_SENT_SUCCESS: i18n.t('OtpCodeSentSuccessfully'),
  OTP_VERIFY_SUCCESS: i18n.t('OtpVerifiedSuccessfully'),
  DELETE_CATEGORY_SUCCESS: i18n.t('deleteCategorySuccess'),
  DELETE_Subcategory_SUCCESS: i18n.t('deleteSubcategorySuccess'),
  DELETE_Brand_SUCCESS: i18n.t('deleteBrandSuccess'),
  DELETE_SKU_SUCCESS: i18n.t('deleteSKUSuccess'),
  DELETE_ITEM_SUCCESS: i18n.t('deleteItemSuccess'),
  DELETE_MARKETING_GROUP: i18n.t('deleteMarketingGroupSuccess'),
  ASSIGN_ITEM_TO_MARKETING_GROUP: i18n.t('itemAssignedSuccessfully'),
  UNASSIGN_ITEM_TO_MARKETING_GROUP: i18n.t('itemUnAssignedSuccessfully'),
  ACTIVATE_TEAM_MEMBER_SUCCESSFULLY: i18n.t('teamMemberActivatedSuccessfully'),
  DEACTIVATE_TEAM_MEMBER_SUCCESSFULLY: i18n.t('teamMemberDeactivatedSuccessfully'),
  DELETE_MESSAGE_SUCCESS: (name: string) => i18n.t('deleteMessageSuccess', { name }),
  ADD_SUCCESS: (name: string) => i18n.t('addMessageSuccess', { name }),
  UPDATE_SUCCESS: (name: string) => i18n.t('updateMessageSuccess', { name }),
};
