import { useGenericStore } from './generic-store';
import { LoadingKeys } from '../constants/loading-keys';
import { API_END_POINTS } from '../constants/api-end-points';
import { CustomerItem, IAddOrEditCustomer } from '@/types';

export const useCustomersStore = useGenericStore<CustomerItem, IAddOrEditCustomer, { status: string }>(
  {
    getAllEndpoint: API_END_POINTS.customers,
    deleteEndpoint: API_END_POINTS.customersCrud,
    getOneEndpoint: API_END_POINTS.customersCrud,
    updateEndpoint: API_END_POINTS.customersCrud,
  },
  {
    getAllLoadingKey: LoadingKeys.getAllCustomers,
    deleteLoadingKey: LoadingKeys.deleteCustomer,
    getOneLoadingKey: LoadingKeys.getCustomerDetails,
    createOrUpdateLoadingKey: LoadingKeys.createOrUpdateCustomer,
  },
  true,
);
