/* eslint-disable eqeqeq */
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ROUTES, stepsMapper, tokenAttr, userAttr, WizardSteps } from '@/constants';
import { isNullOrUndefined, localStorageHelper } from '@/utils';
import { ValueOf } from '@/types';

const RequireAuth = () => {
  const token = Cookies.get(tokenAttr);
  const user = localStorageHelper.get(userAttr);
  const location = useLocation();

  if (token && user) {
    const userStep: string = user?.login_step;
    const expectedPath: ValueOf<typeof ROUTES> = stepsMapper[userStep] ?? ROUTES.WELCOME;

    if (
      !isNullOrUndefined(userStep) &&
      parseInt(userStep) === WizardSteps.HOME &&
      Object.values(stepsMapper)
        .slice(0, -1)
        ?.includes(location.pathname as any)
    ) {
      return <Navigate to={ROUTES.HOME} replace />;
    }
    if (!isNullOrUndefined(userStep) && parseInt(userStep) !== WizardSteps.HOME) {
      // Check if the current pathname matches the expected path for the user's step
      if (location.pathname !== expectedPath) {
        // Redirect to the correct path for the current step
        return <Navigate to={expectedPath} replace />;
      }
    }

    return <Outlet />;
  }
  return <Navigate to={ROUTES.LOGIN} replace />;
};
export default RequireAuth;
