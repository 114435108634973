import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { ArrowBack } from '../icons';
import styles from './back-btn.module.scss';
import { useLoadingStore } from '@/stores';
import { LoadingKeys } from '@/constants';

interface BackBtnProps {
  onClick: () => void;
}

export const BackBtn = ({ onClick }: BackBtnProps) => {
  const { t } = useTranslation();
  const { getLoading } = useLoadingStore((state) => state);
  return (
    <button
      disabled={getLoading(LoadingKeys.setPrevStep)}
      className={styles.backBtn}
      type="button"
      aria-label={t('back')}
      onClick={onClick}
    >
      <span aria-label={t('back')} className={`${styles.arrowBackBtn} mirror-rtl`}>
        {getLoading(LoadingKeys.setPrevStep) ? (
          <Spin indicator={<LoadingOutlined spin />} size="small" />
        ) : (
          <ArrowBack />
        )}
      </span>
      {t('back')}
    </button>
  );
};
