import { SVGProps } from 'react';

export const SupportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 15.167c-.467 0-.94-.18-1.293-.534l-1.14-1.126a1.504 1.504 0 0 0-1.06-.434H4c-1.38 0-2.5-1.113-2.5-2.48V3.32C1.5 1.953 2.62.84 4 .84h8c1.38 0 2.5 1.113 2.5 2.48v7.273c0 1.367-1.12 2.48-2.5 2.48h-.507c-.4 0-.78.154-1.06.434l-1.14 1.126A1.833 1.833 0 0 1 8 15.167ZM4 1.833c-.827 0-1.5.667-1.5 1.48v7.274c0 .82.673 1.48 1.5 1.48h.507c.666 0 1.293.26 1.766.726l1.14 1.127c.327.32.854.32 1.18 0l1.14-1.127a2.503 2.503 0 0 1 1.767-.726h.5c.827 0 1.5-.667 1.5-1.48V3.313c0-.82-.673-1.48-1.5-1.48H4Z"
      fill="#292D32"
    />
    <path
      d="M6.92 9.673H5.133a.91.91 0 0 1-.74-.38.866.866 0 0 1-.126-.773c.233-.713.806-1.1 1.313-1.447.533-.36.833-.586.833-.973a.626.626 0 1 0-1.253 0c0 .273-.227.5-.5.5a.504.504 0 0 1-.5-.5c0-.893.727-1.627 1.627-1.627S7.413 5.2 7.413 6.1c0 .94-.706 1.42-1.273 1.807-.353.24-.687.466-.853.766h1.626c.274 0 .5.227.5.5 0 .274-.22.5-.493.5ZM10.694 9.673a.504.504 0 0 1-.5-.5v-.46H8.88a.923.923 0 0 1-.793-.453.909.909 0 0 1 0-.92c.453-.78.98-1.667 1.46-2.44a.886.886 0 0 1 1.64.473V7.72h.147c.273 0 .5.227.5.5s-.227.5-.5.5h-.14v.46c0 .273-.22.493-.5.493Zm-.5-3.913c-.394.64-.8 1.327-1.167 1.953h1.167V5.76Z"
      fill="#292D32"
    />
  </svg>
);
