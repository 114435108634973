import { SVGProps } from 'react';

export const MailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity={0.45}
      strokeWidth={1.333}
      d="m1.333 4.668 5.444 3.81c.44.309.66.463.9.523.212.053.434.053.646 0 .24-.06.46-.214.9-.523l5.444-3.81M4.534 13.335h6.933c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874c.218-.428.218-.988.218-2.108V5.868c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874c-.428-.218-.988-.218-2.108-.218H4.533c-1.12 0-1.68 0-2.107.218a2 2 0 0 0-.875.874c-.218.428-.218.988-.218 2.108v4.267c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .875.874c.427.218.987.218 2.107.218Z"
    />
  </svg>
);
