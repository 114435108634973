import React, { ReactNode } from 'react';
import { Button, ButtonProps, Flex } from 'antd';
import styles from './custom-button.module.scss';

type WidthType = 'default' | 'large';

interface CustomButtonProps extends ButtonProps {
  widthType?: WidthType;
  icon?: ReactNode;
  iconPosition?: 'start' | 'end';
}

export const CustomButton: React.FC<CustomButtonProps> = ({ widthType, icon, className = '', children, ...props }) => (
  <Button size="large" {...props} className={`${className} ${widthType === 'large' ? styles.largeButton : ''}`}>
    {icon ? (
      <Flex flex={1} align="center" justify="flex-end" gap={16.5}>
        {children}
        <Flex align="center" justify="center">
          {icon}
        </Flex>
      </Flex>
    ) : (
      children
    )}
  </Button>
);
