import { t } from 'i18next';
import { useMemo } from 'react';
import { FormField } from '@/types';
import { RULES } from '../rules';

export const useProfileFields = () => {
  const profileFields = useMemo(
    (): FormField[] => [
      {
        beforeTitle: t('personalInformation'),
        label: t('photo'),
        name: 'logo',
        type: 'uploadImage',
        uploadImageProps: {
          maxImagesCount: 1,
          maxSizeInMB: 1,
          listType: 'picture-circle',
        },
        rules: [RULES.required],
      },
      {
        label: t('fullName'),
        name: 'name',
        type: 'input',
        rules: [RULES.required],
      },
      {
        label: t('email'),
        name: 'email',
        type: 'input',
        rules: [RULES.email, RULES.required, RULES.whiteSpace],
        colProps: {
          span: 24,
          md: 12,
        },
      },
      {
        label: t('mobile'),
        name: 'mobile',
        type: 'phone',
        phoneSelectName: 'country_code',
        rules: [RULES.number, RULES.required, RULES.whiteSpace, RULES.mobile],
        colProps: {
          span: 24,
          md: 12,
        },
      },
    ],
    [],
  );
  return { profileFields };
};
