import { SVGProps } from 'react';

export const DeleteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.031 3.594h-.156c.086 0 .156-.07.156-.156v.156h5.938v-.156c0 .085.07.156.156.156h-.156V5h1.406V3.437c0-.689-.56-1.25-1.25-1.25h-6.25c-.69 0-1.25.561-1.25 1.25V5h1.406V3.594ZM16.875 5H3.125a.624.624 0 0 0-.625.625v.625c0 .086.07.156.156.156h1.18l.482 10.215a1.251 1.251 0 0 0 1.248 1.192h8.868c.668 0 1.216-.524 1.248-1.192l.482-10.215h1.18c.086 0 .156-.07.156-.156v-.625A.624.624 0 0 0 16.875 5Zm-2.592 11.406H5.717l-.473-10h9.512l-.473 10Z"
      fill="#000"
    />
  </svg>
);
