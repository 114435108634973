import { Button, Flex, Table, Typography } from 'antd';
import type { TableColumnsType } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { DeleteIcon, PlusIcon } from '../icons';
import { useModal } from '@/hooks';
import { INITIAL_PAGE_SIZE } from '@/constants';
import { DeleteModal } from '../delete-modal/delete-modal.component';
import styles from './wizard-table.module.scss';

const { Title } = Typography;

interface WizardTableProps<T> {
  title?: string;
  columns: TableColumnsType<T>;
  data: T[];
  page: number;
  total: number;
  pageSize?: number;
  loading: boolean;
  haveDelete?: boolean;
  handlePageChange: (page: number) => void;
  openAddModal: () => void;
  handleDelete?: (selectedIds: number[], onSuccess: () => void) => void;
  deleteLoading?: boolean;
}
export function WizardTable<T>({
  title,
  columns,
  data,
  page,
  total,
  loading,
  deleteLoading,
  pageSize = INITIAL_PAGE_SIZE,
  openAddModal,
  handlePageChange,
  handleDelete,
  haveDelete = true,
}: WizardTableProps<T>) {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const { t } = useTranslation();
  const { openModal, closeModal, isOpen } = useModal();

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedIds(selectedRowKeys as number[]);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  const handleDeleteItems = () => {
    handleDelete(selectedIds, () => {
      setSelectedIds([]);
      closeModal();
    });
  };
  return (
    <div>
      <Flex justify="space-between" className={styles.titleDeleteWrap}>
        <Title level={5}>{title}</Title>
        {selectedIds?.length > 0 && haveDelete && (
          <Button onClick={openModal} icon={<DeleteIcon />} type="text" block>
            {t('delete')}
          </Button>
        )}
      </Flex>
      <Table
        loading={loading}
        rowSelection={
          haveDelete && {
            type: 'checkbox',
            ...rowSelection,
          }
        }
        columns={columns}
        dataSource={data}
        pagination={{
          current: page,
          pageSize,
          total,
        }}
        rowKey="id"
        scroll={{ x: true }}
        onChange={(paginationConfig) => {
          handlePageChange(paginationConfig?.current);
        }}
      />
      <div className={styles.addOnesWrap}>
        <Button icon={<PlusIcon />} onClick={openAddModal} type="text" block>
          {t('addOnes')}
        </Button>
      </div>
      {isOpen && (
        <DeleteModal
          onCancel={closeModal}
          deleteMessage={t('deleteTeamMessage')}
          onOk={handleDeleteItems}
          loading={deleteLoading}
        />
      )}
    </div>
  );
}
