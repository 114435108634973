import { SVGProps } from 'react';

export const WalletIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={34} height={34} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.417 12.75h-8.5M31.167 15.54v2.92c0 .778-.624 1.416-1.417 1.444h-2.777c-1.53 0-2.932-1.119-3.06-2.649a2.845 2.845 0 0 1 .85-2.31 2.824 2.824 0 0 1 2.04-.85h2.947a1.455 1.455 0 0 1 1.417 1.446Z"
      stroke="#292D32"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.763 14.946a2.845 2.845 0 0 0-.85 2.309c.128 1.53 1.53 2.65 3.06 2.65h2.777v2.053c0 4.25-2.834 7.084-7.084 7.084H9.916c-4.25 0-7.083-2.834-7.083-7.084v-9.916c0-3.854 2.323-6.545 5.936-6.999a7.54 7.54 0 0 1 1.147-.085h12.75c.369 0 .723.014 1.063.071 3.655.425 6.02 3.131 6.02 7.013v2.054h-2.946c-.793 0-1.516.312-2.04.85Z"
      stroke="#292D32"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
