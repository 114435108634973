import { useLocation, useSearchParams } from 'react-router-dom';

export const useAddOrDeleteSearchParam = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const addOrEditSearchParam = (searchParam: string, value: string) => {
    searchParams.set(searchParam, value);
    setSearchParams(searchParams);
  };

  const updateSearchParams = (params: Record<string, string | null | undefined>) => {
    const newSearchParams = new URLSearchParams(searchParams);

    Object.entries(params).forEach(([key, value]) => {
      if (value === null || value === undefined) {
        newSearchParams.delete(key);
      } else {
        newSearchParams.set(key, value);
      }
    });

    setSearchParams(newSearchParams);
  };

  const deleteSearchParam = (searchParamValues: string | string[]) => {
    const params = new URLSearchParams(location.search);

    // Delete multiple parameters
    if (Array.isArray(searchParamValues)) {
      searchParamValues.forEach((key) => {
        params.delete(key);
      });
    } else {
      params.delete(searchParamValues);
    }

    setSearchParams(params);
  };

  return {
    addOrEditSearchParam,
    updateSearchParams,
    deleteSearchParam,
    searchParams,
  };
};
