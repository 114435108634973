/* eslint-disable camelcase */
import { create } from 'zustand';
import { message } from 'antd';
import { handleApiCallInStore } from './utils';
import { API_END_POINTS, LoadingKeys, TOAST_MESSAGES } from '@/constants';
import { http, updateObjStatusInArrById } from '@/utils';
import {
  AddOrEditTeamMember,
  ApiResponse,
  ApiResponseWithPagination,
  BasePaginationParams,
  IAddOrEditRole,
  PermissionType,
  RoleItem,
  RolePermissions,
  RolePermissionsType,
  StringOrNumber,
  TeamMemberStatus,
  TeamMemberTableItem,
} from '@/types';
import { PermissionsKeys } from '@/constants/permission-keys';

interface ITeamStore {
  roles: RoleItem[];
  rolePermissions: RolePermissions;
  totalRolesCount: number;
  getAllRoles: () => void;
  totalTeamsMembersNo: number;
  teamMembers: TeamMemberTableItem[];
  getAllTeamMembers: (payload: BasePaginationParams) => void;
  addOrEditTeamMember: (memberId: number, payload: Partial<AddOrEditTeamMember>, onSuccess: () => void) => void;
  deleteTeamMember: (id: number, onSuccess: () => void) => void;
  changeTeamMemberStatus: (memberId: number, status: TeamMemberStatus, onSuccess: () => void) => void;
  getRolePermissions: (roleId: StringOrNumber) => void;
  updateRolePermission: (permissionType: PermissionType, permissionKey: PermissionsKeys) => void;
  updateRolePermissions: (roleId: StringOrNumber, onSuccess: () => void) => void;
  addOrEditRole: (id: StringOrNumber, payload: IAddOrEditRole, onSuccess: () => void) => void;
}

export const useTeamStore = create<ITeamStore>((set, get) => ({
  rolePermissions: undefined,
  roles: [],

  teamMembers: [],
  totalRolesCount: 0,
  totalTeamsMembersNo: 0,
  addOrEditRole: (id, payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.addOrEditRole, async () => {
      if (!id) {
        await http.post<IAddOrEditRole>(API_END_POINTS.addRole, payload);
      } else {
        await http.put<IAddOrEditRole>(API_END_POINTS.updateRole(id), payload);
      }
      onSuccess();
    });
  },
  updateRolePermissions: (roleId, onSuccess) => {
    handleApiCallInStore(LoadingKeys.updateRolePermissions, async () => {
      await http.put<{ permissions: RolePermissionsType }>(API_END_POINTS.getOrUpdateRolePermissions(roleId), {
        permissions: get().rolePermissions?.permissions,
      });
      onSuccess();
    });
  },
  getRolePermissions: (roleId) => {
    handleApiCallInStore(LoadingKeys.getRolePermissions, async () => {
      const { data } = await http.get<undefined, ApiResponse<RolePermissions>>(
        API_END_POINTS.getOrUpdateRolePermissions(roleId),
      );
      set({ rolePermissions: data });
    });
  },
  updateRolePermission: (permissionType, permissionKey) => {
    const prevRolePermissions: RolePermissionsType = get().rolePermissions?.permissions;

    const newRolePermissions: RolePermissionsType = {
      ...prevRolePermissions,
      [permissionKey]: prevRolePermissions[permissionKey].map((perm) =>
        perm.permission_name === permissionType ? { ...perm, selected: !perm.selected } : perm,
      ),
    };
    set({
      rolePermissions: {
        role: get().rolePermissions.role,
        permissions: newRolePermissions,
      },
    });
  },
  changeTeamMemberStatus: (memberId, status, onSuccess) => {
    handleApiCallInStore(
      LoadingKeys.deleteTeamMember,
      async () => {
        set({ teamMembers: updateObjStatusInArrById(memberId, status, get().teamMembers) });
        message.destroy();
        message.success(
          status === 'active'
            ? TOAST_MESSAGES.DEACTIVATE_TEAM_MEMBER_SUCCESSFULLY
            : TOAST_MESSAGES.ACTIVATE_TEAM_MEMBER_SUCCESSFULLY,
        );
        await http.put<Partial<AddOrEditTeamMember>>(API_END_POINTS.updateTeamMember(memberId), {
          is_active: status === 'active' ? 'inactive' : 'active',
        });
        onSuccess();
      },
      () => {
        set({ teamMembers: updateObjStatusInArrById(memberId, status, get().teamMembers, false) });
      },
    );
  },
  deleteTeamMember: (id, onSuccess) => {
    handleApiCallInStore(LoadingKeys.deleteTeamMember, async () => {
      await http.delete<{ ids: number[] }>(API_END_POINTS.deleteTeamMemberById(id));
      onSuccess();
    });
  },
  addOrEditTeamMember: (memberId, payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.addOrEditTeamMember, async () => {
      if (memberId) {
        await http.put<Partial<AddOrEditTeamMember>>(API_END_POINTS.updateTeamMember(memberId), payload);
      } else {
        await http.post<Partial<AddOrEditTeamMember>>(API_END_POINTS.addTeamMember, payload);
      }
      onSuccess();
    });
  },
  getAllRoles: () => {
    handleApiCallInStore(LoadingKeys.getAllRoles, async () => {
      const { data } = await http.get<undefined, ApiResponse<RoleItem[]>>(API_END_POINTS.getAllRoles);
      set({ roles: data, totalRolesCount: data?.length });
    });
  },
  getAllTeamMembers: (payload) => {
    handleApiCallInStore(LoadingKeys.getAllTeamMembers, async () => {
      const { data } = await http.get<undefined, ApiResponseWithPagination<TeamMemberTableItem>>(
        API_END_POINTS.getAllTeamMembers,
        {
          params: payload,
        },
      );
      set({ totalTeamsMembersNo: data.total, teamMembers: data.results });
    });
  },
}));
