import { Button, Dropdown, Space } from 'antd';
import { MenuProps } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import i18n from '@/i18';
import { ROUTES } from '../../constants/routes';

const items: MenuProps['items'] = [
  {
    label: <Link to={ROUTES.CATEGORIES}>{i18n.t('categories')} </Link>,
    key: '0',
  },
  {
    type: 'divider',
  },
  {
    label: <Link to={ROUTES.SUBCATEGORIES}>{i18n.t('subCategories')} </Link>,
    key: '1',
  },
  {
    type: 'divider',
  },
  {
    label: <Link to={ROUTES.BRANDS}>{i18n.t('brands')} </Link>,
    key: '2',
  },
  {
    type: 'divider',
  },
  {
    label: <Link to={ROUTES.SKUS}>{i18n.t('SKU')} </Link>,
    key: '3',
  },
];

export const MaterDataDropdown = () => {
  const { t } = useTranslation();
  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Button type="primary" size="large" onClick={(e) => e.preventDefault()}>
        <Space>
          {t('manageMasterData')}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
