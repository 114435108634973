import { create } from 'zustand';
import { handleApiCallInStore } from './utils';
import { API_END_POINTS, LoadingKeys, ROUTES } from '../constants';
import {
  ApiResponse,
  ICompanyDetailsByHostName,
  ICompanySettings,
  StringOrNumber,
  UpdateSettingsPayload,
} from '@/types';
import { convertToFormData, customHttp, http, localStorageHelper } from '@/utils';

const defaultMainColor = '#000000';
const defaultSecondaryColor = '#000000';
const companySettingsAttr = 'companySettings';
interface IUserStoreState {
  getCompanyDataByHostName: () => void;
  companySettings: Partial<ICompanySettings>;
  getCompanySettings: (loadingKey?: LoadingKeys, onSuccess?: (data: ICompanySettings) => void) => void;
  updateCompanySettings: (
    companyId: StringOrNumber,
    settings: UpdateSettingsPayload & { start_date: string; renewal_date: string },
    onSuccess: () => void,
  ) => void;
  setCompanySettings: (payload: Partial<ICompanySettings>) => void;
}

export const useUserStore = create<IUserStoreState>((set, get) => ({
  companySettings:
    localStorageHelper.get(companySettingsAttr) ??
    ({ main_color: '#000000', secondary_color: '#000000' } as ICompanySettings),

  getCompanyDataByHostName: () => {
    if (window.location?.pathname === ROUTES.ADMIN_LOGIN) {
      get().setCompanySettings({});
    } else {
      handleApiCallInStore(
        LoadingKeys.getCompanyDataByHostName,
        async () => {
          const { hostname } = new URL(window.location.href);
          const { data } = await http.get<undefined, ApiResponse<ICompanyDetailsByHostName>>(
            API_END_POINTS.getCompanyDetailsByHostName(hostname),
          );
          const { settings, ...otherData } = data;
          get().setCompanySettings({
            ...(localStorageHelper.get(companySettingsAttr) ?? {}),
            ...otherData,
            ...(settings ?? {}),
          });
        },
        () => {
          get().setCompanySettings({});
        },
      );
    }
  },
  setCompanySettings: (payload) => {
    const finalPayload = {
      ...payload,
      main_color: payload?.main_color ?? defaultMainColor,
      secondary_color: payload?.secondary_color ?? defaultSecondaryColor,
    };
    localStorageHelper.set(companySettingsAttr, finalPayload);
    set({
      companySettings: finalPayload,
    });
  },
  getCompanySettings: (loadingKey, onSuccess) => {
    handleApiCallInStore(loadingKey ?? LoadingKeys.getCompanySettingsData, async () => {
      const { data } = await http.get<undefined, ApiResponse<ICompanySettings>>(API_END_POINTS.settings);
      onSuccess?.(data);
      get().setCompanySettings(data);
    });
  },

  updateCompanySettings: (companyId, payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.updateCompanySettings, async () => {
      const { data } = await customHttp.put<FormData, ApiResponse<ICompanySettings>>(
        API_END_POINTS.updateSettings(companyId),
        convertToFormData<UpdateSettingsPayload>(payload),
      );
      get().setCompanySettings(data);
      onSuccess?.();
    });
  },
}));
