import { t } from 'i18next';

export enum CUSTOMER_STATUS_TYPES {
  ACTIVE = 'active',
  IN_ACTIVE = 'inactive',
}

export const STATUS_OPTIONS = [
  { label: t('active'), value: 'active' },
  { label: t('inActive'), value: 'inactive' },
];
export const STATUS_OPTIONS_WITH_BOOLEANS = [
  { label: t('active'), value: 'true' },
  { label: t('inActive'), value: 'false' },
];
export enum TICKET_STATUS_TYPES {
  NEW = 'new',
  ON_GOING = 'on-going',
  RESOLVED = 'resolved',
  CLOSED = 'closed',
}

export enum TICKET_PRIORITY_TYPES {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  URGENT = 'urgent',
}
export const TICKET_STATUS_MAPPER = {
  [TICKET_STATUS_TYPES.NEW]: { label: t('new'), color: '#3B8AFF', value: 'new' },
  [TICKET_STATUS_TYPES.RESOLVED]: { label: t('onGoing'), color: '#FAC885', value: 'on-going' },
  [TICKET_STATUS_TYPES.ON_GOING]: { label: t('resolved'), color: '#54C104', value: 'resolved' },
  [TICKET_STATUS_TYPES.CLOSED]: { label: t('closed'), color: '#00000040', value: 'closed' },
};

export const TICKET_PRIORITY_TYPES_MAPPER = {
  [TICKET_PRIORITY_TYPES.LOW]: { label: t('low'), value: TICKET_PRIORITY_TYPES.LOW, color: 'processing' },
  [TICKET_PRIORITY_TYPES.MEDIUM]: { label: t('medium'), value: TICKET_PRIORITY_TYPES.MEDIUM, color: 'success' },
  [TICKET_PRIORITY_TYPES.HIGH]: { label: t('high'), value: TICKET_PRIORITY_TYPES.HIGH, color: 'orange' },
  [TICKET_PRIORITY_TYPES.URGENT]: { label: t('urgent'), value: TICKET_PRIORITY_TYPES.URGENT, color: 'error' },
};

export enum SALES_INVOICE_STATUS {
  PENDING = 'pending',
  REJECTED = 'rejected',
  APPROVED = 'approved',
  DRAFT = 'draft',
}

export enum ORDER_STATUS {
  NEW = 'New',
  IN_PROGRESS = 'In Progress',
  CLOSED = 'Closed',
}

export enum ORDER_CLOSED_REASONS {
  SENT = 'Order sent to the process team',
  DUMMY = 'Dummy order',
  OTHER = 'Other',
}

export enum ORDER_TYPES {
  ITEM = 'item',
  MARKETING_GROUP = 'marketing_group',
}
export const TICKET_SALES_INVOICE_STATUS_MAPPER = {
  [SALES_INVOICE_STATUS.PENDING]: { label: t('pending'), value: 'pending', color: 'warning' },
  [SALES_INVOICE_STATUS.REJECTED]: { label: t('rejected'), value: 'rejected', color: 'error' },
  [SALES_INVOICE_STATUS.APPROVED]: { label: t('approved'), value: 'approved', color: 'success' },
  [SALES_INVOICE_STATUS.DRAFT]: { label: t('draft'), value: 'draft', color: 'default' },
};

export const ORDER_STATUS_MAPPER = {
  [ORDER_STATUS.NEW]: { label: t('new'), value: ORDER_STATUS.NEW, color: 'processing' },
  [ORDER_STATUS.IN_PROGRESS]: { label: t('inProgress'), value: ORDER_STATUS.IN_PROGRESS, color: 'warning' },
  [ORDER_STATUS.CLOSED]: { label: t('closed'), value: ORDER_STATUS.CLOSED, color: 'success' },
};

export const ORDER_CLOSED_REASONS_MAPPER = {
  [ORDER_CLOSED_REASONS.SENT]: { label: t('sentOrder'), value: ORDER_CLOSED_REASONS.SENT },
  [ORDER_CLOSED_REASONS.DUMMY]: { label: t('dummyOrder'), value: ORDER_CLOSED_REASONS.DUMMY },
  [ORDER_CLOSED_REASONS.OTHER]: { label: t('other'), value: ORDER_CLOSED_REASONS.OTHER },
};
