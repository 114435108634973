/* eslint-disable no-nested-ternary */
import { Col, Flex, Form, Input, InputNumber, Row, Select, UploadFile, ColorPicker, DatePicker } from 'antd';
import { Fragment, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { FormInstance } from 'antd/lib';
import { Gutter } from 'antd/es/grid/row';
import { useWatch } from 'antd/es/form/Form';
import { FormField, InfiniteScrollSelectProps } from '@/types';
import { BoxUploader } from '../box-uploader/box-uploader.component';
import { CURRENCIES } from '@/constants';
import { CountriesSelect } from '../phone-input/phone-input.component';
import { InfoTooltip } from '../info-tootlip/info-tooltip.component';
import { InfiniteScrollSelect } from '../infinite-scroll-select/infinite-scroll-select.component';
import { useOnSearchSubmit } from '@/hooks';
import { getOriginFileObjectFromFiles, makeFileObj } from '@/utils';

import styles from './dynamic-form.module.scss';
import { TextEditor } from '../text-editor/text-editor.component';
import { SectionsHeading } from '../sections-heading/section-heading.component';

const { Option } = Select;

interface DynamicFormProps {
  formFields: FormField[];
  children?: ReactNode | ((renderFormField: (field: FormField) => ReactNode) => ReactNode);
  form: FormInstance<any>;
  initialValues?: Record<string, any>;
  gutter?: Gutter | [Gutter, Gutter];
  innerChildren?: ReactNode | ReactNode[];
  useWatch?: typeof useWatch;
}

const CurrencySelect = () => (
  <Select disabled defaultValue={CURRENCIES[0].id} style={{ width: 60 }}>
    {CURRENCIES.map((item) => (
      <Option key={item.id} value={item.id}>
        {item.title}
      </Option>
    ))}
  </Select>
);

export const DynamicForm = ({
  formFields,
  gutter,
  initialValues,
  children,
  form,
  innerChildren,
  useWatch,
}: DynamicFormProps) => {
  const initialValuesRef = useRef(null);
  const initialSetValuesRef = useRef(null);

  const [images, setImages] = useState<{ [key: string]: UploadFile[] }>({});
  const { onSearch } = useOnSearchSubmit();

  useEffect(() => {
    if (initialValues && initialSetValuesRef.current !== JSON.stringify(initialValues)) {
      initialSetValuesRef.current = JSON.stringify(initialValues);
      form.setFieldsValue(initialValues);
    }
  }, [initialValues]);

  // Dynamically watch all fields that have dependencies
  const watchedFields = formFields.reduce((acc: any, field) => {
    if (field.dependsOn && useWatch) {
      acc[field.dependsOn] = useWatch(field.dependsOn);
    }
    return acc;
  }, {});

  useEffect(() => {
    if (initialValues && initialValuesRef.current !== JSON.stringify(initialValues)) {
      initialValuesRef.current = JSON.stringify(initialValues);
      const initialImages: { [key: string]: UploadFile[] } = {};
      let hasImages = false;
      formFields.forEach((field) => {
        const fieldValue = initialValues[field.name];
        if (field.type === 'uploadImage' && fieldValue && fieldValue !== '[object Object]') {
          hasImages = true;
          initialImages[field.name] = Array.isArray(fieldValue)
            ? fieldValue.map((item) => makeFileObj(item))
            : [makeFileObj(fieldValue)];
        }
      });

      // eslint-disable-next-line no-unused-expressions
      if (hasImages) {
        setImages(initialImages);
      }
    }
  }, [initialValues, formFields]);

  const renderFormField = useCallback(
    (field: FormField) => {
      switch (field.type) {
        case 'input':
          return (
            <Input size="large" placeholder={field.placeholder} {...(field?.inputProps ?? {})} spellCheck dir="auto" />
          );
        case 'password':
          return <Input.Password size="large" placeholder={field.placeholder} {...(field?.inputProps ?? {})} />;
        case 'search':
          return (
            <Input.Search
              className={styles.searchInput}
              size="large"
              placeholder={field.placeholder}
              enterButton
              onSearch={onSearch}
              {...(field?.searchInputProps ?? {})}
              spellCheck
              dir="auto"
            />
          );
        case 'richText':
          return (
            <div className={styles.editorWrap}>
              <TextEditor
                editorData={form.getFieldValue(field.name)}
                setEditorData={(data) => form.setFieldsValue({ [field.name]: data })}
              />
            </div>
          );

        case 'color':
          return (
            <ColorPicker
              onChange={(_value, colorValue) =>
                form.setFieldsValue({
                  [field.name]: colorValue,
                })
              }
              {...(field.colorPickerProps ?? {})}
              size="large"
              showText
            />
          );
        case 'select':
          return (
            <Select size="large" placeholder={field.placeholder} {...(field?.selectProps ?? {})}>
              {field.options.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          );
        case 'date':
          return <DatePicker {...(field?.datePickerProps ?? {})} size="large" width="100%" />;
        case 'infiniteSelect':
          return (
            <InfiniteScrollSelect
              form={form}
              placeholder={field.placeholder}
              {...(field?.infiniteSelectProps ?? ({} as InfiniteScrollSelectProps))}
            />
          );
        case 'textarea':
          return (
            <Input.TextArea
              size="large"
              placeholder={field.placeholder}
              {...(field?.textareaProps ?? {})}
              dir="auto"
              spellCheck
            />
          );
        case 'phone':
          return (
            <Input
              size="large"
              placeholder={field.placeholder ?? 'xxxxxxxxxxxx'}
              addonBefore={
                <Form.Item initialValue="+966" name={field.phoneSelectName} noStyle>
                  <CountriesSelect form={form} fieldName={field.phoneSelectName ?? 'country_code'} />
                </Form.Item>
              }
              {...(field?.inputProps ?? {})}
              dir="auto"
            />
          );
        case 'number':
          return <InputNumber size="large" placeholder={field.placeholder} {...(field?.inputNumberProps ?? {})} />;
        case 'price':
          return (
            <InputNumber
              min={0}
              size="large"
              placeholder={field.placeholder}
              addonBefore={<CurrencySelect />}
              {...(field?.inputNumberProps ?? {})}
            />
          );
        case 'uploadImage':
          return (
            <BoxUploader
              {...field?.uploadImageProps}
              fileList={images[field.name] || []}
              onChangeFileList={(files) => {
                setImages((prev) => ({
                  ...prev,
                  [field.name]: files,
                }));
                form.setFieldsValue({
                  [field.name]:
                    files?.length === 0
                      ? undefined
                      : field.uploadImageProps.maxImagesCount === 1
                        ? (files[0].originFileObj ?? files[0].url)
                        : getOriginFileObjectFromFiles(files),
                });
              }}
            />
          );
        default:
          return null;
      }
    },
    [formFields, images],
  );

  return (
    <>
      <Row gutter={gutter ?? 16}>
        {formFields.map((field) => {
          if (field.dependsOn) {
            const dependentValue = watchedFields[field.dependsOn];
            if (!field.showWhen(dependentValue)) {
              return null;
            }
          }

          return (
            <Fragment key={field?.name}>
              {field?.beforeTitle && (
                <Col span={24}>
                  <SectionsHeading title={field?.beforeTitle} subtitle={field?.beforeSubtitle} />
                </Col>
              )}
              <Col key={field.name} span={field.colProps ? field.colProps?.span : 24} {...(field.colProps ?? {})}>
                <Form.Item
                  label={
                    field?.tooltipText ? (
                      <Flex gap={5}>
                        {field.label}
                        <InfoTooltip inLabel text={field.tooltipText} />
                      </Flex>
                    ) : (
                      field.label
                    )
                  }
                  name={field.name}
                  rules={field.rules}
                >
                  {renderFormField(field)}
                </Form.Item>
              </Col>
            </Fragment>
          );
        })}
        {innerChildren}
      </Row>
      {children && typeof children === 'function' ? children(renderFormField) : children}
    </>
  );
};
