import { FC, useState, useEffect, useMemo } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { SidebarNavigationItem, useSidebarNavigation } from '@/constants';
import { useAppStore } from '@/stores';
import { usePermission, useResponsive } from '@/hooks';
import { PermissionType } from '@/types';
import { PermissionsKeys } from '@/constants/permission-keys';
import styles from './sider-menu.module.scss';

export const SiderMenu: FC = () => {
  const { translateComponent, showComponent } = usePermission();
  const { sidebarItems } = useSidebarNavigation();
  const sidebarNavFlat = useMemo(
    () =>
      sidebarItems.reduce(
        (result: SidebarNavigationItem[], current) =>
          result.concat(current.children && current.children.length > 0 ? current.children : current),
        [],
      ),
    [sidebarItems],
  );
  const location = useLocation();
  const { isDesktop } = useResponsive();
  const { setCollapsed } = useAppStore();

  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = sidebarItems.find(({ children }) => children?.some(({ url }) => url === location.pathname));
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  const [openKeys, setOpenKeys] = useState<string[]>(defaultOpenKeys);
  const [selectedKeys, setSelectedKeys] = useState<string[]>(defaultSelectedKeys);

  useEffect(() => {
    const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname);
    setSelectedKeys(currentMenuItem ? [currentMenuItem.key] : []);

    const openedSubmenu = sidebarItems.find(({ children }) => children?.some(({ url }) => url === location.pathname));
    setOpenKeys(openedSubmenu ? [openedSubmenu.key] : []);
  }, [location.pathname]);

  const handleMenuClick = () => {
    if (!isDesktop) {
      setCollapsed(true);
    }
  };

  const handleOpenChange = (keys: string[]) => {
    setOpenKeys(keys);
  };

  return (
    <Menu
      className={styles.menu}
      mode="inline"
      openKeys={openKeys}
      selectedKeys={selectedKeys}
      onClick={handleMenuClick}
      onOpenChange={handleOpenChange}
      items={sidebarItems.map((nav) => {
        const isSubMenu = nav.children?.length;
        const title = translateComponent(nav.key as PermissionsKeys) ?? nav?.title;
        return showComponent(nav.key as PermissionsKeys, PermissionType.View)
          ? {
              key: nav.key,
              title,
              label: isSubMenu ? title : <Link to={nav.url || ''}>{title}</Link>,
              icon: nav.icon,
              children:
                isSubMenu &&
                nav.children &&
                nav.children.map((childNav) => ({
                  key: childNav.key,
                  label: <Link to={childNav.url || ''}>{childNav.title}</Link>,
                  title: childNav.title,
                })),
            }
          : null;
      })}
    />
  );
};
