import { UserOutlined } from '@ant-design/icons';
import { Avatar, Card, Flex, Typography } from 'antd';
import { TicketComment } from '@/types';
import { Paragraph } from '../paragraph/paragraph.component';
import styles from './ticket-comment.module.scss';

interface TicketCommentCardProps {
  data: TicketComment;
  loading: boolean;
}
export const TicketCommentCard = ({ data, loading }: TicketCommentCardProps) => (
  <Card loading={loading} bordered className={styles.commentCard}>
    <Flex gap={4} align="center">
      <Avatar src={data?.creator?.logo} size={25} icon={<UserOutlined />} />
      <Typography className={styles.avatarUser}>{data?.creator?.name}</Typography>
    </Flex>
    <Paragraph>{data?.content}</Paragraph>
  </Card>
);
