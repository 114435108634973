import { FC } from 'react';
import { Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './steps.module.scss';
import { useWizardStore } from '@/stores';

const StepsComp: FC = () => {
  const { t } = useTranslation();
  const { phase } = useWizardStore((state) => state);
  return (
    <Steps
      className={styles.stepsWrapper}
      direction="vertical"
      current={phase - 1}
      items={[
        {
          title: t('buisnessModel'),
          description: t('selectYourBusinessModel'),
        },
        {
          title: t('distributor'),
          description: t('distributorDesc'),
        },
        {
          title: t('roles'),
          description: t('rolesDesc'),
        },
        {
          title: t('teams'),
          description: t('teamMembersDesc'),
        },

        {
          title: t('companySettings'),
          description: t('addCompanySettings'),
        },
      ]}
    />
  );
};

export default StepsComp;
