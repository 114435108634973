/* eslint-disable eqeqeq */
import { Navigate, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ROUTES, tokenAttr, userAttr } from '@/constants';
import { localStorageHelper } from '@/utils';

const AuthRedirect = () => {
  const token = Cookies.get(tokenAttr);
  const user = localStorageHelper.get(userAttr);
  if (token && user) {
    return <Navigate to={ROUTES.HOME} replace />;
  }
  return <Outlet />;
};
export default AuthRedirect;
