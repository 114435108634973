/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Card, Dropdown, Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { EnhancedImage, Paragraph } from '@/components';
import styles from './topic-card.module.scss';
import { TopicItem } from '@/types';

const { Title } = Typography;

interface TopicCardProps {
  onClick: () => void;
  data: TopicItem;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  showDescription?: boolean;
}

export const TopicCard = ({ onClick, data, showDescription = true, onEditClick, onDeleteClick }: TopicCardProps) => {
  const { t } = useTranslation();
  const items = [
    {
      key: '1',
      label: t('edit'),
      extra: <EditOutlined />,
      onClick: onEditClick,
    },
    {
      key: '2',
      label: t('delete'),
      extra: <DeleteOutlined />,
      onClick: onDeleteClick,
    },
  ];
  return (
    <Card
      className={styles.topicCard}
      cover={<EnhancedImage onClick={onClick} preview={false} src={data.image} contain />}
    >
      <Card.Meta
        title={
          <Title className={styles.topicTitle} onClick={onClick} level={5}>
            {data?.title}
          </Title>
        }
        description={showDescription ? data?.content : ''}
      />
    </Card>
  );
};
