import { SVGProps } from 'react';

export const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill="#000"
      fillOpacity={0.88}
      d="M7.531 2.375h.938c.083 0 .125.042.125.125v11c0 .083-.042.125-.125.125H7.53c-.083 0-.125-.042-.125-.125v-11c0-.083.042-.125.125-.125Z"
    />
    <path
      fill="#000"
      fillOpacity={0.88}
      d="M2.75 7.406h10.5c.083 0 .125.042.125.125v.938c0 .083-.042.125-.125.125H2.75c-.083 0-.125-.042-.125-.125V7.53c0-.083.042-.125.125-.125Z"
    />
  </svg>
);
