import { SVGProps } from 'react';

export const CheckedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={14} fill="none" {...props}>
    <path
      fill="#000"
      d="M15.812.71h-1.365a.622.622 0 0 0-.49.24l-8.053 10.2-3.862-4.892a.625.625 0 0 0-.49-.238H.187c-.131 0-.203.15-.123.251l5.35 6.778c.25.316.73.316.982 0L15.935.96c.08-.1.008-.25-.123-.25Z"
    />
  </svg>
);
