import { Typography } from 'antd';
import { PropsWithChildren } from 'react';
import styles from './sections-heading.module.scss';

const { Title } = Typography;
interface SectionsHeadingProps {
  title: string;
  subtitle?: string;
  className?: string;
}
export const SectionsHeading = ({ title, subtitle, className = '' }: PropsWithChildren<SectionsHeadingProps>) => (
  <div className={`${styles.sectionTitleWrapper} ${className}`}>
    <Title level={4}>{title}</Title>
    {subtitle && <Typography.Paragraph>{subtitle}</Typography.Paragraph>}
  </div>
);
