import { useState, useEffect } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import qs from 'query-string';
import { ADDED_INFINITE_NAME, INITIAL_PAGE_SIZE } from '@/constants';
import { BasePaginationParams } from '@/types';
import { useAddOrDeleteSearchParam } from './use-add-or-delete-search-param';

interface UsePaginationProps {
  limit?: number;
  apiCall: ({ page, limit }: { page: number; limit: number }) => void;
  dependencies?: any[];
}

export const usePagination = ({ limit, apiCall, dependencies = [] }: UsePaginationProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(limit ?? INITIAL_PAGE_SIZE);
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams({});
  const { addOrEditSearchParam, deleteSearchParam } = useAddOrDeleteSearchParam();

  const location = useLocation();

  const handlePageChange = (page: number) => {
    addOrEditSearchParam('page', String(page));
  };

  const handlePageSizeChange = (_current, size) => {
    setPageSize(size);
    addOrEditSearchParam('page', String(1));
  };

  const paginate = (searchFields?: Record<string, string | string[]>) => {
    const payload: BasePaginationParams = {
      limit: pageSize,
      page: 1,
    };
    if (searchFields && Object.values(searchFields)?.length > 0) {
      Object.keys(searchFields).forEach((key) => {
        switch (key) {
          case 'page': {
            const pageNumber = parseInt(searchFields[key] as string);
            payload.page = pageNumber;
            setCurrentPage(pageNumber);
            break;
          }
          case 'keyword': {
            payload.keyword = searchFields[key] as string;
            break;
          }
          default:
            if (!key.includes(ADDED_INFINITE_NAME)) {
              payload[key] = searchFields[key];
            }
        }
      });
    }

    apiCall(payload);
  };

  useEffect(() => {
    paginate(qs.parse(location?.search));
  }, [searchParams, ...dependencies]);

  const callApi = (withReset: boolean = false) => {
    if (withReset) {
      addOrEditSearchParam('page', String(1));
      if (currentPage === 1) {
        paginate(qs.parse(location?.search));
      }
    } else {
      paginate(qs.parse(location?.search));
    }
  };

  return {
    currentPage,
    pageSize,
    handlePageChange,
    handlePageSizeChange,
    callApi,
  };
};
