import { useTranslation } from 'react-i18next';
import { QuestionIcon } from '@/components/icons';
import styles from './help-btn.module.scss';

export const HelpBtn = () => {
  const { t } = useTranslation();
  return (
    <button title={t('help')} className={styles.footerHelpBtn} aria-label={t('help')} type="button">
      <QuestionIcon />
      {t('help')}
    </button>
  );
};
