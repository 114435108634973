import {
  AppstoreAddOutlined,
  BorderOuterOutlined,
  CalculatorOutlined,
  CompassOutlined,
  ContainerOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  PicCenterOutlined,
  ProfileOutlined,
  SettingOutlined,
  TableOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { ROUTES } from './routes';
import i18n from '@/i18';
import { GiftIcon, PartnersIcon, ReceiptIcon, ShoppingCart, UsersIcon } from '@/components';
import { PermissionsKeys } from './permission-keys';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const useSidebarNavigation = () => {
  const sidebarItems: SidebarNavigationItem[] = [
    {
      title: i18n.t('dashboard'),
      key: 'dashboard',
      url: ROUTES.HOME,
      icon: <DashboardOutlined />,
    },

    {
      title: i18n.t('items'),
      key: PermissionsKeys.MASTER_DATA,
      url: ROUTES.ITEMS,
      icon: <AppstoreAddOutlined />,
    },
    {
      title: i18n.t('roles'),
      key: PermissionsKeys.ROLES,
      url: ROUTES.ROLES,
      icon: <TeamOutlined />,
    },
    {
      title: i18n.t('teams'),
      key: PermissionsKeys.TEAMS,
      url: ROUTES.TEAMS,
      icon: <TeamOutlined />,
    },
    {
      title: i18n.t('marketingGroups'),
      key: PermissionsKeys.MARKETING_GROUPS,
      url: ROUTES.MARKETING_GROUPS,
      icon: <TableOutlined />,
    },
    {
      title: i18n.t('distributors'),
      key: PermissionsKeys.DISTRIBUTORS,
      url: ROUTES.DISTRIBUTORS,
      icon: <UsersIcon />,
    },
    {
      title: i18n.t('salesTeam'),
      key: PermissionsKeys.SALES_TEAM,
      url: ROUTES.SALES_TEAM,
      icon: <ProfileOutlined />,
    },
    {
      title: i18n.t('salesInvoices'),
      key: PermissionsKeys.INVOICES,
      url: ROUTES.SALES_INVOICES,
      icon: <ReceiptIcon />,
    },
    {
      title: i18n.t('customers'),
      key: PermissionsKeys.CUSTOMERS,
      url: ROUTES.CUSTOMERS,
      icon: <TeamOutlined />,
    },
    {
      title: i18n.t('customerService'),
      key: PermissionsKeys.TICKETS,
      url: ROUTES.CUSTOMER_SERVICE,
      icon: <ShoppingCart />,
    },
    {
      title: i18n.t('orders'),
      key: PermissionsKeys.ORDERS,
      url: ROUTES.ORDERS,
      icon: <ShoppingCart />,
    },
    {
      title: i18n.t('businessModels'),
      key: PermissionsKeys.BUSINESS_RULES,
      url: ROUTES.BUSINESS_MODELS,
      icon: <ShoppingCart />,
    },
    {
      title: i18n.t('companies'),
      key: PermissionsKeys.COMPANIES,
      url: ROUTES.COMPANIES,
      icon: <CompassOutlined />,
    },
    {
      title: i18n.t('marketingMaterials'),
      key: PermissionsKeys.MARKETING_MATERIALS,
      url: ROUTES.MARKETING_MATERIALS,
      icon: <ContainerOutlined />,
    },
    {
      title: i18n.t('redemption'),
      key: PermissionsKeys.REDEMPTION,
      url: ROUTES.REDEMPTION,
      icon: <GiftIcon />,
    },
    {
      title: i18n.t('customerRedeemRequests'),
      key: PermissionsKeys.CUSTOMER_REDEEM_REQUESTS,
      url: ROUTES.CUSTOMER_REDEEM_REQUESTS,
      icon: <GiftIcon />,
    },
    {
      title: i18n.t('trainingMaterials'),
      key: PermissionsKeys.TRAINING_MATERIALS,

      url: ROUTES.TRAINING_MATERIALS,
      icon: <ContainerOutlined />,
    },
    {
      title: i18n.t('earnAndBurn'),
      key: PermissionsKeys.EARN_AND_BURN,
      url: ROUTES.EARN_AND_BURN,
      icon: <PartnersIcon />,
    },
    {
      title: i18n.t('partners'),
      key: PermissionsKeys.PARTNERS,
      url: ROUTES.PARTNERS,
      icon: <PartnersIcon />,
    },
    {
      title: i18n.t('partnerCategories'),
      key: PermissionsKeys.PARTNER_CATEGORIES,
      url: ROUTES.PARTNER_CATEGORIES,
      icon: <PartnersIcon />,
    },
    {
      title: i18n.t('tiers'),
      key: PermissionsKeys.TIERS,
      url: ROUTES.TIERS,
      icon: <DatabaseOutlined />,
    },
    {
      title: i18n.t('backlogs'),
      key: PermissionsKeys.BACKLOG,
      url: ROUTES.BACKLOGS,
      icon: <PicCenterOutlined />,
    },
    {
      title: i18n.t('pointFormula'),
      key: PermissionsKeys.POINTS_FORMULA,
      url: ROUTES.POINT_FORMULA,
      icon: <BorderOuterOutlined />,
    },
    {
      title: i18n.t('calculator'),
      key: PermissionsKeys.CALCULATOR,
      url: ROUTES.CALCULATOR,
      icon: <CalculatorOutlined />,
    },
    {
      title: i18n.t('settings'),
      key: PermissionsKeys.SETTINGS,
      url: ROUTES.SETTINGS,
      icon: <SettingOutlined />,
    },
    // {
    //   title: i18n.t('addTranslation'),
    //   key: 'addTranslation',
    //   url: ROUTES.ADD_TRANSLATION,
    //   icon: <GlobalOutlined />,
    // },
  ];
  return { sidebarItems };
};
