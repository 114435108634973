import { useGenericStore } from './generic-store';
import { LoadingKeys } from '../constants/loading-keys';
import { API_END_POINTS } from '../constants/api-end-points';
import { IAddOrEditTiers, ITiersItem } from '@/types';

export const useTierStore = useGenericStore<ITiersItem, IAddOrEditTiers>(
  {
    getAllEndpoint: API_END_POINTS.tiers,
    deleteEndpoint: API_END_POINTS.tiersCrud,
    getOneEndpoint: API_END_POINTS.tiersCrud,
    updateEndpoint: API_END_POINTS.tiersCrud,
  },
  {
    getAllLoadingKey: LoadingKeys.getAllTiers,
    deleteLoadingKey: LoadingKeys.deleteTier,
    getOneLoadingKey: LoadingKeys.getTierById,
    createOrUpdateLoadingKey: LoadingKeys.addOrEditTier,
  },
  true,
);
