import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRoles } from '@/hooks';
import { FormField } from '@/types';
import { RULES } from '../rules';

export const useSettingsFields = () => {
  const { t } = useTranslation();
  const { rolesOptions } = useRoles();
  const settingsFields = useMemo(
    (): FormField[] => [
      {
        beforeTitle: t('companyInformation'),
        beforeSubtitle: t('companyInformationDesc'),
        label: t('companyName'),
        name: 'name',
        type: 'input',
        rules: [RULES.required],
      },
      {
        label: t('logo'),
        name: 'logo',
        type: 'uploadImage',
        uploadImageProps: {
          maxImagesCount: 1,
          maxSizeInMB: 1,
          listType: 'picture-circle',
        },
        rules: [RULES.required],
      },
      {
        label: t('businessModel'),
        name: 'business_role',
        type: 'input',
        inputProps: {
          disabled: true,
        },
      },
      {
        label: t('mainColor'),
        name: 'main_color',
        type: 'color',
        rules: [RULES.required],
      },
      {
        label: t('secondaryColor'),
        name: 'secondary_color',
        type: 'color',
        rules: [RULES.required],
      },

      {
        beforeTitle: t('programDate'),
        beforeSubtitle: t('programSettingsDesc'),
        label: t('startDate'),
        name: 'start_date',
        type: 'date',
        placeholder: t('selectDate'),
        rules: [RULES.required],
        datePickerProps: {
          format: 'YYYY-MM-DD',
        },
        colProps: {
          lg: 8,
          span: 12,
        },
      },
      {
        label: t('renewalDate'),
        name: 'renewal_date',
        type: 'date',
        placeholder: t('selectDate'),
        rules: [RULES.required],
        datePickerProps: {
          format: 'YYYY-MM-DD',
        },
        colProps: {
          lg: 8,
          span: 12,
        },
      },

      {
        beforeTitle: t('points'),
        beforeSubtitle: t('pointsSettingsDesc'),
        label: t('joinPoints'),
        name: 'join_points',
        type: 'input',
        rules: [RULES.required, RULES.number, RULES.whiteSpace],
      },
      {
        label: t('creditPerPoint'),
        name: 'credit_per_point',
        type: 'input',
        rules: [RULES.required, RULES.whiteSpace],
      },
      {
        label: t('minimumPointsToRedeem'),
        name: 'min_points_to_redeem',
        type: 'input',
        rules: [RULES.required, RULES.number, RULES.whiteSpace],
      },
    ],
    [rolesOptions],
  );

  return { settingsFields };
};
