import cn from 'classnames';
import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { WithChildrenProps } from '@/types';

import styles from './paragraph.module.scss';

interface ParagraphProps extends DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> {
  size?: 'large' | 'default';
  otherProps?: string;
  gray?: boolean;
}

export const Paragraph = ({
  size = 'default',
  className = '',
  gray = false,
  children,
  ...otherProps
}: WithChildrenProps<ParagraphProps>) => (
  <p
    {...otherProps}
    className={`${className} ${cn({ [styles.default]: size === 'default', [styles.large]: size === 'large', [styles.gray]: gray })}`}
  >
    {children}
  </p>
);
