import { Card, Flex, Skeleton } from 'antd';
import styles from './customers.module.scss';

const CustomerCardSkeleton = () => (
  <Card bordered className={styles.card}>
    <Flex className={styles.cardHead} justify="space-between" align="center" gap={10}>
      <Flex gap={15} align="center">
        <Skeleton.Avatar active size={32} />
        <Skeleton.Button
          active
          style={{ height: '16px', width: '100px', borderRadius: '4px', display: 'flex', alignItems: 'center' }}
        />
        <Skeleton.Button active style={{ width: '22px', minWidth: '22px', height: '22px', overflow: 'hidden' }} />
      </Flex>
      <Skeleton.Button
        active
        style={{
          height: '16px',
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center',
          flex: 1,
        }}
      />
    </Flex>
    <div className={styles.customerDetailsWrapper}>
      <Skeleton paragraph={{ rows: 4, width: '100%' }} />
    </div>
    <Flex justify="flex-end" className={styles.footer}>
      <Flex gap={5}>
        <Skeleton.Button
          active
          style={{
            height: '32px',
            width: '32px',
            minWidth: '32px',
            borderRadius: '4px',
          }}
        />
        <Skeleton.Button
          active
          style={{
            height: '32px',
            width: '32px',
            minWidth: '32px',
            borderRadius: '4px',
          }}
        />
      </Flex>
    </Flex>
  </Card>
);

export default CustomerCardSkeleton;
