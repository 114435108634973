export const EXTRA_FORMULA_OPTIONS = [
  {
    name_en: 'Broadcast',
    name_ar: 'برودكاست',
    name: 'Broadcast',
    type: 'extra',
    company_id: 1,
    id: 1,
    deleted_at: null,
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    name_en: 'Questionnaire',
    name_ar: 'استبيان',
    name: 'Questionnaire',
    type: 'extra',
    id: 2,
    company_id: 1,
    deleted_at: null,
    created_at: new Date(),
    updated_at: new Date(),
  },
];

export const DISPLAY_TYPE_FORMULA_OPTIONS = [
  {
    name_en: '40*50cm',
    name_ar: '40*50سم',
    name: '40*50cm',
    type: 'display_type',
    company_id: 1,
    deleted_at: null,
    id: 3,
    created_at: new Date(),
    updated_at: new Date(),
  },
  {
    name_en: '20*30cm',
    name_ar: '20*30سم',
    name: '20*30cm',
    type: 'display_type',
    company_id: 1,
    deleted_at: null,
    id: 4,
    created_at: new Date(),
    updated_at: new Date(),
  },
];
