import { Outlet } from 'react-router-dom';
import { Col, Flex, Row, Typography } from 'antd';
import styles from './wizard-layout.module.scss';
import { UserDropdown } from '@/components/user-dropdown/user-dropdown.component';
import { HelpBtn } from '@/components/help-btn/help-btn.component';
import { Logo } from '@/components';
import { useUserStore } from '@/stores';

const { Title } = Typography;
export const WizardLayout = () => {
  const { companySettings } = useUserStore((state) => state);

  return (
    <div className={styles.wizardLayoutWrap}>
      <div className={styles.wizardLayoutHeader}>
        <Flex gap={10} align="center">
          <Logo />
          <Title level={5}>{companySettings?.company?.name}</Title>
        </Flex>

        <UserDropdown />
      </div>
      <div className={styles.childrenWrap}>
        <Row justify="center" className={styles.row}>
          <Col span={24} lg={12} className={styles.col}>
            <Outlet />
          </Col>
        </Row>
      </div>
      <footer className={styles.footer}>
        <HelpBtn />
      </footer>
    </div>
  );
};
