/* eslint-disable camelcase */
import { create } from 'zustand';
import { message } from 'antd';
import { handleApiCallInStore } from './utils';
import { API_END_POINTS, LoadingKeys, TOAST_MESSAGES } from '@/constants';
import { convertToFormData, customHttp, http } from '@/utils';
import {
  AddOrEditSubcategoryPayload,
  ApiResponseWithPagination,
  BasePaginationParams,
  ISubcategoryDetails,
  StringOrNumber,
  SubcategoryItem,
} from '@/types';

interface ISubcategoryStore {
  subcategories: SubcategoryItem[];
  totalSubcategoriesCount: number;
  subcategoryDetails: ISubcategoryDetails;
  getAllSubcategories: (payload: BasePaginationParams & { category_id?: number }) => void;
  deleteSubcategory: (id: number, onSuccess: () => void) => void;
  addOrEditSubcategory: (payload: AddOrEditSubcategoryPayload, onSuccess: () => void) => void;
  getSubCategoryById: (subcategoryId: StringOrNumber) => void;
}

export const useSubcategoryStore = create<ISubcategoryStore>((set) => ({
  subcategories: [],
  subcategoryDetails: undefined,
  totalSubcategoriesCount: 0,
  addOrEditSubcategory: (payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.addOrEditSubcategory, async () => {
      if (!payload.id) {
        await customHttp.post<FormData>(
          API_END_POINTS.subcategories,
          convertToFormData<AddOrEditSubcategoryPayload>(payload),
        );
      } else {
        await customHttp.put<FormData>(
          API_END_POINTS.updateSubcategory(payload.id),
          convertToFormData<AddOrEditSubcategoryPayload>(payload),
        );
      }
      onSuccess();
    });
  },
  getAllSubcategories: (payload) => {
    handleApiCallInStore(LoadingKeys.getAllSubcategories, async () => {
      const { data } = await http.get<undefined, ApiResponseWithPagination<SubcategoryItem>>(
        API_END_POINTS.subcategories,
        {
          params: payload,
        },
      );
      set({ subcategories: data.results, totalSubcategoriesCount: data.total });
    });
  },
  deleteSubcategory: (id, onSuccess) => {
    handleApiCallInStore(LoadingKeys.deleteSubcategory, async () => {
      await http.delete(API_END_POINTS.deleteSubcategory(id));
      onSuccess();
      message.success(TOAST_MESSAGES.DELETE_Subcategory_SUCCESS);
    });
  },
  getSubCategoryById: (id) => {
    handleApiCallInStore(LoadingKeys.getSubcategoryById, async () => {
      const { data } = await http.get<ISubcategoryDetails>(API_END_POINTS.getSubcategoryById(id));
      set({ subcategoryDetails: data });
    });
  },
}));
