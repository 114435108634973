import { Modal } from 'antd';
import { Paragraph } from '../paragraph/paragraph.component';
import styles from './confirm-modal.module.scss';

interface ConfirmModalProps {
  okText?: string;
  cancelText?: string;
  confirmMessage?: string;
  loading: boolean;
  onOk: () => void;
  onCancel: () => void;
  modalTitle?: string;
}

export const ConfirmModal = ({
  loading,
  onOk,
  modalTitle,
  onCancel,
  okText,
  cancelText,
  confirmMessage,
}: ConfirmModalProps) => (
  <Modal
    className={styles.confirmModal}
    centered
    title={modalTitle}
    open
    okButtonProps={{
      loading,
    }}
    onOk={onOk}
    onCancel={onCancel}
    okText={okText}
    cancelText={cancelText}
  >
    <Paragraph className={styles.confirmMessage}>{confirmMessage}</Paragraph>
  </Modal>
);
