import { create } from 'zustand';
import { handleApiCallInStore } from './utils';
import { LoadingKeys } from '@/constants/loading-keys';
import { API_END_POINTS } from '@/constants/api-end-points';
import { convertToFormData, customHttp, http } from '@/utils';
import {
  AddOrEditArticlePayload,
  AddOrEditTopicPayload,
  ApiResponseWithPagination,
  ArticleItem,
  BasePaginationParams,
  IArticle,
  StringOrNumber,
} from '@/types';

const apiEndPoints = {
  getAllEndpoint: API_END_POINTS.articles,
  createEndPoint: API_END_POINTS.addArticle,
  deleteEndpoint: API_END_POINTS.articlesCrud,
  getOneEndpoint: API_END_POINTS.articlesCrud,
  updateEndpoint: API_END_POINTS.articlesCrud,
};
const loadingKeys = {
  getAllLoadingKey: LoadingKeys.getAllArticles,
  deleteLoadingKey: LoadingKeys.deleteArticle,
  getOneLoadingKey: LoadingKeys.getArticleDetails,
  createOrUpdateLoadingKey: LoadingKeys.createOrUpdateArticle,
};
interface IArticleStore {
  items: ArticleItem[];
  total: number;
  details: IArticle;
  getAll: (payload: BasePaginationParams) => void;
  deleteOne: (id: number, topicId: StringOrNumber, onSuccess: () => void) => void;
  addOrEdit: (
    id: StringOrNumber,
    topicId: StringOrNumber,
    payload: AddOrEditArticlePayload,
    onSuccess: () => void,
  ) => void;
  getById: (id: StringOrNumber, topicId: StringOrNumber, onSuccess?: (data: IArticle) => void) => void;
}

export const useArticleStore = create<IArticleStore>((set) => ({
  items: [],
  total: 0,
  details: undefined,
  addOrEdit: (id, topicId, payload, onSuccess) => {
    handleApiCallInStore(loadingKeys.createOrUpdateLoadingKey, async () => {
      if (!id) {
        await customHttp.post<FormData>(
          apiEndPoints.createEndPoint(topicId),
          convertToFormData<AddOrEditTopicPayload>(payload),
        );
      } else {
        await customHttp.put<FormData>(
          apiEndPoints.updateEndpoint(topicId, id),
          convertToFormData<AddOrEditTopicPayload>(payload),
        );
      }
      onSuccess();
    });
  },
  getAll: (payload) => {
    handleApiCallInStore(loadingKeys.getAllLoadingKey, async () => {
      const { data } = await http.get<undefined, ApiResponseWithPagination<ArticleItem>>(apiEndPoints.getAllEndpoint, {
        params: payload,
      });
      set({ items: data.results, total: data.total });
    });
  },
  deleteOne: (id, topicId, onSuccess) => {
    handleApiCallInStore(loadingKeys.deleteLoadingKey, async () => {
      await http.delete(apiEndPoints.deleteEndpoint(id, topicId));
      onSuccess();
    });
  },
  getById: (id, topicId, onSuccess) => {
    handleApiCallInStore(loadingKeys.getOneLoadingKey, async () => {
      const { data } = await http.get<IArticle>(apiEndPoints.getOneEndpoint(topicId, id));
      set({ details: data });
      onSuccess?.(data);
    });
  },
}));
