import { SVGProps } from 'react';

export const PartnersIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 15.167H6C2.38 15.167.833 13.62.833 10V6C.833 2.38 2.38.833 6 .833h4c3.62 0 5.166 1.547 5.166 5.167v4c0 3.62-1.546 5.167-5.166 5.167ZM6 1.833C2.926 1.833 1.833 2.927 1.833 6v4c0 3.073 1.093 4.167 4.167 4.167h4c3.073 0 4.166-1.094 4.166-4.167V6c0-3.073-1.093-4.167-4.166-4.167H6Z"
      fill="#292D32"
    />
    <path
      d="M10.387 12.833a.504.504 0 0 1-.5-.5v-2.6c0-.273.226-.5.5-.5.273 0 .5.227.5.5v2.6c0 .274-.227.5-.5.5ZM10.387 5.466a.504.504 0 0 1-.5-.5v-1.3c0-.273.226-.5.5-.5.273 0 .5.227.5.5v1.3c0 .274-.227.5-.5.5Z"
      fill="#292D32"
    />
    <path
      d="M10.387 8.933a2.233 2.233 0 1 1 0-4.466 2.233 2.233 0 0 1 0 4.466Zm0-3.466c-.68 0-1.234.553-1.234 1.233s.554 1.233 1.234 1.233c.68 0 1.233-.553 1.233-1.233s-.56-1.233-1.233-1.233ZM5.613 12.833a.504.504 0 0 1-.5-.5v-1.3c0-.273.227-.5.5-.5.274 0 .5.227.5.5v1.3a.5.5 0 0 1-.5.5ZM5.613 6.766a.504.504 0 0 1-.5-.5v-2.6c0-.273.227-.5.5-.5.274 0 .5.227.5.5v2.6a.5.5 0 0 1-.5.5Z"
      fill="#292D32"
    />
    <path
      d="M5.613 11.534a2.233 2.233 0 1 1 0-4.467 2.233 2.233 0 0 1 0 4.467Zm0-3.467c-.68 0-1.233.553-1.233 1.233s.553 1.234 1.233 1.234S6.847 9.98 6.847 9.3a1.23 1.23 0 0 0-1.234-1.233Z"
      fill="#292D32"
    />
  </svg>
);
