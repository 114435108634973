import { SVGProps } from 'react';

export const UsersIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.106 7.747h-.053a.362.362 0 0 0-.12 0C4 7.687 2.54 6.167 2.54 4.294A3.465 3.465 0 0 1 6 .834a3.465 3.465 0 0 1 3.46 3.46 3.448 3.448 0 0 1-3.334 3.453h-.02ZM6 1.834a2.467 2.467 0 0 0-2.46 2.46 2.442 2.442 0 0 0 2.366 2.453 1.11 1.11 0 0 1 .214 0 2.456 2.456 0 0 0 2.34-2.453A2.467 2.467 0 0 0 6 1.834ZM11.027 7.833c-.02 0-.04 0-.06-.007-.274.027-.554-.167-.58-.44-.027-.273.14-.52.413-.553.08-.007.167-.007.24-.007a1.83 1.83 0 0 0 1.733-1.833c0-1.014-.82-1.834-1.833-1.834a.494.494 0 0 1-.5-.493c0-.273.227-.5.5-.5a2.84 2.84 0 0 1 2.833 2.833c0 1.534-1.2 2.774-2.726 2.834h-.02ZM6.113 15.033c-1.307 0-2.62-.334-3.613-1-.927-.614-1.434-1.454-1.434-2.367s.507-1.76 1.434-2.38c2-1.327 5.24-1.327 7.226 0 .92.613 1.434 1.453 1.434 2.367 0 .913-.507 1.76-1.434 2.38-1 .666-2.306 1-3.613 1Zm-3.06-4.907c-.64.427-.987.973-.987 1.547 0 .566.354 1.113.987 1.533 1.66 1.113 4.46 1.113 6.12 0 .64-.427.987-.973.987-1.547 0-.566-.354-1.113-.987-1.533-1.66-1.107-4.46-1.107-6.12 0ZM12.226 13.833a.492.492 0 0 1-.486-.4.507.507 0 0 1 .386-.593 2.7 2.7 0 0 0 1.107-.487c.38-.286.587-.646.587-1.026s-.207-.74-.58-1.02c-.294-.227-.66-.387-1.094-.487a.504.504 0 0 1-.38-.6.504.504 0 0 1 .6-.38 3.721 3.721 0 0 1 1.48.667c.62.466.974 1.126.974 1.82 0 .693-.36 1.353-.98 1.826a3.61 3.61 0 0 1-1.507.667c-.04.013-.073.013-.107.013Z"
      fill="#292D32"
    />
  </svg>
);
