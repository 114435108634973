import { create } from 'zustand';
import { message } from 'antd';
import { handleApiCallInStore } from './utils';
import { API_END_POINTS, LoadingKeys, TOAST_MESSAGES } from '@/constants';
import { convertToFormData, customHttp, http } from '@/utils';
import {
  AddOrEditSKUPayload,
  ApiResponseWithPagination,
  BasePaginationParams,
  ISKUDetails,
  SKUItem,
  StringOrNumber,
} from '@/types';

interface ISKUStore {
  SKUs: SKUItem[];
  totalSKUsCount: number;
  SKUDetails: ISKUDetails;
  getAllSKUs: (payload: BasePaginationParams & { brand_id?: number }) => void;
  deleteSKU: (id: number, onSuccess: () => void) => void;
  addOrEditSKU: (payload: AddOrEditSKUPayload, onSuccess: () => void) => void;
  getSKUById: (id: StringOrNumber) => void;
}

export const useSKUStore = create<ISKUStore>((set) => ({
  SKUs: [],
  SKUDetails: undefined,
  totalSKUsCount: 0,
  addOrEditSKU: (payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.addOrEditSKU, async () => {
      if (!payload.id) {
        await customHttp.post<FormData>(API_END_POINTS.SKUs, convertToFormData<AddOrEditSKUPayload>(payload));
      } else {
        await customHttp.put<FormData>(
          API_END_POINTS.updateSKU(payload.id),
          convertToFormData<AddOrEditSKUPayload>(payload),
        );
      }
      onSuccess();
    });
  },
  getAllSKUs: (payload) => {
    handleApiCallInStore(LoadingKeys.getAllSKUs, async () => {
      const { data } = await http.get<undefined, ApiResponseWithPagination<SKUItem>>(API_END_POINTS.SKUs, {
        params: payload,
      });
      set({ SKUs: data.results, totalSKUsCount: data.total });
    });
  },
  deleteSKU: (id, onSuccess) => {
    handleApiCallInStore(LoadingKeys.deleteSKU, async () => {
      await http.delete(API_END_POINTS.deleteSKU(id));
      onSuccess();
      message.success(TOAST_MESSAGES.DELETE_SKU_SUCCESS);
    });
  },
  getSKUById: (id) => {
    handleApiCallInStore(LoadingKeys.getSKUById, async () => {
      const { data } = await http.get<ISKUDetails>(API_END_POINTS.getSKUById(id));
      set({ SKUDetails: data });
    });
  },
}));
