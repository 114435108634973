import { WithChildrenProps } from '@/types';
import styles from './center-styles.module.scss';

interface CenterProps {
  className?: string;
}

export const Center = ({ className = '', children }: WithChildrenProps<CenterProps>) => (
  <div className={`${styles.center} ${className}`}>{children}</div>
);
