import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormField } from '@/types';
import { RULES } from '../rules';
import { StatusTag } from '@/components';
import { useGetInitialData } from '@/hooks';
import { API_END_POINTS } from '../api-end-points';
import { LoadingKeys } from '../loading-keys';
import { SALES_INVOICE_STATUS, TICKET_SALES_INVOICE_STATUS_MAPPER } from '../sales-constants';

const statusTypes = [
  {
    label: (
      <StatusTag
        type={TICKET_SALES_INVOICE_STATUS_MAPPER[SALES_INVOICE_STATUS.APPROVED].color}
        text={TICKET_SALES_INVOICE_STATUS_MAPPER[SALES_INVOICE_STATUS.APPROVED].label}
      />
    ),
    value: TICKET_SALES_INVOICE_STATUS_MAPPER[SALES_INVOICE_STATUS.APPROVED].value,
  },
  {
    label: (
      <StatusTag
        type={TICKET_SALES_INVOICE_STATUS_MAPPER[SALES_INVOICE_STATUS.REJECTED].color}
        text={TICKET_SALES_INVOICE_STATUS_MAPPER[SALES_INVOICE_STATUS.REJECTED].label}
      />
    ),
    value: TICKET_SALES_INVOICE_STATUS_MAPPER[SALES_INVOICE_STATUS.REJECTED].value,
  },
  {
    label: (
      <StatusTag
        type={TICKET_SALES_INVOICE_STATUS_MAPPER[SALES_INVOICE_STATUS.PENDING].color}
        text={TICKET_SALES_INVOICE_STATUS_MAPPER[SALES_INVOICE_STATUS.PENDING].label}
      />
    ),
    value: TICKET_SALES_INVOICE_STATUS_MAPPER[SALES_INVOICE_STATUS.PENDING].value,
  },
  {
    label: (
      <StatusTag
        type={TICKET_SALES_INVOICE_STATUS_MAPPER[SALES_INVOICE_STATUS.DRAFT].color}
        text={TICKET_SALES_INVOICE_STATUS_MAPPER[SALES_INVOICE_STATUS.DRAFT].label}
      />
    ),
    value: TICKET_SALES_INVOICE_STATUS_MAPPER[SALES_INVOICE_STATUS.DRAFT].value,
  },
];
export const useInvoiceFields = () => {
  const { t } = useTranslation();
  const customersData = useGetInitialData({
    apiEndPoint: API_END_POINTS.customers,
    loadingKey: LoadingKeys.getAllCustomersLite,
    fetchOnMount: true,
    selectParamValue: 'id,name,created_at',
  });

  const invoiceFields = useMemo(
    (): FormField[] => [
      {
        label: t('customer'),
        name: 'customer_id',
        type: 'infiniteSelect' as const,
        placeholder: t('selectCustomer'),
        infiniteSelectProps: {
          apiEndPoint: API_END_POINTS.customers,
          loadingKey: LoadingKeys.getAllCustomersLite,
          initialData: customersData?.initialData,
          initialHasMore: customersData?.hasMore,
          selectParamValue: 'id,name,created_at',
        },
      },

      {
        label: t('amount'),
        name: 'total_amount',
        type: 'input',
        rules: [RULES.number, RULES.required, RULES.whiteSpace],
      },
      {
        label: t('status'),
        name: 'status',
        type: 'select',
        placeholder: t('selectStatus'),
        rules: [RULES.required],
        options: statusTypes,
      },
      {
        label: t('dueDate'),
        name: 'due_date',
        type: 'date',
        rules: [RULES.required],
      },
    ],
    [customersData],
  );
  return { invoiceFields };
};
