import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Avatar, Dropdown, Typography } from 'antd';
import { useMemo } from 'react';
import i18n from '@/i18';
import { useAuthStore } from '@/stores';
import styles from './user-dropdown.module.scss';

const { Text } = Typography;

export const UserDropdown: React.FC = () => {
  const { logout, user } = useAuthStore((state) => state);
  const items: MenuProps['items'] = useMemo(
    () => [
      {
        label: (
          <div className={styles.logoutWrap}>
            <LogoutOutlined />
            <p>{i18n.t('logout')}</p>
          </div>
        ),
        key: '1',
        onClick: logout,
      },
    ],
    [],
  );
  return (
    <Dropdown trigger={['click']} menu={{ items }} placement="bottom">
      <div className={styles.dropdownInnerWrap}>
        <div className={styles.logoWithAvatarWrap}>
          <Avatar icon={<UserOutlined />} src={user?.logo ?? ''} className={styles.logo} />
          <Text className={styles.userName}>{user?.representative_full_name ?? user?.email}</Text>
        </div>
        <DownOutlined />
      </div>
    </Dropdown>
  );
};
