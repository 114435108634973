import { useEffect, useState } from 'react';
import { ApiResponseWithPagination, InfiniteScrollItem, InfiniteScrollSelectProps } from '@/types';
import { INITIAL_PAGE_SIZE } from '@/constants';
import { handleApiCallInStore } from '@/stores/utils';
import { http } from '@/utils';

export const useGetInitialData = ({
  apiEndPoint,
  loadingKey,
  selectParamValue = 'id,name_ar,name_en,created_at',
  limit = INITIAL_PAGE_SIZE,
  fetchOnMount = true,
}: InfiniteScrollSelectProps & { fetchOnMount?: boolean }) => {
  const [initialData, setInitialData] = useState<InfiniteScrollItem[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const fetchData = async (otherParams: Record<string, any> = {}) => {
    handleApiCallInStore(loadingKey, async () => {
      const { data } = await http.get<undefined, ApiResponseWithPagination<InfiniteScrollItem>>(apiEndPoint, {
        params: {
          page: 1,
          limit,
          fields: selectParamValue,
          ...otherParams,
        },
      });
      setHasMore(data.total_pages !== 1);
      setInitialData(data.results);
    });
  };
  useEffect(() => {
    if (fetchOnMount) {
      fetchData();
    }
  }, []);

  return { initialData, hasMore, fetchData };
};
