import { SVGProps } from 'react';

export const FlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={12} height={12} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.225 1v10V1Z" fill="#FF4D4F" />
    <path
      d="M3.225 1v10M3.475 2l4.05 1.75c1.65.7 1.65 1.9.1 2.7L3.475 8.5"
      stroke="#FF4D4F"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
