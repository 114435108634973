import { Flex, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from '../icons';
import { Paragraph } from '../paragraph/paragraph.component';
import styles from './delete-modal.module.scss';

interface DeleteModalProps {
  okText?: string;
  cancelText?: string;
  deleteMessage?: string;
  loading: boolean;
  onOk: () => void;
  onCancel: () => void;
  modalTitle?: string;
}

export const DeleteModal = ({
  loading,
  onOk,
  modalTitle,
  onCancel,
  okText,
  cancelText,
  deleteMessage,
}: DeleteModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      className={styles.deleteModal}
      centered
      title={
        <Flex align="center" gap="8px">
          <DeleteIcon />
          {modalTitle ?? t('delete')}
        </Flex>
      }
      open
      okButtonProps={{
        loading,
      }}
      onOk={onOk}
      onCancel={onCancel}
      okText={okText ?? t('yesDelete')}
      cancelText={cancelText ?? t('noKeepIt')}
    >
      <Paragraph className={styles.deleteMessgae}>{deleteMessage ?? t('deleteMessage')}</Paragraph>
    </Modal>
  );
};
