import { Image, ImageProps } from 'antd';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { fallbackSrc } from '@/constants';
import styles from './enhanced-image.module.scss';

export const EnhancedImage = ({
  src,
  className,
  full = false,
  fluid = false,
  contain = false,
  ...otherProps
}: ImageProps & { full?: boolean; fluid?: boolean; contain?: boolean }) => {
  const [srcValue, setSrcValue] = useState(src);
  useEffect(() => {
    setSrcValue(src);
  }, [src]);

  return (
    <Image
      onError={() => setSrcValue(fallbackSrc)}
      src={srcValue}
      fallback={fallbackSrc}
      {...otherProps}
      wrapperClassName={cn(className, {
        [styles.fullImage]: full,
        [styles.fluidImage]: fluid,
        [styles.containImage]: contain,
      })}
    />
  );
};
