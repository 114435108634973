import { SVGProps } from 'react';

export const XIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <mask id="a" fill="#fff">
      <path d="M0 4a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4Z" />
    </mask>
    <path fill="#fff" d="M0 4a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4Z" />
    <path
      fill="#000"
      d="M4 1h8v-2H4v2Zm11 3v8h2V4h-2Zm-3 11H4v2h8v-2ZM1 12V4h-2v8h2Zm3 3a3 3 0 0 1-3-3h-2a5 5 0 0 0 5 5v-2Zm11-3a3 3 0 0 1-3 3v2a5 5 0 0 0 5-5h-2ZM12 1a3 3 0 0 1 3 3h2a5 5 0 0 0-5-5v2ZM4-1a5 5 0 0 0-5 5h2a3 3 0 0 1 3-3v-2Z"
      mask="url(#a)"
    />
    <g clipPath="url(#b)">
      <path
        fill="#000"
        fillRule="evenodd"
        d="m13.14 1.826.002.001 1.03 1.03v.005L9.034 7.999l5.138 5.138v.004l-1.03 1.03-.002.001h-.003L8 9.033 2.862 14.17l-.001.001h-.003l-1.03-1.031-.001-.001v-.003l5.138-5.138-5.137-5.137-.001-.002v-.002l1.031-1.03.001-.002h.002l.001.001L8 6.965l5.137-5.138h.003Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="b">
        <path fill="#fff" d="M4 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z" />
      </clipPath>
    </defs>
  </svg>
);

export default XIcon;
