import * as React from 'react';
import { SVGProps } from 'react';

export const ViewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={17} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.222 7.597c-1.482-3.12-3.72-4.69-6.722-4.69-3.004 0-5.241 1.57-6.722 4.691a.942.942 0 0 0 0 .805c1.481 3.12 3.72 4.69 6.722 4.69 3.003 0 5.24-1.57 6.722-4.691a.942.942 0 0 0 0-.805ZM8.5 11.969c-2.52 0-4.366-1.278-5.668-3.969C4.134 5.31 5.98 4.031 8.5 4.031c2.52 0 4.365 1.278 5.667 3.969-1.3 2.69-3.146 3.969-5.667 3.969ZM8.437 5.25a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5Zm0 4.5a1.75 1.75 0 1 1 0-3.5 1.75 1.75 0 1 1 0 3.5Z"
      fill="#000"
      fillOpacity={0.88}
    />
  </svg>
);
