import { FC } from 'react';
import { DesktopHeader } from './layouts/desktop-header.component';
import { MobileHeader } from './layouts/mobile-header.component';
import { useResponsive } from '@/hooks';

export const Header: FC = () => {
  const { isTablet } = useResponsive();

  return isTablet ? <DesktopHeader /> : <MobileHeader />;
};
