/* eslint-disable camelcase */
import { create } from 'zustand';
import { message } from 'antd';
import {
  AddOrEditMarketingGroup,
  ApiResponse,
  ApiResponseWithPagination,
  AssignToMarketingGroupPayload,
  BasePaginationParams,
  MarketingGroupDetails,
  MarketingGroupItem,
  ProductItem,
  StringOrNumber,
} from '@/types';
import { handleApiCallInStore } from './utils';
import { API_END_POINTS, LoadingKeys, TOAST_MESSAGES } from '@/constants';
import { convertToFormData, customHttp, http, isFile } from '@/utils';

interface IMarketingGroupStore {
  marketingGroups: MarketingGroupItem[];
  marketingGroupDetails: MarketingGroupDetails;
  assignedItemsToMarketingGroups: ProductItem[];
  totalMarketingGroupsCount: number;
  totalAssignedItemsToMarketingGroup: number;
  getAllMarketingGroups: (payload: BasePaginationParams) => void;
  addOrEditMarketingGroup: (payload: AddOrEditMarketingGroup, onSuccess: () => void) => void;
  deleteMarketingGroup: (id: number, onSuccess: () => void) => void;
  assignItemToMarketingGroup: (
    groupId: StringOrNumber,
    payload: AssignToMarketingGroupPayload,
    onSuccess: () => void,
  ) => void;
  getAssignedItemsToMarketingGroup: (payload: BasePaginationParams, groupId: number) => void;
  unassignItemFromMarketingGroup: (
    payload: AssignToMarketingGroupPayload,
    groupId: StringOrNumber,
    onSuccess: () => void,
  ) => void;
  getMarketingGroupDetails: (groupId: StringOrNumber) => void;
}

export const useMarketingGroupsStore = create<IMarketingGroupStore>((set) => ({
  marketingGroupDetails: undefined,
  marketingGroups: [],
  assignedItemsToMarketingGroups: [],
  totalMarketingGroupsCount: 0,
  totalAssignedItemsToMarketingGroup: 0,
  unassignItemFromMarketingGroup: (payload, groupId, onSuccess) => {
    handleApiCallInStore(LoadingKeys.unAssignItemFromMarketingGroup, async () => {
      await http.delete<AssignToMarketingGroupPayload>(API_END_POINTS.unassignItemFromMarketingGroup(groupId), {
        data: payload,
      });
      onSuccess();
      message.success(TOAST_MESSAGES.UNASSIGN_ITEM_TO_MARKETING_GROUP);
    });
  },
  getAssignedItemsToMarketingGroup: (payload, groupId) => {
    handleApiCallInStore(LoadingKeys.getAssignedItemsToMarketingGroup, async () => {
      const { data } = await http.get<undefined, ApiResponseWithPagination<ProductItem>>(
        API_END_POINTS.getAssignedItemsToMarketingGroup(groupId),
        {
          params: payload,
        },
      );
      set({ totalAssignedItemsToMarketingGroup: data?.total, assignedItemsToMarketingGroups: data?.results });
    });
  },
  assignItemToMarketingGroup: (groupId, payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.assignItemToMarketingGroup, async () => {
      await http.post<AssignToMarketingGroupPayload>(API_END_POINTS.assignItemToMarketingGroup(groupId), payload);
      onSuccess();
      message.success(TOAST_MESSAGES.ASSIGN_ITEM_TO_MARKETING_GROUP);
    });
  },
  deleteMarketingGroup: (id, onSuccess) => {
    handleApiCallInStore(LoadingKeys.deleteMarketingGroup, async () => {
      await http.delete(API_END_POINTS.deleteMarketingGroup(id));
      onSuccess();
      message.success(TOAST_MESSAGES.DELETE_MARKETING_GROUP);
    });
  },
  addOrEditMarketingGroup: (payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.addOrEditMarketingGroup, async () => {
      if (!payload.id) {
        await customHttp.post<FormData>(
          API_END_POINTS.marketingGroups,
          convertToFormData<AddOrEditMarketingGroup>(payload),
        );
      } else {
        const { main_photo, ...otherItems } = payload;
        await customHttp.put<FormData>(
          API_END_POINTS.updateMarketingGroup(payload.id),
          convertToFormData<AddOrEditMarketingGroup>(isFile(main_photo) ? payload : otherItems),
        );
      }
      onSuccess();
    });
  },
  getMarketingGroupDetails: (id: StringOrNumber) => {
    handleApiCallInStore(LoadingKeys.getMarketingGroupDetails, async () => {
      const { data } = await http.get<undefined, ApiResponse<MarketingGroupDetails>>(
        API_END_POINTS.marketingGroupDetails(id),
      );
      set({ marketingGroupDetails: data });
    });
  },
  getAllMarketingGroups: (payload) => {
    handleApiCallInStore(LoadingKeys.getAllMarketingGroups, async () => {
      const { data } = await http.get<undefined, ApiResponseWithPagination<MarketingGroupItem>>(
        API_END_POINTS.marketingGroups,
        {
          params: payload,
        },
      );
      set({ marketingGroups: data.results, totalMarketingGroupsCount: data.total });
    });
  },
}));
