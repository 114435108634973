import { SalesTeamItem } from '@/types';
import { useGenericStore } from './generic-store';
import { LoadingKeys } from '../constants/loading-keys';
import { API_END_POINTS } from '../constants/api-end-points';
import { AddOrEditSalesTeam } from '@/types/sales-team';

export const useSalesTeamStore = useGenericStore<SalesTeamItem, AddOrEditSalesTeam>(
  {
    getAllEndpoint: API_END_POINTS.salesTeam,
    deleteEndpoint: API_END_POINTS.salesTeamMemberCrud,
    updateEndpoint: API_END_POINTS.salesTeamMemberCrud,
  },
  {
    getAllLoadingKey: LoadingKeys.getAllSalesTeamMembers,
    deleteLoadingKey: LoadingKeys.deleteSalesTeamMember,
    createOrUpdateLoadingKey: LoadingKeys.addOrEditSalesTeamMember,
  },
  false,
);
