/* eslint-disable default-param-last */
import { create } from 'zustand';
import { ApiResponse, ImportDataPayload, ImportEntityItem, ImportEntityType } from '@/types';
import { handleApiCallInStore } from './utils';
import { API_END_POINTS, LoadingKeys } from '@/constants';
import { convertToFormData, customHttp, http } from '@/utils';

interface ICommonStoreState {
  entities: Record<ImportEntityType, ImportEntityItem>;
  uploadFile: (file: File, onSuccess: (link: string) => void) => void;
  importData: (payload: ImportDataPayload, onSuccess: () => void) => void;
  getEntities: () => void;
}

export const useCommonStore = create<ICommonStoreState>((set) => ({
  entities: {} as Record<ImportEntityType, ImportEntityItem>,
  getEntities: () => {
    handleApiCallInStore(LoadingKeys.getEntities, async () => {
      const { data } = await http.get<FormData, ApiResponse<Record<string, ImportEntityItem>>>(API_END_POINTS.entities);
      set({ entities: data });
    });
  },
  uploadFile: (file, onSuccess) => {
    handleApiCallInStore(LoadingKeys.uploadFile, async () => {
      const { data } = await customHttp.post<FormData, ApiResponse<{ url: string }>>(
        API_END_POINTS.uploadFile,
        convertToFormData<{ file: File }>({ file }),
      );

      onSuccess(data?.url);
      return data;
    });
  },
  importData: (payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.importData, async () => {
      const { data } = await customHttp.post<FormData>(
        API_END_POINTS.importFile,
        convertToFormData<ImportDataPayload>(payload),
      );
      onSuccess();
    });
  },
}));
