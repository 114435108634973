import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Filler,
  Tooltip,
  Legend,
  Title,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Filler, Tooltip, Legend, Title);

interface AreaChartProps {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    fill: boolean;
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    pointRadius: number;
  }[];
  xLabel?: string;
  yLabel?: string;
  height?: number;
}
export const AreaChart = ({ labels, datasets, xLabel, yLabel, height = 200 }: AreaChartProps) => {
  const data = {
    labels,
    datasets,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Remove the legend
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: xLabel, // Set your X-axis label here
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: yLabel, // Set your Y-axis label here
        },
      },
    },
  };

  return (
    <div>
      <Line height={height} data={data} options={options} />
    </div>
  );
};
