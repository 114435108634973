export enum WizardSteps {
  STEP_0,
  STEP_1,
  STEP_1_1,
  STEP_1_2,
  STEP_2,
  STEP_2_1,
  STEP_3,
  STEP_4,
  STEP_4_1,
  STEP_4_2,
  STEP_5,
  COMPLETE,
  HOME,
}

export enum WizardPhases {
  PHASE_1 = 1,
  PHASE_2 = 2,
  PHASE_3 = 3,
  PHASE_4 = 4,
  PHASE_5 = 5,
}
