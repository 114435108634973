import { Flex, Form, FormInstance, Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormProps } from 'antd/lib';
import { ReactNode } from 'react';
import { DynamicForm } from '../dynamic-form/dynamic-form.component';
import { FormField, RecursivePartial } from '@/types';
import styles from './dynamic-modal.module.scss';

interface DynamicModalProps<T> {
  title: string;
  isLoading: boolean;
  handleClose: () => void;
  fields: FormField[];
  handleSubmit: (values: T, form: FormInstance<T>) => void;
  okText?: string;
  cancelText?: string;
  children?: ReactNode | ReactNode[];
  data?: RecursivePartial<T>;
  initialValues?: RecursivePartial<T>;
}
const { Title } = Typography;

export function DynamicModal<T>({
  title,
  handleClose,
  okText,
  cancelText,
  isLoading,
  fields,
  handleSubmit,
  children,
  data,
}: DynamicModalProps<T>) {
  const { t } = useTranslation();
  const [form] = Form.useForm<T>();
  const { useWatch } = Form;

  const onFinish: FormProps<T>['onFinish'] = (values) => {
    handleSubmit(values, form);
  };
  return (
    <Modal
      className={styles.dynamicModal}
      centered
      title={
        <Flex align="center" gap="8px">
          <Title level={3}>{title}</Title>
        </Flex>
      }
      open
      onOk={() => form.submit()}
      onCancel={handleClose}
      okText={okText ?? (data ? t('save') : t('submit'))}
      okButtonProps={{ loading: isLoading }}
      cancelText={cancelText ?? t('cancel')}
    >
      <div className={styles.formWrapper}>
        <Form
          initialValues={data}
          scrollToFirstError
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <DynamicForm useWatch={useWatch} initialValues={data} form={form} formFields={fields}>
            {children}
          </DynamicForm>
        </Form>
      </div>
    </Modal>
  );
}
