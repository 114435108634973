/* eslint-disable camelcase */
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { FormField } from '@/types';
import { useGetInitialData } from '@/hooks';
import { API_END_POINTS } from '../api-end-points';
import { LoadingKeys } from '../loading-keys';

export const useAdminFields = () => {
  const { t } = useTranslation();

  const companiesData = useGetInitialData({
    apiEndPoint: API_END_POINTS.companies,
    loadingKey: LoadingKeys.getAllCompaniesLite,
    fetchOnMount: true,
    selectParamValue: 'id,name,created_at,logo',
  });

  const companiesField = useMemo(
    (): FormField => ({
      name: 'company_id',
      type: 'infiniteSelect' as const,
      placeholder: t('selectCompany'),
      infiniteSelectProps: {
        apiEndPoint: API_END_POINTS.companies,
        loadingKey: LoadingKeys.getAllCompaniesLite,
        initialData: companiesData?.initialData,
        initialHasMore: companiesData?.hasMore,
        size: 'middle',
        imgSrcName: 'logo',
        selectParamValue: 'id,name,created_at,logo',
      },
    }),
    [companiesData],
  );

  return {
    companiesField,
  };
};
