import { SVGProps } from 'react';

export const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m8.75 14 3.5 3.5 7-7m6.417 3.5c0 6.444-5.224 11.667-11.667 11.667S2.333 20.444 2.333 14.001C2.333 7.557 7.557 2.334 14 2.334s11.667 5.223 11.667 11.667Z"
    />
  </svg>
);
