import Papa, { ParseResult } from 'papaparse';
import * as XLSX from 'xlsx';

type HeaderRow = string[];

export const detectHeaderRowCSV = (csvData: string): HeaderRow | undefined => {
  const parsed: ParseResult<string[]> = Papa.parse(csvData, { skipEmptyLines: true });
  const rows: string[][] = parsed.data;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];

    // eslint-disable-next-line no-restricted-globals
    const nonNumericCount = row.filter((cell) => isNaN(Number(cell))).length;

    if (nonNumericCount > row.length / 2) {
      return row;
    }
  }

  return rows[0];
};

export const detectHeaderRowExcel = (fileData: ArrayBuffer): HeaderRow => {
  if (fileData) {
    const data = new Uint8Array(fileData as ArrayBuffer);
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const jsonData: HeaderRow[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < jsonData.length; i++) {
      const row = jsonData[i];

      // Check if most cells in the row are strings (likely headers)
      const nonNumericCount = row.filter((cell) => typeof cell === 'string').length;
      if (nonNumericCount > row.length / 2) {
        return row; // This row is likely the header row
      }
    }

    return jsonData[0]; // Default to the first row if no header row is detected
  }
};
