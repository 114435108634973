// http.ts

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { ROUTES, tokenAttr, userAttr, companyIdAttr } from '@/constants';
import { localStorageHelper } from './local-storage-helper';

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

class Http {
  private instance: AxiosInstance | null = null;

  private isCustom: boolean = false;

  constructor(isCustom: boolean = false) {
    this.isCustom = isCustom;
  }

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
    });

    // http.interceptors.request.use(injectToken, (error) => Promise.reject(error));
    http.interceptors.request.use(
      (config) => {
        // config.timeout = 5000;
        const token = Cookies.get(tokenAttr);
        const company_id = localStorageHelper.get(companyIdAttr);
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        config.headers = {
          ...config.headers,
          'Content-Type': this.isCustom ? 'multipart/form-data' : 'application/json',
          'Accept-Language': Cookies.get('i18next') || 'en',

          ...(token && { Authorization: `Bearer ${token}` }),
          ...(company_id && { company_id: company_id?.value }),
        } as any;
        return config;
      },
      (error) => Promise.reject(error),
    );

    http.interceptors.response.use(
      (response) => response?.data,
      (error) => {
        const { response } = error;
        if (response && response instanceof Object) {
          return this.handleError(response);
        }
        return Promise.reject(error);
      },
    );

    this.instance = http;
    return http;
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  private handleError(error) {
    const { status } = error;

    switch (status) {
      case StatusCode.InternalServerError: {
        return Promise.reject(error);
      }
      case StatusCode.Forbidden: {
        return Promise.reject(error);
      }
      case StatusCode.Unauthorized: {
        if (!window.location.pathname.includes('login')) {
          Cookies.remove(tokenAttr);
          Cookies.remove(userAttr);
          localStorageHelper.clearItem(companyIdAttr);
          window.location.href = ROUTES.LOGIN;
        }
        break;
      }
      case StatusCode.TooManyRequests: {
        return Promise.reject(error);
      }
      default: {
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
}

export const http = new Http();
export const customHttp = new Http(true);
