import { Button, Flex, Tooltip } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteIcon, EditIcon, PlusIcon, ViewIcon } from '../icons';
import { PermissionsKeys } from '@/constants/permission-keys';
import { usePermission } from '@/hooks';
import { PermissionType } from '@/types';

interface TableActionsProps {
  handleAddClick?: () => void;
  handleDeleteClick?: () => void;
  handleEditClick?: () => void;
  handleViewClick?: () => void;
  deleteBtnTitle?: string;
  deleteIcon?: ReactNode;
  viewIcon?: ReactNode;
  viewTitle?: string;
  children?: ReactNode | ReactNode[];
  permissionKey?: PermissionsKeys;
  viewPermissionType?: PermissionType;
}

export const TableActions = ({
  handleAddClick,
  handleDeleteClick,
  handleEditClick,
  handleViewClick,
  children,
  deleteBtnTitle,
  deleteIcon = <DeleteIcon />,
  permissionKey,
  viewPermissionType,
  viewIcon = <ViewIcon />,
  viewTitle,
}: TableActionsProps) => {
  const { t } = useTranslation();
  const { showComponent } = usePermission();

  const permissionCondition = (permissionType: PermissionType, handleFunction: () => void) =>
    permissionType && permissionKey ? handleFunction && showComponent(permissionKey, permissionType) : handleFunction;

  return (
    <Flex align="center" gap={16}>
      {permissionCondition(viewPermissionType, handleViewClick) && (
        <Tooltip placement="top" title={viewTitle ?? t('viewDetails')}>
          <Button onClick={handleViewClick} size="middle" icon={viewIcon} />
        </Tooltip>
      )}
      {permissionCondition(PermissionType.EDIT, handleEditClick) && (
        <Tooltip placement="top" title={t('edit')}>
          <Button onClick={handleEditClick} size="middle" icon={<EditIcon />} />
        </Tooltip>
      )}
      {permissionCondition(PermissionType.DELETE, handleDeleteClick) && (
        <Tooltip placement="top" title={deleteBtnTitle ?? t('delete')}>
          <Button onClick={handleDeleteClick} size="middle" icon={deleteIcon} />
        </Tooltip>
      )}
      {permissionCondition(PermissionType.Assign, handleAddClick) && (
        <Tooltip placement="top" title={t('add')}>
          <Button onClick={handleAddClick} size="middle" icon={<PlusIcon />} />
        </Tooltip>
      )}
      {children}
    </Flex>
  );
};
