import { SVGProps } from 'react';

export const ShoppingCart = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.127 11.833h-7.1c-.66 0-1.293-.28-1.74-.767a2.382 2.382 0 0 1-.62-1.8l.554-6.64a.725.725 0 0 0-.194-.56.721.721 0 0 0-.54-.233H1.334a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h1.16c.487 0 .947.207 1.273.56.18.2.314.433.387.693h8.327c.673 0 1.293.267 1.746.747.447.487.674 1.12.62 1.793l-.36 5c-.073 1.22-1.14 2.207-2.36 2.207ZM4.187 3.08l-.52 6.266c-.033.387.094.754.354 1.04.26.287.62.44 1.006.44h7.1c.694 0 1.32-.586 1.374-1.28l.36-5a1.36 1.36 0 0 0-1.374-1.473h-8.3v.007ZM10.833 15.167c-.733 0-1.333-.6-1.333-1.334 0-.733.6-1.333 1.333-1.333.734 0 1.334.6 1.334 1.333 0 .734-.6 1.334-1.334 1.334Zm0-1.667a.33.33 0 0 0-.333.333.33.33 0 0 0 .333.334.33.33 0 0 0 .334-.334.33.33 0 0 0-.334-.333ZM5.5 15.167c-.734 0-1.334-.6-1.334-1.334 0-.733.6-1.333 1.333-1.333.734 0 1.334.6 1.334 1.333 0 .734-.6 1.334-1.334 1.334Zm0-1.667a.33.33 0 0 0-.334.333.33.33 0 0 0 .333.334.33.33 0 0 0 .334-.334.33.33 0 0 0-.334-.333Z"
      fill="#292D32"
    />
    <path d="M14 5.833H6a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h8c.273 0 .5.227.5.5s-.227.5-.5.5Z" fill="#292D32" />
  </svg>
);
