import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { TagProps } from 'antd/lib';
import styles from './status-tag.module.scss';

interface StatusTagProps {
  type: TagProps['color'];
  text: string;
}

const typesIconMapper = {
  success: <CheckCircleOutlined />,
  error: <CloseCircleOutlined />,
  warning: <ExclamationCircleOutlined />,
};
export const StatusTag = ({ type, text }: StatusTagProps) => (
  <Tag className={styles.ticketTag} bordered icon={typesIconMapper[type]} color={type}>
    {text}
  </Tag>
);
