import { create } from 'zustand';
import { message } from 'antd';
import {
  AddOrEditCategoryPayload,
  ApiResponseWithPagination,
  BasePaginationParams,
  CategoryItem,
  ICategoryDetails,
  StringOrNumber,
} from '@/types';
import { handleApiCallInStore } from './utils';
import { API_END_POINTS, LoadingKeys, TOAST_MESSAGES } from '@/constants';
import { convertToFormData, http } from '@/utils';

interface ICategoryStore {
  categories: CategoryItem[];
  categoryDetails: ICategoryDetails;
  totalCategoriesCount: number;
  getAllCategories: (payload: BasePaginationParams) => void;
  deleteCategory: (id: number, onSuccess: () => void) => void;
  addOrEditCategory: (payload: AddOrEditCategoryPayload, onSuccess: () => void) => void;
  getCategoryById: (categoryId: StringOrNumber) => void;
}

export const useCategoryStore = create<ICategoryStore>((set) => ({
  categories: [],
  categoryDetails: undefined,
  totalCategoriesCount: 0,
  addOrEditCategory: (payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.addOrEditCategory, async () => {
      if (!payload.id) {
        await http.post<FormData>(API_END_POINTS.categories, convertToFormData<AddOrEditCategoryPayload>(payload));
      } else {
        await http.put<FormData>(
          API_END_POINTS.updateCategory(payload.id),
          convertToFormData<AddOrEditCategoryPayload>(payload),
        );
      }
      onSuccess();
    });
  },
  getAllCategories: (payload) => {
    handleApiCallInStore(LoadingKeys.getAllCategories, async () => {
      const { data } = await http.get<undefined, ApiResponseWithPagination<CategoryItem>>(API_END_POINTS.categories, {
        params: payload,
      });
      set({ categories: data.results, totalCategoriesCount: data.total });
    });
  },
  deleteCategory: (id, onSuccess) => {
    handleApiCallInStore(LoadingKeys.deleteCategory, async () => {
      await http.delete(API_END_POINTS.deleteCategory(id));
      onSuccess();
      message.success(TOAST_MESSAGES.DELETE_CATEGORY_SUCCESS);
    });
  },
  getCategoryById: (id) => {
    handleApiCallInStore(LoadingKeys.getCategoryById, async () => {
      const { data } = await http.get<ICategoryDetails>(API_END_POINTS.getCategoryById(id));
      set({ categoryDetails: data });
    });
  },
}));
