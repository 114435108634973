import { useEffect, FC } from 'react';
import { t } from 'i18next';
import NProgress from 'nprogress';
import styles from './loader.module.scss';

export const Loader: FC = () => {
  useEffect(() => {
    NProgress.start();
    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loaderInnerWrap}>
        <div className={styles.loader} />
        <p className={styles.loadingText}>{t('isLoading')}</p>
      </div>
    </div>
  );
};
