/* eslint-disable eqeqeq */
import { create } from 'zustand';
import Cookies from 'js-cookie';
import { message } from 'antd';
import {
  ApiResponse,
  ChangePasswordPayload,
  ForgetPasswordPayload,
  ForgetPasswordRes,
  IAuthRes,
  ILoginPayload,
  IProfilePayload,
  VerifyPasswordPayload,
} from '@/types';
import { handleApiCallInStore } from './utils';
import {
  emailSearchParamAttr,
  LoadingKeys,
  passwordSessionAttr,
  ROUTES,
  stepsMapper,
  TOAST_MESSAGES,
  tokenAttr,
  userAttr,
  WizardSteps,
  API_END_POINTS,
} from '@/constants';
import { customHttp, http, localStorageHelper } from '@/utils';
import { useAppStore } from './app-store';
import { IUpdateProfilePayload } from '@/types/profile';

interface IAuthState {
  selectedCompany: { value: number; label: string };
  token: string | null;
  user: IProfilePayload | null;
  login: (payload: ILoginPayload, remember: boolean) => void;
  forgetPassword: (payload: ForgetPasswordPayload, onSuccess?: (data: ForgetPasswordRes) => void) => void;
  verifyOtp: (payload: VerifyPasswordPayload) => void;
  changePassword: (payload: ChangePasswordPayload) => void;
  logout: () => void;
  updateUser: (data: Partial<IProfilePayload>) => void;
  deleteAccount: (onSuccess: () => void) => void;
  getProfileData: (onSuccess?: () => void) => void;
  updateProfile: (payload: IUpdateProfilePayload, onSuccess?: () => void) => void;
  setSelectedCompany: (data: { value: number; label: string }) => void;
  resetSelectedCompany: () => void;
}
const companyIdAttr = 'companyIdAttr';

export const useAuthStore = create<IAuthState>((set, get) => ({
  selectedCompany: localStorageHelper.get(companyIdAttr),
  token: Cookies.get('token') ?? null,
  user: localStorageHelper.get('user') ?? null,
  setSelectedCompany: (data) => {
    localStorageHelper.set(companyIdAttr, data);
    set({
      selectedCompany: data,
    });
  },
  resetSelectedCompany: () => {
    localStorageHelper.clearItem(companyIdAttr);
    set({
      selectedCompany: undefined,
    });
  },
  deleteAccount: (onSuccess) => {
    handleApiCallInStore(LoadingKeys.deleteAccount, async () => {
      await http.delete(API_END_POINTS.profile);
      onSuccess();
    });
  },
  getProfileData: (onSuccess) => {
    handleApiCallInStore(LoadingKeys.getProfileData, async () => {
      const { data } = await http.get<undefined, ApiResponse<IProfilePayload>>(API_END_POINTS.profile);
      get().updateUser(data);
      onSuccess?.();
    });
  },
  updateProfile: (payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.updateProfile, async () => {
      const { data } = await customHttp.put<IUpdateProfilePayload, any>(API_END_POINTS.profile, payload);
      get().updateUser(data);
      onSuccess?.();
    });
  },
  updateUser: (data) => {
    if (data && typeof data === 'object') {
      const updatedUser = {
        ...useAuthStore.getState().user,
        ...data,
      };
      set({ user: updatedUser });
      localStorageHelper.set(userAttr, updatedUser);
    }
  },
  login: (payload) =>
    handleApiCallInStore(LoadingKeys.login, async () => {
      const { data } = await http.post<ILoginPayload, ApiResponse<IAuthRes>>(API_END_POINTS.login, payload);

      Cookies.set(tokenAttr, data?.access_token, { expires: payload?.remember ? 30 : 10 });
      set({
        user: data?.user,
        token: data?.access_token,
      });

      if (data?.user?.login_step && data?.user?.login_step != WizardSteps.HOME) {
        useAppStore.getState().navigate(stepsMapper[data?.user?.login_step] ?? ROUTES.WELCOME);
      } else {
        useAppStore.getState().navigate(ROUTES.HOME);
      }

      localStorageHelper.set(userAttr, data?.user);
    }),
  forgetPassword: (payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.forgetPassword, async () => {
      const { data } = await http.post<ForgetPasswordPayload, ApiResponse<ForgetPasswordRes>>(
        API_END_POINTS.forgetPassword,
        payload,
      );
      message.success(TOAST_MESSAGES.OTP_SENT_SUCCESS);
      if (onSuccess) {
        onSuccess(data);
      }
    });
  },
  verifyOtp: (payload) => {
    handleApiCallInStore(LoadingKeys.verifyOtp, async () => {
      await http.post<VerifyPasswordPayload, ApiResponse<null>>(API_END_POINTS.verifyOtp, payload);
      message.success(TOAST_MESSAGES.OTP_VERIFY_SUCCESS);
      useAppStore.getState().navigate({
        pathname: ROUTES.CHANGE_PASSWORD,
        search: `?${new URLSearchParams({
          [emailSearchParamAttr]: payload.email,
        }).toString()}`,
      });
    });
  },
  logout: () => {
    const isAdmin = get().user?.role?.user_types_id === 1;
    localStorageHelper.clearItem(userAttr);
    Cookies.remove(tokenAttr);
    get().resetSelectedCompany();
    set({ user: null, token: null });
    window.location.href = isAdmin ? ROUTES.ADMIN_LOGIN : ROUTES.LOGIN;
  },

  changePassword: (payload) => {
    handleApiCallInStore(LoadingKeys.changePassword, async () => {
      await http.put<ChangePasswordPayload, ApiResponse<null>>(API_END_POINTS.resetPassword, payload);
      sessionStorage.setItem(emailSearchParamAttr, payload.email);
      sessionStorage.setItem(passwordSessionAttr, payload.password);
      useAppStore.getState().navigate(ROUTES.PASSWORD_RESET);
    });
  },
}));
