/* eslint-disable camelcase */
import { create } from 'zustand';
import { ApiResponse, ICustomerRedemption, IRedeemPayload } from '@/types';
import { handleApiCallInStore } from './utils';
import { API_END_POINTS, LoadingKeys } from '@/constants';
import { http } from '@/utils';

interface IRedemptionStore {
  customerRedemption: ICustomerRedemption;
  getCustomerRedemption: () => void;
  redeemCard: (payload: IRedeemPayload, onSuccess: () => void) => void;
}

export const useRedemptionStore = create<IRedemptionStore>((set) => ({
  customerRedemption: undefined,
  redeemCard: (payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.redeemCard, async () => {
      await http.post<IRedeemPayload>(API_END_POINTS.redeemCard, payload);
      onSuccess();
    });
  },
  getCustomerRedemption: () => {
    handleApiCallInStore(LoadingKeys.getCustomerRedemption, async () => {
      const { data } = await http.get<undefined, ApiResponse<ICustomerRedemption>>(API_END_POINTS.customerRedemption);
      set({
        customerRedemption: data,
      });
    });
  },
}));
