export interface UserTypes {
  id: number;
  name: string;
  deleted_at: any;
  created_at: string;
  updated_at: string;
}
export interface Role {
  id: number;
  name_ar: string;
  name_en: string;
  deleted_at: any;
  created_at: string;
  updated_at: string;
  company_id: number;
  user_types_id: number;
  user_types: UserTypes;
}

export interface IAuthRes {
  access_token: string;
  user: {
    id: number;
    name: string;
    sub_domain: string;
    email: string;
    mobile: string;
    country_code: string;
    registration_number: string;
    representative_full_name: string;
    logo: null | string;
    type: string;
    password_updated_at: null | string;
    password_reset_code: null | string;
    password_reset_code_expires: null | string;
    password_reset_verified: boolean;
    is_active: boolean;
    is_first_login: boolean;
    login_step: number | string;
    login_attempts: number;
    last_login_attempt: null | string;
    last_login_attempt_ip: null | string;
    last_login: null | string;
    last_login_ip: null | string;
    created_at: string;
    updated_at: string;
    role_id: number;
    last_path: string;
    business_id: string;
    deleted_at: string;
    permissions: Record<string, string[]>;
    permissions_keys: Record<string, string>;
    role: Role;
    company_name: string;
    distributor_type: string;
  };
}

export interface ILoginPayload {
  email: string;
  password: string;
  remember?: boolean;
  company_id: number;
}

export interface ForgetPasswordPayload {
  email: string;
}

export interface ForgetPasswordRes {
  opt: number;
}
export interface VerifyPasswordPayload {
  otp: string;
  email: string;
}

export interface ChangePasswordPayload {
  email: string;
  password: string;
  confirm_password: string;
}

export interface IProfilePayload {
  id: number;
  name: string;
  full_name?: string;
  image?: string;
  sub_domain: string;
  email: string;
  mobile: string;
  country_code: string;
  registration_number: string;
  representative_full_name: string;
  logo: string;
  type: string;
  password_reset_code: string;
  password_reset_code_expires: string;
  password_reset_verified: boolean;
  is_active: boolean;
  is_first_login: boolean;
  login_step: number | string;
  login_attempts: number;
  last_login_attempt: string;
  last_login_attempt_ip: string;
  last_login: string;
  last_login_ip: string;
  last_path?: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  role_id: number;
  permissions: Record<string, string[]>;
  permissions_keys: Record<string, string>;
  role: Role;
  company_name: string;
  distributor_type: string;
}

export enum PermissionType {
  EDIT = 'Edit',
  DELETE = 'Delete',
  CREATE = 'Create',
  View = 'View',
  Assign = 'Assign',
}
export interface Settings {
  main_color: string;
  secondary_color: string;
}
export interface ICompanyDetailsByHostName {
  id: number;
  name: string;
  logo: string;
  settings: Settings;
}
