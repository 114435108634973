import { Col, Row } from 'antd';
import { ColProps } from 'antd/lib';
import { PropsWithChildren } from 'react';

export const InnerPagesWrapper = ({ children, colProps = {} }: PropsWithChildren<{ colProps?: ColProps }>) => (
  <Row>
    <Col lg={12} {...colProps}>
      {children}
    </Col>
  </Row>
);
