import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { langLabel, optionalLabel } from '@/utils';
import { RULES } from '../rules';
import { FormField } from '@/types';

export const useCategoryFields = () => {
  const { t } = useTranslation();

  const categoryFields = useMemo(
    (): FormField[] => [
      {
        label: langLabel(t('categoryName'), 'en'),
        name: 'name_en',
        type: 'input',
        placeholder: t('enterCategoryName'),
        rules: [RULES.required, RULES.englishOnly],
      },
      {
        label: langLabel(t('categoryName'), 'ar'),
        name: 'name_ar',
        type: 'input',
        placeholder: t('enterCategoryName'),
        rules: [RULES.required, RULES.arabicOnly],
      },
      {
        label: optionalLabel(langLabel(t('description'), 'en')),
        name: 'description_en',
        type: 'textarea',
        placeholder: t('description'),
        rules: [RULES.englishOnly],
      },
      {
        label: optionalLabel(langLabel(t('description'), 'ar')),
        name: 'description_ar',
        type: 'textarea',
        placeholder: t('description'),
        rules: [RULES.arabicOnly],
      },
    ],
    [],
  );

  return {
    categoryFields,
  };
};
