/* eslint-disable camelcase */
import { create } from 'zustand';
import {
  ApiResponse,
  ApiResponseWithPagination,
  BasePaginationParams,
  ICalculateItemPayload,
  IChangeOrderStatusPayload,
  IOrderDetails,
  IStoreOrderPayload,
  MarketingGroupOrderItem,
  OrderItem,
  OrderType,
  ProductItem,
  ProductOrderItem,
  StringOrNumber,
} from '@/types';
import { handleApiCallInStore } from './utils';
import { API_END_POINTS, LoadingKeys } from '@/constants';
import { http } from '@/utils';

interface IOrdersStore {
  totalPrice: number;
  totalCalculatorPoints: number;
  orders: OrderItem[];
  totalOrdersCount: number;
  orderDetails: IOrderDetails;
  resetCalculationList: () => void;
  calculatorList: (ProductOrderItem | MarketingGroupOrderItem)[];
  calculateItemPoints: (payload: ICalculateItemPayload, onSuccess?: () => void) => void;
  removeCalculatorItem: (index: number) => void;
  getAllOrders: (params: BasePaginationParams & { type: string }) => void;
  getOrderDetails: (id: StringOrNumber, orderType: OrderType, onSuccess: (data: IOrderDetails) => void) => void;
  createOrUpdateOrder: (id: StringOrNumber, payload: IStoreOrderPayload, onSuccess?: () => void) => void;
  resetOrderDetails: () => void;
  changeOrderStatus: (orderId: StringOrNumber, payload: IChangeOrderStatusPayload, onSuccess: () => void) => void;
}

const calculatePointsFromCalcListArr = (calculatorList: (ProductItem | MarketingGroupOrderItem)[]) =>
  calculatorList.reduce((acc, current) => acc + Number(current?.points ?? 0), 0);
const calculateTotalPriceFromCalcListArr = (calculatorList: (ProductItem | MarketingGroupOrderItem)[]) =>
  calculatorList.reduce((acc, current) => acc + Number((current?.price ?? 0) * (current?.quantity ?? 0)), 0);

export const useOrdersStore = create<IOrdersStore>((set, get) => ({
  orderDetails: undefined,
  orders: [],
  totalOrdersCount: 0,
  totalCalculatorPoints: 0,
  totalPrice: 0,
  calculatorList: [],
  changeOrderStatus: (orderId, payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.changeOrderStatus, async () => {
      await http.put<IChangeOrderStatusPayload>(API_END_POINTS.changeOrderStatus(orderId), payload);
      onSuccess();
    });
  },
  resetCalculationList: () => {
    set({
      calculatorList: [],
      totalCalculatorPoints: 0,
      totalPrice: 0,
    });
  },
  resetOrderDetails: () => {
    set({
      orderDetails: undefined,
    });
  },
  getAllOrders: (payload) => {
    handleApiCallInStore(LoadingKeys.getAllOrders, async () => {
      const { data } = await http.get<undefined, ApiResponseWithPagination<OrderItem>>(API_END_POINTS.orders, {
        params: payload,
      });
      set({ orders: data.results, totalOrdersCount: data.total });
    });
  },
  getOrderDetails: (orderId, orderType, onSuccess) => {
    handleApiCallInStore(LoadingKeys.getOrderDetails, async () => {
      const { data } = await http.get<undefined, ApiResponse<IOrderDetails>>(
        API_END_POINTS.orderCrud(orderId, orderType),
      );
      onSuccess(data);
      set({
        orderDetails: data,
        calculatorList:
          orderType === 'marketing_group'
            ? data?.marketingGroups.map((marketingItem) => ({
                ...marketingItem,
                item_id: marketingItem?.marketing_group_id,
              }))
            : data?.items.map((productItem) => ({
                ...productItem,
                item_id: productItem?.item_id,
              })),
        totalCalculatorPoints: data?.total_points,
        totalPrice: calculateTotalPriceFromCalcListArr(
          orderType === 'marketing_group' ? data?.marketingGroups : data?.items,
        ),
      });
    });
  },
  calculateItemPoints: (payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.calculateItemPoints, async () => {
      const { data } = await http.post<ICalculateItemPayload, ApiResponse<{ item: ProductItem; points: number }>>(
        API_END_POINTS.calculatePoints,
        payload,
      );
      const newCalcList: (ProductOrderItem | MarketingGroupOrderItem)[] = [
        { ...data?.item, quantity: payload.quantity, points: data?.points, item_id: data?.item?.id },
        ...get().calculatorList,
      ];
      set({
        calculatorList: newCalcList,
        totalCalculatorPoints: calculatePointsFromCalcListArr(newCalcList),
        totalPrice: calculateTotalPriceFromCalcListArr(newCalcList),
      });
      onSuccess?.();
    });
  },
  removeCalculatorItem: (itemIndex) => {
    const newCalcList = get().calculatorList.filter((_, index) => index !== itemIndex);
    set({
      calculatorList: newCalcList,
      totalCalculatorPoints: calculatePointsFromCalcListArr(newCalcList),
      totalPrice: calculateTotalPriceFromCalcListArr(newCalcList),
    });
  },
  createOrUpdateOrder: (id, payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.createOrUpdateOrder, async () => {
      if (id) {
        await http.put<IStoreOrderPayload>(API_END_POINTS.updateOrder(id), payload);
      } else {
        await http.post<IStoreOrderPayload>(API_END_POINTS.createOrder, payload);
      }
      onSuccess?.();
    });
  },
}));
