import { useGenericStore } from './generic-store';
import { LoadingKeys } from '../constants/loading-keys';
import { API_END_POINTS } from '../constants/api-end-points';
import { ICompanyItem, ICreateOrUpdateCompany } from '@/types';

export const useCompaniesStore = useGenericStore<ICompanyItem, Partial<ICreateOrUpdateCompany>>(
  {
    getAllEndpoint: API_END_POINTS.companies,
    deleteEndpoint: API_END_POINTS.companiesCrud,
    getOneEndpoint: API_END_POINTS.companiesCrud,
    updateEndpoint: API_END_POINTS.companiesCrud,
  },
  {
    getAllLoadingKey: LoadingKeys.getAllCompanies,
    createOrUpdateLoadingKey: LoadingKeys.createOrUpdateCompany,
    deleteLoadingKey: LoadingKeys.deleteCompany,
  },
  true,
);
