import { Affix, Flex, Layout, Typography } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { HelpBtn, Logo, Paragraph, UserDropdown } from '@/components';
import { MailIcon } from '@/components/icons';
import StepsComp from './components/steps/steps.component';
import styles from './wizard-steps-layput.module.scss';
import { useUserStore } from '@/stores';

const { Title } = Typography;

export const WizardStepsLayout = () => {
  const { t } = useTranslation();
  const { companySettings } = useUserStore((state) => state);

  return (
    <Layout className={styles.layout} hasSider>
      <Sider
        className={styles.sider}
        collapsedWidth="0"
        width="var(--wizard-steps-layout-sider-width)"
        style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }}
      >
        <div className={styles.innerSiderWrap}>
          <Flex gap={10} align="center">
            <Logo />
            <Title level={5}>{companySettings?.company?.name}</Title>
          </Flex>
          <div className={styles.stepsWrpper}>
            <StepsComp />
          </div>
          <div>
            <Flex justify="space-between" className={styles.siderBottomWrapper}>
              <Paragraph>
                © {t('LTD')} {new Date().getFullYear()}
              </Paragraph>
              <Paragraph className={styles.email}>
                <a href="mailto:help@ltd.com">
                  <span className={styles.mailIcon}>
                    <MailIcon />
                  </span>
                  <span>help@ltd.com</span>
                </a>
              </Paragraph>
            </Flex>
          </div>
        </div>
      </Sider>
      <Layout
        className={styles.headerContentWrap}
        style={{ marginInlineStart: 'var(--wizard-steps-layout-sider-width)' }}
      >
        <Affix offsetTop={0}>
          <Header className={styles.header}>
            <HelpBtn />
            <UserDropdown />
          </Header>
        </Affix>

        <Content className={styles.content}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
