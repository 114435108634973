import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { RULES } from '../rules';
import { FormField } from '@/types';
import { useRoles } from '@/hooks';

export const useSalesTeamFields = () => {
  const { t } = useTranslation();
  const { rolesOptions } = useRoles();

  const salesTeamFields = useMemo(
    (): FormField[] => [
      {
        label: t('name'),
        name: 'name',
        type: 'input',
        rules: [RULES.required],
      },
      {
        label: t('position'),
        name: 'role_id',
        type: 'select',
        placeholder: t('selectRole'),
        rules: [RULES.required],
        options: rolesOptions,
      },
      {
        label: t('email'),
        name: 'email',
        type: 'input',
        placeholder: t('enterMemberEmail'),
        rules: [RULES.required, RULES.whiteSpace, RULES.email],
      },
      {
        label: t('phoneNumber'),
        name: 'mobile',
        type: 'phone',
        phoneSelectName: 'country_code',
        rules: [RULES.required, RULES.number, RULES.whiteSpace, RULES.mobile],
      },
      {
        label: t('region'),
        name: 'region',
        type: 'input',
        rules: [RULES.required],
      },
      {
        label: t('area'),
        name: 'area',
        type: 'input',
        rules: [RULES.required],
      },
    ],
    [rolesOptions],
  );

  return {
    salesTeamFields,
  };
};
