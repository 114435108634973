import { SVGProps } from 'react';

export const GiftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.646 15.167H5.313c-2.28 0-3.167-.887-3.167-3.167V6.667c0-.274.227-.5.5-.5h10.667c.273 0 .5.226.5.5V12c0 2.28-.886 3.167-3.167 3.167Zm-7.5-8V12c0 1.72.447 2.167 2.167 2.167h5.333c1.72 0 2.167-.447 2.167-2.167V7.167H3.146Z"
      fill="#000"
      fillOpacity={0.88}
    />
    <path
      d="M13 7.167H3c-1.166 0-1.833-.667-1.833-1.834v-.666c0-1.167.667-1.834 1.833-1.834h10c1.134 0 1.834.7 1.834 1.834v.666c0 1.134-.7 1.834-1.834 1.834ZM3 3.833c-.606 0-.833.227-.833.834v.666c0 .607.227.834.833.834h10c.587 0 .834-.247.834-.834v-.666c0-.587-.247-.834-.834-.834H3Z"
      fill="#292D32"
    />
    <path
      d="M7.76 3.833H4.08a.504.504 0 0 1-.367-.16 1.13 1.13 0 0 1 .033-1.56l.947-.946c.44-.44 1.167-.44 1.607 0L8.113 2.98a.502.502 0 0 1-.353.853Zm-3.314-1H6.56l-.967-.96a.137.137 0 0 0-.193 0l-.947.947c0 .007-.007.007-.007.013Z"
      fill="#292D32"
    />
    <path
      d="M11.913 3.833h-3.68c-.2 0-.386-.12-.46-.306a.504.504 0 0 1 .107-.547l1.813-1.813c.44-.44 1.167-.44 1.607 0l.947.946c.426.427.446 1.114.033 1.56a.504.504 0 0 1-.367.16Zm-2.466-1h2.113l-.013-.013-.947-.947a.137.137 0 0 0-.193 0l-.96.96ZM6.627 11.267A1.168 1.168 0 0 1 5.46 10.1V6.667c0-.274.227-.5.5-.5h4.027c.273 0 .5.226.5.5v3.42c0 .433-.234.826-.614 1.026-.38.207-.84.18-1.2-.06l-.593-.4a.16.16 0 0 0-.187 0l-.626.414c-.194.133-.42.2-.64.2Zm-.167-4.1v2.926c0 .087.053.127.087.147.033.02.1.04.173-.007l.627-.413c.393-.26.9-.26 1.286 0l.594.4c.073.047.14.027.173.007a.162.162 0 0 0 .087-.147V7.16H6.46v.007Z"
      fill="#292D32"
    />
  </svg>
);
