import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Tag } from 'antd';
import { FormField } from '@/types';
import {
  ORDER_CLOSED_REASONS,
  ORDER_CLOSED_REASONS_MAPPER,
  ORDER_STATUS,
  ORDER_STATUS_MAPPER,
} from '../sales-constants';
import { RULES } from '../rules';

export const useOrderFields = () => {
  const { t } = useTranslation();

  const orderSearchFields = useMemo(
    (): FormField[] => [
      {
        label: t('status'),
        name: 'status',
        type: 'select',
        placeholder: t('selectStatus'),
        options: Object.values(ORDER_STATUS_MAPPER).map((item) => ({
          label: <Tag color={item?.color}>{item?.label}</Tag>,
          value: item.value,
        })),
      },
    ],
    [],
  );
  const closedOrderStatusReasons = useMemo(
    (): FormField[] => [
      {
        label: t('reason'),
        name: 'reason',
        type: 'select',
        placeholder: t('selectReason'),
        options: Object.values(ORDER_CLOSED_REASONS_MAPPER),
      },
      {
        label: t('otherReason'),
        name: 'reason_other_text',
        type: 'textarea',
        rules: [RULES.required],
        dependsOn: 'reason',
        showWhen: (value: string) => value === ORDER_CLOSED_REASONS.OTHER,
      },
    ],
    [],
  );

  return {
    closedOrderStatusReasons,
    orderSearchFields,
  };
};
