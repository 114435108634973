import { SVGProps } from 'react';

const UnassignIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={17} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.167 10.667V4.333c0-.733-.6-1.333-1.333-1.333h-3"
      stroke="#292D32"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 1.333 8.5 3l2 1.667M3.833 6v4.667M4 5.667a2.167 2.167 0 1 0 0-4.334 2.167 2.167 0 0 0 0 4.334ZM3.833 14.667a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM13.166 14.667a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      stroke="#292D32"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UnassignIcon;
