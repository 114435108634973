import { Flex, Pagination } from 'antd';
import { PaginationProps } from 'antd/lib';
import { CSSProperties } from 'react';
import styles from './pagination-wrap.module.scss';

export const PaginationWrap = ({
  current,
  pageSize,
  total,
  onChange,
  justify = 'flex-end',
}: PaginationProps & { justify?: CSSProperties['justifyContent'] }) => (
  <Flex justify={justify} className={styles.paginationWrap}>
    <Pagination current={current} pageSize={pageSize} total={total} onChange={onChange} />
  </Flex>
);
