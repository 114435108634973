/* eslint-disable camelcase */
import { create } from 'zustand';
import { message } from 'antd';
import { handleApiCallInStore } from './utils';
import { API_END_POINTS, LoadingKeys, TOAST_MESSAGES } from '@/constants';
import { convertToFormData, customHttp, http } from '@/utils';
import {
  AddOrEditBrandPayload,
  ApiResponseWithPagination,
  BasePaginationParams,
  BrandItem,
  IBrandDetails,
  StringOrNumber,
} from '@/types';

interface IBrandStore {
  brands: BrandItem[];
  totalBrandsCount: number;
  brandDetails: IBrandDetails;
  getAllBrands: (payload: BasePaginationParams & { sub_category_id?: number }) => void;
  deleteBrand: (id: number, onSuccess: () => void) => void;
  addOrEditBrand: (payload: AddOrEditBrandPayload, onSuccess: () => void) => void;
  getBrandById: (subcategoryId: StringOrNumber) => void;
}

export const useBrandStore = create<IBrandStore>((set) => ({
  brands: [],
  totalBrandsCount: 0,
  brandDetails: undefined,
  addOrEditBrand: (payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.addOrEditBrand, async () => {
      if (!payload.id) {
        await customHttp.post<FormData>(API_END_POINTS.brands, convertToFormData<AddOrEditBrandPayload>(payload));
      } else {
        await customHttp.put<FormData>(
          API_END_POINTS.updateBrand(payload.id),
          convertToFormData<AddOrEditBrandPayload>(payload),
        );
      }
      onSuccess();
    });
  },
  getAllBrands: (payload) => {
    handleApiCallInStore(LoadingKeys.getAllBrands, async () => {
      const { data } = await http.get<undefined, ApiResponseWithPagination<BrandItem>>(API_END_POINTS.brands, {
        params: payload,
      });
      set({ brands: data.results, totalBrandsCount: data.total });
    });
  },
  deleteBrand: (id, onSuccess) => {
    handleApiCallInStore(LoadingKeys.deleteBrand, async () => {
      await http.delete(API_END_POINTS.deleteBrand(id));
      onSuccess();
      message.success(TOAST_MESSAGES.DELETE_Brand_SUCCESS);
    });
  },
  getBrandById: (id) => {
    handleApiCallInStore(LoadingKeys.getBrandById, async () => {
      const { data } = await http.get<IBrandDetails>(API_END_POINTS.getBrandById(id));
      set({ brandDetails: data });
    });
  },
}));
