import { SVGProps } from 'react';

export const RedeemGiftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={19} height={19} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.969 5.95h-2.595c.24-.377.38-.823.38-1.302A2.423 2.423 0 0 0 9.5 3.055a2.423 2.423 0 0 0-4.254 1.593c0 .479.139.925.38 1.301H3.03a.562.562 0 0 0-.562.563v3.515c0 .078.063.141.14.141h.704v6.047c0 .311.25.562.562.562h11.25a.562.562 0 0 0 .563-.562v-6.047h.703c.077 0 .14-.063.14-.14V6.511a.562.562 0 0 0-.562-.563Zm-5.871-1.302c0-.678.552-1.23 1.23-1.23.679 0 1.23.552 1.23 1.23 0 .679-.551 1.23-1.23 1.23h-1.23v-1.23Zm-2.426-1.23c.678 0 1.23.552 1.23 1.23v1.23h-1.23c-.679 0-1.23-.551-1.23-1.23 0-.678.551-1.23 1.23-1.23ZM3.664 8.973V7.145h5.238v1.828H3.664Zm.844 1.195h4.394v5.414H4.508v-5.414Zm9.984 5.414h-4.394v-5.414h4.394v5.414Zm.844-6.61h-5.238V7.146h5.238v1.828Z"
      fill="#000"
      fillOpacity={0.88}
    />
    <path
      d="m12.952 5.681-.488.768H15.969c.035 0 .062.028.062.063v3.156h-.843v6.547a.062.062 0 0 1-.063.062H3.875a.062.062 0 0 1-.063-.062V9.668H2.97V6.512c0-.035.027-.063.062-.063H6.54l-.493-.77c-.19-.297-.3-.65-.3-1.03a1.923 1.923 0 0 1 3.377-1.264l.376.431.377-.431a1.923 1.923 0 0 1 3.377 1.263c0 .38-.111.733-.302 1.033Zm-3.354.198v.5h1.73c.955 0 1.73-.776 1.73-1.73 0-.955-.775-1.731-1.73-1.731-.954 0-1.73.776-1.73 1.73v1.23Zm-.696.5h.5v-1.73c0-.955-.775-1.731-1.73-1.731s-1.73.776-1.73 1.73c0 .955.775 1.73 1.73 1.73h1.23ZM3.164 8.973v.5h6.238V6.645H3.164V8.973Zm1.344.695h-.5v6.414h5.394V9.668H4.508Zm9.984 6.414h.5V9.668H9.598v6.414h4.894Zm.844-6.61h.5V6.646H9.598V9.473h5.738Z"
      stroke="#000"
      strokeOpacity={0.88}
    />
  </svg>
);
