import { Breadcrumb, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { ROUTES } from '@/constants';
import { BreadCrumbItem } from '@/types';
import styles from './breadcrumb.module.scss';

interface DynamicBreadcrumbItems {
  items: BreadCrumbItem[];
  loading?: boolean;
}

export const DynamicBreadcrumb = ({ items = [], loading = false }: DynamicBreadcrumbItems) => {
  const { t } = useTranslation();

  return (
    <>
      {loading ? (
        <Skeleton active title={false} paragraph={{ rows: 1, width: '30%' }} />
      ) : (
        <>
          {items?.length > 0 && (
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={ROUTES.HOME}>{t('dashboard')}</Link>
              </Breadcrumb.Item>
              {items.map((item, index) => (
                <Breadcrumb.Item
                  className={cn(styles.breadItem, {
                    [styles.lastOne]: index === items.length - 1,
                  })}
                  key={item.title}
                >
                  <Link to={item.link}>{item.title}</Link>
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          )}
        </>
      )}
    </>
  );
};
