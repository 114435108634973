import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetInitialData } from '@/hooks';
import { API_END_POINTS } from '../api-end-points';
import { LoadingKeys } from '../loading-keys';
import { FormField, MasterDataType, ProductItem } from '@/types';
import { RULES } from '../rules';

const shownItemsBasedOnType: Record<MasterDataType, number> = {
  categories: 0,
  subCategories: 1,
  brands: 2,
  SKUs: 3,
  items: 4,
};

interface FilterMasterDataArguments {
  type: MasterDataType | 'all';
  isHorizontal?: boolean;
  withLabel?: boolean;
  hideItemsOnSKUEmpty?: boolean;
  isSKURequired?: boolean;
  isItemsEnabled?: boolean;
  formInstance?: FormInstance;
}
export const useFilterMasterDataFields = ({
  type,
  isHorizontal = true,
  withLabel = false,
  hideItemsOnSKUEmpty = false,
  isSKURequired = false,
  isItemsEnabled = false,
}: FilterMasterDataArguments) => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState<number>();
  const [selectedSubcategory, setSelectedSubcategory] = useState<number>();
  const [selectedBrand, setSelectedBrand] = useState<number>();
  const [selectedSKU, setSelectedSKU] = useState<number>();

  const location = useLocation();
  const categoriesData = useGetInitialData({
    apiEndPoint: API_END_POINTS.categories,
    loadingKey: LoadingKeys.getAllCategoriesLite,
  });

  const subcategoriesData = useGetInitialData({
    apiEndPoint: API_END_POINTS.subcategories,
    loadingKey: LoadingKeys.getAllSubcategoriesLite,
    fetchOnMount: false,
  });

  const brandsData = useGetInitialData({
    apiEndPoint: API_END_POINTS.brands,
    loadingKey: LoadingKeys.getAllBrandsLite,
    fetchOnMount: false,
  });

  const SKUsData = useGetInitialData({
    apiEndPoint: API_END_POINTS.SKUs,
    loadingKey: LoadingKeys.getAllSKUsLite,
    fetchOnMount: false,
  });

  const itemsData = useGetInitialData({
    apiEndPoint: API_END_POINTS.items,
    loadingKey: LoadingKeys.getAllItemsLite,
    fetchOnMount: isItemsEnabled,
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.size > 0) {
      const categoryIdParamValue = searchParams.get('category_id');
      const subcategoryIdParamValue = searchParams.get('sub_category_id');
      const brandIdParamValue = searchParams.get('brand_id');

      setSelectedCategory(categoryIdParamValue ? Number(categoryIdParamValue) : undefined);
      setSelectedSubcategory(subcategoryIdParamValue ? Number(subcategoryIdParamValue) : undefined);
      setSelectedBrand(brandIdParamValue ? Number(brandIdParamValue) : undefined);
    }
  }, [location.search]);

  // handle selected category id change
  useEffect(() => {
    if (selectedCategory) {
      subcategoriesData?.fetchData(selectedCategory ? { category_id: selectedCategory } : {});
    }
  }, [selectedCategory]);

  // handle selected subcategory id change
  useEffect(() => {
    if (selectedSubcategory) {
      brandsData?.fetchData(selectedSubcategory ? { sub_category_id: selectedSubcategory } : {});
    }
  }, [selectedSubcategory]);

  // handle selected brand id change

  useEffect(() => {
    if (selectedBrand) {
      SKUsData?.fetchData(selectedBrand ? { brand_id: selectedBrand } : {});
    }
  }, [selectedBrand]);

  // handle select sku
  useEffect(() => {
    if (selectedSKU) {
      itemsData?.fetchData(selectedSKU ? { sku_id: selectedSKU } : {});
    }
  }, [selectedSKU]);

  const onCategoryChange = (categoryId: number, form: FormInstance) => {
    setSelectedCategory(categoryId);
    setSelectedSubcategory(undefined);

    form.setFieldValue('sub_category_id', undefined);
    form.setFieldValue('brand_id', undefined);
    form.setFieldValue('sku_id', undefined);
    form.setFieldValue('item_id', undefined);
  };
  const onSubcategoryChange = (subcategoryId: number, form: FormInstance) => {
    setSelectedSubcategory(subcategoryId);
    form.setFieldValue('sku_id', undefined);
    form.setFieldValue('brand_id', undefined);
  };

  const onBrandChange = (brandId: number, form: FormInstance) => {
    setSelectedBrand(brandId);
    form.setFieldValue('sku_id', undefined);
    form.setFieldValue('item_id', undefined);
  };
  const onSKUChange = (skuId: number, form: FormInstance) => {
    setSelectedSKU(skuId);
    form.setFieldValue('item_id', undefined);
  };

  const handleItemChange = (_, form: FormInstance, { element }: { element: ProductItem }) => {
    if (isItemsEnabled) {
      const category = { ...element?.category, label: element.category?.name };
      const subcategory = { ...element?.sub_category, label: element.sub_category?.name };
      const brand = { ...element?.brand, label: element?.brand?.name };
      const sku = { ...element?.sku, label: element.sku?.name };
      setSelectedCategory(element?.category_id);
      setSelectedSubcategory(element?.sub_category_id);
      setSelectedBrand(element?.brand_id);
      setSelectedSKU(element?.sku_id);
      form.setFieldValue('category_id', category);
      form.setFieldValue('sub_category_id', subcategory);
      form.setFieldValue('brand_id', brand);
      form.setFieldValue('sku_id', sku);
    }
  };
  const onReset = () => {
    setSelectedCategory(undefined);
    setSelectedSubcategory(undefined);
    setSelectedBrand(undefined);
    setSelectedSKU(undefined);
  };

  const masterDataFields = useMemo((): FormField[] => {
    const fields = [
      {
        ...(withLabel && { label: t('category') }),
        name: 'category_id',
        type: 'infiniteSelect' as const,
        placeholder: t('selectCategory'),
        ...(isHorizontal && {
          colProps: {
            span: 24,
            sm: 12,
            lg: 8,
            xl: 6,
          },
        }),

        infiniteSelectProps: {
          apiEndPoint: API_END_POINTS.categories,
          loadingKey: LoadingKeys.getAllCategoriesLite,
          initialData: categoriesData?.initialData,
          initialHasMore: categoriesData?.hasMore,
          size: 'middle' as const,
          onSelectChange: onCategoryChange,
        },
      },
      {
        ...(withLabel && { label: t('subcategory') }),
        name: 'sub_category_id',
        type: 'infiniteSelect' as const,
        placeholder: t('selectSubcategory'),
        ...(isHorizontal && {
          colProps: {
            span: 24,
            sm: 12,
            lg: 8,
            xl: 6,
          },
        }),
        infiniteSelectProps: {
          apiEndPoint: API_END_POINTS.subcategories,
          loadingKey: LoadingKeys.getAllSubcategoriesLite,
          initialData: selectedCategory === undefined ? [] : subcategoriesData?.initialData,
          initialHasMore: subcategoriesData?.hasMore,
          size: 'middle' as const,
          disabled: !selectedCategory,
          onSelectChange: onSubcategoryChange,
        },
      },
      {
        ...(withLabel && { label: t('brand') }),

        name: 'brand_id',
        type: 'infiniteSelect' as const,
        placeholder: t('selectBrand'),
        ...(isHorizontal && {
          colProps: {
            span: 24,
            sm: 12,
            lg: 8,
            xl: 6,
          },
        }),
        infiniteSelectProps: {
          apiEndPoint: API_END_POINTS.brands,
          loadingKey: LoadingKeys.getAllBrandsLite,
          initialData: selectedSubcategory === undefined ? [] : brandsData?.initialData,
          initialHasMore: brandsData?.hasMore,
          size: 'middle' as const,
          disabled: !selectedSubcategory,
          onSelectChange: onBrandChange,
        },
      },
      {
        ...(withLabel && { label: t('SKU') }),

        name: 'sku_id',
        type: 'infiniteSelect' as const,
        placeholder: t('selectSKU'),
        rules: isSKURequired ? [RULES.required] : [],
        ...(isHorizontal && {
          colProps: {
            span: 24,
            sm: 12,
            lg: 8,
            xl: 6,
          },
        }),
        infiniteSelectProps: {
          apiEndPoint: API_END_POINTS.SKUs,
          loadingKey: LoadingKeys.getAllSKUsLite,
          initialData: selectedBrand === undefined ? [] : SKUsData?.initialData,
          initialHasMore: SKUsData?.hasMore,
          size: 'middle' as const,
          disabled: !selectedBrand,
          onSelectChange: onSKUChange,
        },
      },
      ...(hideItemsOnSKUEmpty && selectedSKU && itemsData?.initialData?.length === 0
        ? []
        : [
            {
              ...(withLabel && { label: t('item') }),

              name: 'item_id',
              type: 'infiniteSelect' as const,
              placeholder: t('selectItem'),
              ...(isHorizontal && {
                colProps: {
                  span: 24,
                  sm: 12,
                  lg: 8,
                  xl: 6,
                },
              }),
              infiniteSelectProps: {
                apiEndPoint: API_END_POINTS.items,
                loadingKey: LoadingKeys.getAllItems,
                initialData: selectedSKU === undefined && !isItemsEnabled ? [] : itemsData?.initialData,
                initialHasMore: itemsData?.hasMore,
                size: 'middle' as const,
                disabled: isItemsEnabled ? false : !selectedSKU,
                onSelectChange: handleItemChange,
              },
            },
          ]),
    ];
    return type === 'all' ? fields : fields.slice(0, shownItemsBasedOnType[type]);
  }, [
    selectedBrand,
    categoriesData,
    selectedCategory,
    subcategoriesData,
    selectedSubcategory,
    brandsData,
    SKUsData,
    isHorizontal,
    isItemsEnabled,
  ]);
  return {
    masterDataFields,
    onReset,
  };
};
