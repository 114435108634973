import { SVGProps } from 'react';

export const ArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={14} fill="none" {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeOpacity={0.88}
      strokeWidth={1.667}
      d="M13.334 7H1.667m0 0L7.5 12.832M1.667 6.999 7.5 1.166"
    />
  </svg>
);
