import { FC, useState } from 'react';
import { Upload, Image as AntdImage, Flex } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { useTranslation } from 'react-i18next';
import { getBase64, validateFile } from '@/utils';
import { UploadPlusIcon } from '../icons';
import { ImageUploadProps } from '@/types';
import { useModal } from '@/hooks';
import { Paragraph } from '../paragraph/paragraph.component';
import styles from './box-uploader.module.scss';

export const BoxUploader: FC<ImageUploadProps> = ({
  maxImagesCount = 1,
  fileList = [],
  listType = 'picture-card',
  fileTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'],

  maxSizeInMB,
  onChangeFileList = () => {},
  onRemoveImage = () => {},
  onUploadFile = () => {},
}) => {
  const { t } = useTranslation();
  const { isOpen, openModal, closeModal } = useModal();
  const [imageUrl, setImageUrl] = useState(null);

  const readFile = async (file: UploadFile | RcFile | Blob | string) =>
    await new Promise<string>((resolve) => {
      const reader = new FileReader();
      if (typeof file === 'object' && 'originFileObj' in file) {
        reader.readAsDataURL(file.originFileObj as RcFile);
      } else {
        reader.readAsDataURL(file as RcFile);
      }

      reader.onload = () => resolve(reader.result as string);
    });

  const uploadRequest = async ({ onSuccess }: UploadRequestOption) => {
    onSuccess?.('ok');
  };

  // handle remove image
  const onRemove = (file: UploadFile<any>) => {
    if (onRemoveImage) {
      onRemoveImage(file.uid);
    }
  };

  // handle image change
  const onChange: UploadProps['onChange'] = (info) => {
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as RcFile, (base64File) => {
        if (onUploadFile) {
          onUploadFile(base64File as string);
        }
      });
    }
    onChangeFileList(info.fileList);
  };

  // handle before uploading

  const beforeUpload = (file: RcFile) =>
    validateFile(file, fileTypes as string[], maxSizeInMB as number) || Upload.LIST_IGNORE;

  // handle image preview
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await readFile(file);
    }
    setImageUrl(src);
    openModal();
  };

  return (
    <>
      <Upload
        customRequest={uploadRequest}
        listType={listType}
        fileList={[...fileList]}
        onChange={onChange}
        onPreview={onPreview}
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        accept={fileTypes?.join()}
      >
        {fileList.length < maxImagesCount && (
          <Flex vertical justify="center" align="center" gap={6}>
            <UploadPlusIcon />
            <Paragraph className={styles.uploadText}>{t('upload')}</Paragraph>
          </Flex>
        )}
      </Upload>
      <AntdImage
        width={0}
        height={0}
        src={imageUrl}
        preview={{
          visible: isOpen,
          onVisibleChange: closeModal,
        }}
      />
    </>
  );
};
