import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { QuestionMark } from '../icons';
import styles from './info-tooltip.module.scss';

interface InfoTooltipProps {
  text: string;
  inLabel?: boolean;
}

export const InfoTooltip = ({ text, inLabel = false }: InfoTooltipProps) => (
  <Tooltip placement="topLeft" title={text}>
    <button type="button" aria-label={text} className={styles.infoBtn}>
      {inLabel ? <QuestionMark /> : <InfoCircleOutlined />}
    </button>
  </Tooltip>
);
