import { useGenericStore } from './generic-store';
import { LoadingKeys } from '../constants/loading-keys';
import { API_END_POINTS } from '../constants/api-end-points';
import { BusinessModelItem, IAddOrEditBusinessModel } from '@/types';

export const useBusinessModelsStore = useGenericStore<BusinessModelItem, IAddOrEditBusinessModel>(
  {
    getAllEndpoint: API_END_POINTS.businessModels,
    deleteEndpoint: API_END_POINTS.businessModelsCrud,
    getOneEndpoint: API_END_POINTS.businessModelsCrud,
    updateEndpoint: API_END_POINTS.businessModelsCrud,
  },
  {
    getAllLoadingKey: LoadingKeys.getAllBusinessModels,
    createOrUpdateLoadingKey: LoadingKeys.createOrUpdateBusinessModel,
    deleteLoadingKey: LoadingKeys.deleteBusinessModel,
  },
  false,
);
