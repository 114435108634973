/* eslint-disable camelcase */
import { create } from 'zustand';
import {
  ApiResponse,
  IDisplayTypeOption,
  IExtraOption,
  IFormulaOptions,
  IPointFormulaPayload,
  IPointsFormula,
  IUpdateItemPointFormula,
  OptionType,
} from '@/types';
import { handleApiCallInStore } from './utils';
import { API_END_POINTS, LoadingKeys } from '@/constants';
import { http, mapItemsToLabelValue } from '@/utils';

interface IPointsStore {
  pointsFormula: IPointsFormula;
  displayTypeOptions: OptionType[];
  extraTypeOptions: OptionType[];
  getPointsFormula: (onSuccess?: (data: IPointsFormula) => void) => void;
  updatePointsFormula: (payload: IPointFormulaPayload, onSuccess?: () => void) => void;
  updateItemPointFormula: (payload: IUpdateItemPointFormula, onSuccess?: () => void) => void;
  getPointFormulaOptions: () => void;
}

export const usePointsStore = create<IPointsStore>((set, get) => ({
  pointsFormula: undefined,
  extraTypeOptions: [],
  displayTypeOptions: [],
  getPointFormulaOptions: () => {
    handleApiCallInStore(LoadingKeys.getPointFormulaOptions, async () => {
      const { data } = await http.get<undefined, ApiResponse<IFormulaOptions>>(API_END_POINTS.pointFormulaOptions);
      set({
        displayTypeOptions: mapItemsToLabelValue<IDisplayTypeOption>(data?.disply_type, 'name', 'id') ?? [],
        extraTypeOptions: mapItemsToLabelValue<IDisplayTypeOption>(data?.extra, 'name', 'id') ?? [],
      });
    });
  },
  getPointsFormula: (onSuccess) => {
    handleApiCallInStore(LoadingKeys.getPointsFormula, async () => {
      const { data } = await http.get<undefined, ApiResponse<IPointsFormula>>(API_END_POINTS.pointsFormula);
      set({ pointsFormula: data });
      onSuccess?.(data);
    });
  },
  updatePointsFormula: (payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.updatePointsFormula, async () => {
      const { data } = await http.put<IPointFormulaPayload, ApiResponse<IPointsFormula>>(
        API_END_POINTS.pointsFormula,
        payload,
      );
      set({ pointsFormula: { ...get().pointsFormula, ...data } });
      onSuccess?.();
    });
  },
  updateItemPointFormula: (payload, onSuccess) => {
    handleApiCallInStore(LoadingKeys.updateItemPointFormula, async () => {
      await http.put<IUpdateItemPointFormula>(API_END_POINTS.updateItemPointFormula(payload?.item_id), payload);
      onSuccess?.();
    });
  },
}));
