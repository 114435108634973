import { PropsWithChildren } from 'react';
import { Layout } from 'antd';
import styles from './main-layout.module.scss';
import { DynamicBreadcrumb } from '@/components';

const { Content } = Layout;
interface MainContentProps {
  id?: string;
}

export const MainContent = ({ children, id }: PropsWithChildren<MainContentProps>) => (
  <Content id={id} className={styles.mainContentWrapper}>
    {children}
  </Content>
);
