/* eslint-disable no-unused-expressions */
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { PARAM_ROUTES, ROUTES } from '@/constants';
import { IMasterData, MasterDataType } from '@/types';

export const useMasterDataBreadcrumb = (type: MasterDataType, data?: IMasterData) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const breadArr = useMemo(() => {
    if (data) {
      return [
        { title: t('masterData') },

        ...(data?.category
          ? [{ title: data?.category?.name, link: PARAM_ROUTES.CATEGORY_SUBCATEGORIES(data?.category?.id) }]
          : []),

        ...(data?.sub_category
          ? [
              {
                title: data?.sub_category?.name,
                link: PARAM_ROUTES.SUBCATEGORY_BRANDS(data?.category?.id, data?.sub_category?.id),
              },
            ]
          : []),
        ...(data?.brand
          ? [
              {
                title: data?.brand?.name,
                link: PARAM_ROUTES.BRANDS_SKUs(data?.category?.id, data?.sub_category?.id, data?.brand?.id),
              },
            ]
          : []),
        ...(data?.sku
          ? [
              {
                title: data?.sku?.name,
                link: PARAM_ROUTES.SKU_ITEMS(
                  data?.category?.id,
                  data?.sub_category?.id,
                  data?.brand?.id,
                  data?.sku?.id,
                ),
              },
            ]
          : []),
        ...(data?.name
          ? [
              {
                title: data?.name,
                link: pathname,
              },
              {
                title: t(type),
              },
            ]
          : []),
      ];
    }
    return [
      { title: t('masterData') },

      ...(type === 'categories' ? [{ title: t('categories'), link: ROUTES.CATEGORIES }] : []),

      ...(type === 'subCategories' ? [{ title: t('subCategories'), link: ROUTES.SUBCATEGORIES }] : []),
      ...(type === 'brands' ? [{ title: t('brands'), link: ROUTES.BRANDS }] : []),
      ...(type === 'SKUs' ? [{ title: t('SKUs'), link: ROUTES.SKUS }] : []),
      ...(type === 'items' ? [{ title: t('items'), link: ROUTES.ITEMS }] : []),
    ];
  }, [data, type]);
  return {
    breadArr,
  };
};
