import { GlobalToken, theme } from 'antd';
import { forEach, includes, isNumber, isString } from 'lodash';
import { FC, useLayoutEffect } from 'react';

const isPureNumberProperty = (tokenName: string): boolean =>
  includes(tokenName, 'zIndex') ||
  includes(tokenName, 'Weight') ||
  includes(tokenName, 'motion') ||
  includes(tokenName, 'opacity') ||
  includes(tokenName, 'lineHeight');

function saveToken(value: string | number, tokenName: string) {
  const isPrivateField = tokenName.startsWith('_');
  if (isPrivateField) return;
  const variableName = `--antd-${tokenName}`;

  if (isString(value)) {
    document.documentElement.style.setProperty(variableName, value);
  } else if (isNumber(value)) {
    const propertyValue = isPureNumberProperty(tokenName) ? `${value}` : `${value}px`;
    document.documentElement.style.setProperty(variableName, propertyValue);
  }
}

export const CssTokenBridge: FC = () => {
  const { token } = theme.useToken();

  useLayoutEffect(() => {
    forEach(token as GlobalToken, (value, key) => saveToken(value as string | number, key));
  }, [token]);

  return null;
};
