import { SVGProps } from 'react';

export const StarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.32 13.793a1.15 1.15 0 0 1-.706-.22c-.267-.193-.68-.693-.42-1.813l.38-1.64c.033-.14-.04-.393-.14-.5L4.107 8.293c-.7-.7-.64-1.306-.547-1.606.094-.3.4-.827 1.374-.994l1.7-.28a.673.673 0 0 0 .386-.286l.94-1.88c.454-.907 1.054-1.04 1.374-1.04.32 0 .92.133 1.373 1.04l.94 1.88c.06.113.26.266.387.286l1.7.28c.973.16 1.28.694 1.373.994.094.3.153.906-.547 1.606L13.234 9.62c-.1.1-.174.36-.14.5l.38 1.64c.26 1.127-.154 1.62-.42 1.813-.267.194-.86.44-1.86-.153L9.6 12.473c-.133-.08-.413-.08-.546 0l-1.594.947c-.46.28-.846.373-1.14.373ZM9.334 3.207c-.107 0-.3.126-.48.486l-.94 1.88c-.2.407-.674.754-1.12.827l-1.7.28c-.38.067-.554.207-.587.313-.033.107.027.32.3.594l1.327 1.326c.34.34.52.96.413 1.427l-.38 1.64c-.113.473-.04.727.033.787.074.053.34.046.754-.207l1.6-.947c.446-.266 1.12-.266 1.56 0l1.593.947c.42.247.687.26.76.207.073-.054.147-.307.033-.787l-.38-1.64c-.106-.473.067-1.087.414-1.427l1.326-1.326c.274-.274.334-.494.3-.594-.033-.1-.206-.246-.586-.313l-1.7-.28c-.447-.073-.92-.42-1.12-.827l-.94-1.88c-.18-.36-.374-.486-.48-.486ZM5.333 3.833h-4a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h4c.273 0 .5.227.5.5 0 .274-.227.5-.5.5ZM3.333 13.167h-2a.504.504 0 0 1-.5-.5c0-.274.227-.5.5-.5h2c.273 0 .5.226.5.5 0 .273-.227.5-.5.5ZM2 8.5h-.667a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5H2c.273 0 .5.227.5.5s-.227.5-.5.5Z"
      fill="#000"
      fillOpacity={0.45}
    />
  </svg>
);
