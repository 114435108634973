import { FC } from 'react';
import { Avatar, Flex, Popover, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { usePopover } from '@/hooks';
import { useAuthStore } from '@/stores';
import { ProfileOverlay } from './profile-overlay.component';
import styles from './profile-dropdown.module.scss';

interface ProfileDropdownProps {
  isMobile?: boolean;
}

export const ProfileDropdown = ({ isMobile }: ProfileDropdownProps) => {
  const { t } = useTranslation();
  const { user } = useAuthStore((state) => state);
  const { open, hide, handleOpenChange } = usePopover();

  return user ? (
    <Popover onOpenChange={handleOpenChange} open={open} content={<ProfileOverlay hide={hide} />} trigger="click">
      <Flex gap={10} align="center">
        {!isMobile && <Typography className={styles.userName}>{user?.name}</Typography>}
        <Avatar
          className={styles.avatar}
          icon={<UserOutlined />}
          src={user?.logo ?? user?.image}
          alt={t('profileAltName')}
          shape="circle"
          size={32}
        />
      </Flex>
    </Popover>
  ) : null;
};
