import { ROUTES } from './routes';
import { WizardSteps } from './wizard-steps';

export const stepsMapper = {
  [WizardSteps.STEP_0]: ROUTES.WELCOME,
  [WizardSteps.STEP_1]: ROUTES.SELECT_MODEL,
  [WizardSteps.STEP_1_1]: ROUTES.CREATE_CUSTOM_MODEL,
  [WizardSteps.STEP_1_2]: ROUTES.CREATE_CUSTOM_MODEL_SUCCESS,
  [WizardSteps.STEP_2]: ROUTES.CHOOSE_DISTRIBUTOR,
  [WizardSteps.STEP_2_1]: ROUTES.COMPANY_DISTRIBUTORS,
  [WizardSteps.STEP_3]: ROUTES.COMPANY_ROLES,
  [WizardSteps.STEP_4]: ROUTES.COMPANY_TEAMS,
  [WizardSteps.STEP_5]: ROUTES.COMPANY_SETTINGS,
  [WizardSteps.COMPLETE]: ROUTES.COMPLETE_STEPS,
  [WizardSteps.HOME]: ROUTES.HOME,
};
