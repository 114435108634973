import { useEffect } from 'react';
import { WizardPhases } from '@/constants';
import { useWizardStore } from '@/stores';

export const useSetPhase = (phaseNo: WizardPhases) => {
  const { setPhase } = useWizardStore((state) => state);

  useEffect(() => {
    setPhase(phaseNo);
  }, []);
};
