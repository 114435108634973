import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { Tag } from 'antd';
import { RULES } from '../rules';
import { FormField } from '@/types';
import { langLabel, optionalLabel } from '@/utils';
import { BUSINESS_MODEL_STATUS_MAPPER } from '../admin-constants';

export const useBusinessModelFields = () => {
  const { t } = useTranslation();

  const businessModelFields = useMemo(
    (): FormField[] => [
      {
        label: optionalLabel(langLabel(t('name'), 'en')),
        name: 'name_en',
        type: 'input',
        rules: [RULES.required, RULES.englishOnly],
      },
      {
        label: optionalLabel(langLabel(t('name'), 'ar')),
        name: 'name_ar',
        type: 'input',
        rules: [RULES.required, RULES.arabicOnly],
      },
      {
        label: optionalLabel(langLabel(t('description'), 'en')),
        name: 'description_ar',
        type: 'textarea',

        rules: [RULES.englishOnly],
      },
      {
        label: optionalLabel(langLabel(t('description'), 'ar')),
        name: 'description_en',
        type: 'textarea',
        rules: [RULES.arabicOnly],
      },
    ],
    [],
  );
  const businessModelTableFields = useMemo(
    (): FormField[] => [
      {
        label: t('status'),
        name: 'status',
        type: 'select',
        placeholder: t('selectStatus'),
        options: Object.values(BUSINESS_MODEL_STATUS_MAPPER).map((item) => ({
          label: <Tag color={item?.color}>{item?.label}</Tag>,
          value: item.value,
        })),
      },
    ],
    [],
  );
  return {
    businessModelFields,
    businessModelTableFields,
  };
};
