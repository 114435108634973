import { create } from 'zustand';
import { LoadingKeys } from '@/constants';

export interface ILoadingStoreType {
  loadingObj: Record<LoadingKeys, boolean>;
  setLoading: (key: LoadingKeys, isLoading: boolean) => void;
  getLoading: (key: (LoadingKeys | LoadingKeys[]) | (string | string[])) => boolean;
  emptyLoadingObject: () => void;
}

export const useLoadingStore = create<ILoadingStoreType>((set, get) => ({
  loadingObj: {} as Record<LoadingKeys, boolean>,
  emptyLoadingObject: () => {
    set(() => ({
      loadingObj: {} as Record<LoadingKeys, boolean>,
    }));
  },
  setLoading: async (key, isLoading) => {
    set(({ loadingObj }) => ({
      loadingObj: { ...loadingObj, [key]: isLoading },
    }));
  },
  getLoading: (keys) => {
    if (Array.isArray(keys)) {
      return keys.some((keyItem) => get().loadingObj[keyItem]);
    }
    return get().loadingObj[keys] ?? false;
  },
}));
