import { Rule } from 'antd/es/form';
import i18n from '@/i18';

type RuleKeys =
  | 'required'
  | 'email'
  | 'whiteSpace'
  | 'integers'
  | 'number'
  | 'charactersOnly'
  | 'englishOnly'
  | 'arabicOnly'
  | 'mobile';

export const RULES: Record<RuleKeys, Rule> = {
  required: { required: true, message: i18n.t('thisFieldIsRequired') },
  email: {
    type: 'email',
    message: i18n.t('notValidEmail'),
  },
  whiteSpace: {
    pattern: /^\S*$/,
    message: i18n.t('whiteSpacesValidation'),
  },
  number: {
    pattern: /^-?\d+(\.\d+)?$/,
    message: i18n.t('thisFieldAcceptsNumbersOnly'),
  },
  integers: {
    pattern: /^-?\d+$/,
    message: i18n.t('thisFieldAcceptsIntegersOnly'),
  },
  charactersOnly: {
    pattern: /^[a-zA-Z\u0600-\u06FF\s]+$/,
    message: i18n.t('thisFieldAcceptsCharactersOnly'),
  },
  arabicOnly: {
    pattern: /^[\u0600-\u06FF0-9\s.\-_()+=":;[\]{}<>@!#%^&*~`'"\\/|?,]+$/,
    warningOnly: true,
    message: i18n.t('youAreWritingEnglishCharacters'),
  },
  englishOnly: {
    pattern: /^[A-Za-z0-9\s.\-_()+=":;[\]{}<>@!#%^&*~`'"\\/|?,]+$/,
    message: i18n.t('youAreWritingArabicCharacters'),
    warningOnly: true,
  },
  mobile: {
    pattern: /^(\+?966|0)?5\d{8}$/,
    message: i18n.t('pleaseEnterValidNumber'),
  },
};
export const validateNumber = (values: {
  maxVal?: number | string;
  minVal?: number | string;
  maxValMessage?: string;
  minValMessage?: string;
  equality?: boolean;
}) => {
  const { maxVal, minVal, maxValMessage, minValMessage, equality = false } = values;

  return (_1: any, value: number) => {
    if (
      value !== undefined &&
      maxVal !== undefined &&
      (equality
        ? parseFloat(String(value)) >= parseFloat(String(maxVal))
        : parseFloat(String(value)) > parseFloat(String(maxVal)))
    ) {
      return Promise.reject(new Error(maxValMessage || `${i18n.t('maxValValidError', { maxVal })}`));
    }
    if (
      value !== undefined &&
      minVal !== undefined &&
      (equality
        ? parseFloat(String(value)) <= parseFloat(String(minVal))
        : parseFloat(String(value)) < parseFloat(String(minVal)))
    ) {
      return Promise.reject(new Error(minValMessage || `${i18n.t('minValValidError', { minVal })}`));
    }
    return Promise.resolve();
  };
};
export const PARAM_RULES = {
  min: (minChar: number): Rule => ({
    min: minChar,
    message: i18n.t('minChar', { charNo: minChar }),
  }),
  max: (maxChar: number): Rule => ({
    max: maxChar,
    message: i18n.t('maxChar', { charNo: maxChar }),
  }),
  exact: (length: number) => ({
    pattern: new RegExp(`^.{${length}}$`),
    message: i18n.t('exactChar', { length }),
  }),
};
