import { Card, Flex, Skeleton } from 'antd';
import styles from './ticket-card.module.scss';

export const TicketCardSkeleton = () => (
  <Card bordered className={styles.card}>
    <Flex justify="space-between" align="center">
      <Flex gap={15}>
        <Flex align="center" gap={4}>
          <Skeleton.Avatar active size={16} shape="circle" />
          <Skeleton.Button active style={{ width: '104px', height: '16px' }} />
        </Flex>
        <Skeleton.Button active style={{ width: '133px', height: '32px' }} />
      </Flex>
      <Skeleton.Button active style={{ width: '60px', height: '22px' }} />
    </Flex>
    <div className={styles.skeletonTextContent}>
      <Skeleton paragraph={{ rows: 2 }} />
    </div>
    <div className={styles.skeletonFooter}>
      <Flex align="center" justify="space-between" gap={10}>
        <Flex gap={8} align="center">
          <Flex gap={4} align="center">
            <Skeleton.Avatar active size={25} shape="circle" />
            <Skeleton.Button active style={{ width: '104px', height: '16px' }} />
          </Flex>
          <Skeleton.Button active style={{ width: '104px', height: '16px' }} />
        </Flex>
        <Skeleton.Button active style={{ width: '70', height: '16px' }} />
      </Flex>
    </div>
  </Card>
);
