import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { langLabel, optionalLabel } from '@/utils';
import { RULES, validateNumber } from '../rules';
import { FormField } from '@/types';
import { useFilterMasterDataFields } from './filter-master-data-fields';

export const useMarketingGroupsFields = () => {
  const { masterDataFields, onReset } = useFilterMasterDataFields({
    type: 'all',
    withLabel: true,
    isHorizontal: false,
    isSKURequired: true,
    hideItemsOnSKUEmpty: true,
  });
  const { t } = useTranslation();
  const marketingGroupFields = useMemo(
    (): FormField[] => [
      {
        label: langLabel(t('groupName'), 'en'),
        name: 'name_en',
        type: 'input',
        placeholder: t('enterGroupName'),
        rules: [RULES.required, RULES.englishOnly],
      },
      {
        label: langLabel(t('groupName'), 'ar'),
        name: 'name_ar',
        type: 'input',
        placeholder: t('enterGroupName'),
        rules: [RULES.required, RULES.arabicOnly],
      },
      {
        label: t('price'),
        name: 'price',
        type: 'price',
        rules: [RULES.required, RULES.number, { validator: validateNumber({ minVal: 1 }) }],
        inputNumberProps: {
          min: 1,
        },
      },
      {
        label: optionalLabel(langLabel(t('description'), 'en')),
        name: 'description_en',
        type: 'textarea',
        placeholder: t('description'),
        rules: [RULES.englishOnly],
      },
      {
        label: optionalLabel(langLabel(t('description'), 'ar')),
        name: 'description_ar',
        type: 'textarea',
        placeholder: t('description'),
        rules: [RULES.arabicOnly],
      },
      {
        label: t('mainPhoto'),
        name: 'main_photo',
        type: 'uploadImage',
        rules: [RULES.required],
        uploadImageProps: {
          maxImagesCount: 1,
          maxSizeInMB: 1,
        },
      },
    ],
    [],
  );

  return { marketingGroupFields, assignSKUsToMarketingGroupFields: masterDataFields, onReset };
};
